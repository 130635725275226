import { deliveryOptionsEnum } from '../../utils/constants';
import ups from '../../assets/img/carriers/ups.svg';
import dhl from '../../assets/img/carriers/dhl.svg';
import usps from '../../assets/img/carriers/usps.png';
import fedex from '../../assets/img/carriers/fedex.svg';
import tnt from '../../assets/img/carriers/TNT.png';
import boxberry from '../../assets/img/carriers/boxberry.png';
import dpd from '../../assets/img/carriers/dpd.png';
import postRussia from '../../assets/img/carriers/post_russia.png';
import apcLogistics from '../../assets/img/carriers/apc_logistics.png';
import amazon from '../../assets/img/carriers/amazon.png';
import other from '../../assets/img/carriers/other.png';

export const deliveryOptions = [
  { value: '' },
  {
    value: deliveryOptionsEnum.AMAZON_LOGISTICS,
    label: 'app.amazonLogistics',
    src: amazon,
  },
  {
    value: deliveryOptionsEnum.UPS,
    label: 'app.ups',
    src: ups,
  },
  {
    value: deliveryOptionsEnum.DHL,
    label: 'app.dhl',
    src: dhl,
  },
  {
    value: deliveryOptionsEnum.USPS,
    label: 'app.usps',
    src: usps,
  },
  {
    value: deliveryOptionsEnum.FEDEX,
    label: 'app.fedex',
    src: fedex,
  },
  {
    value: deliveryOptionsEnum.TNT,
    label: 'app.tnt',
    src: tnt,
  },
  {
    value: deliveryOptionsEnum.POST_RUSSIA,
    label: 'app.russianPost',
    src: postRussia,
  },
  {
    value: deliveryOptionsEnum.DPD,
    label: 'app.dpd',
    src: dpd,
  },
  {
    value: deliveryOptionsEnum.APC,
    label: 'app.apc',
    src: apcLogistics,
  },
  {
    value: deliveryOptionsEnum.BOXBERRY,
    label: 'app.boxberry',
    src: boxberry,
  },
  {
    value: deliveryOptionsEnum.OTHER,
    label: 'app.other',
    src: other,
  },
];
