import { TableCell, TableRow } from '@material-ui/core';
import React from 'react';
import { ITableListRow } from '../types';
import { TableHeadCellsEnums } from '../../../utils/constants';
import { Link } from 'react-router-dom';
import { TRoutes } from '../../../utils/helpers';

export const TableListRow = ({ row, headCells, number }: ITableListRow) => {
  return (
    <TableRow>
      {headCells.map((column) => {
        const value = row[column.name];

        if (column.name === TableHeadCellsEnums.ID)
          return (
            <TableCell key={column.label} align='center'>
              <Link
                to={`${TRoutes.SETTINGS_MY_PRODUCT_DETAILS_WITHOUT_ID}${value}`}
              >
                {row.titleId}
              </Link>
            </TableCell>
          );

        return (
          <TableCell key={column.label} align='center'>
            {column.name === TableHeadCellsEnums.NUMBER ? number : value}
          </TableCell>
        );
      })}
    </TableRow>
  );
};
