import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { COLORS } from '../../utils/constants';

export const styles = (theme: Theme) =>
  createStyles({
    placeholder: {
      color: 'grey',
    },
    infoWrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: '24px',
    },
    carrierCodeIcon: {
      width: 20,
      maxHeight: 16,
      marginRight: 10,
    },
    carrierCodeSelectedItem: {
      display: 'flex',
      alignItems: 'center',
    },
    trackNumberItem: { fontSize: '1rem', lineHeight: '1.5' },
    datePicker: {
      padding: '4px',
      width: '148px',
      borderRedius: '3px',
      '& .MuiInputBase-input': { padding: '4px 8px' },
    },
    labelRow: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: 0,
      marginRight: 0,
      paddingTop: '10px',
    },
    radio: {
      padding: 0,
    },
    labelRowText: {
      fontWeight: 'bold',
      marginLeft: 10,
      marginRight: 10,
    },
    adornment: {
      height: '28px',
      backgroundColor: COLORS.LIGHT_GREY,
      borderRadius: '0 4px 4px 0',
      padding: '0 4px',
      display: 'flex',
      alignItems: 'center',
    },
    calendarIcon: { width: '15px', height: '15px' },
  });

export default makeStyles(styles);
