import { TRoutes } from '../../../utils/helpers';
import SearchIcon from '@material-ui/icons/Search';

export const getActionOptions = (id: number) => [
  {
    link: `${TRoutes.RETURN_DETAILS_WITHOUT_ID}${id?.toString()}`,
    Icon: SearchIcon,
    title: 'app.view',
    tooltipText: 'app.clickToOpenViewShipments',
  },
];
