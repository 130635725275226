import { FormControl, MenuItem, Select } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useStyles from '../styles';
import { IFilters } from '../../../utils/types';
import { WAREHOUSE_B2B_STATUS_BY_STATUS } from '../../../utils/constants';

interface ISelectWarehouseShipmentStatus {
  value?: string;
  setFilter: React.Dispatch<React.SetStateAction<IFilters>>;
}

const FilterSelectB2bWarehouseShipmentStatus = ({
  value,
  setFilter,
}: ISelectWarehouseShipmentStatus) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const options = Object.entries(WAREHOUSE_B2B_STATUS_BY_STATUS);

  return (
    <FormControl className={classes.filtersItemSelect}>
      <Select
        displayEmpty
        disableUnderline
        value={value}
        onChange={(e) => {
          setFilter((state) => ({
            ...state,
            warehouseStatus: e.target.value as string,
            page: 1,
          }));
        }}
      >
        <MenuItem value={''}>{t('app.status')}</MenuItem>
        {options.map(([value, label]) => (
          <MenuItem key={label} value={value}>
            {t(label)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default FilterSelectB2bWarehouseShipmentStatus;
