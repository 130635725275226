import { makeStyles } from '@material-ui/core/styles';

export const styles = makeStyles((theme) => ({
  mainTitle: {
    marginBottom: 32,

    [theme.breakpoints.down('sm')]: {
      fontSize: 23,
      marginBottom: 15,
    },
  },
  itemInfo: {
    fontSize: 18,
  },
}));

export default styles;
