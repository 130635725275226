import React from 'react';
import { TableCell, TableHead, TableRow } from '@material-ui/core';
import { ITableListHeader } from '../types';
import { useTranslation } from 'react-i18next';
import { TableHeadCellsEnums } from '../../../utils/constants';

export const TableListHeader = ({
  headCells,
  currency = '',
}: ITableListHeader) => {
  const { t } = useTranslation();
  return (
    <TableHead>
      <TableRow>
        {headCells.map(({ label, name }) => (
          <TableCell key={label} align='center'>
            {name !== TableHeadCellsEnums.PRICE
              ? t(label)
              : `${t(label)} ${currency}`}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
