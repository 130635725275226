import { ProdyctSortType } from '../../../generated/graphql';

export const filterSelectSortingProductsOptions = [
  { title: 'app.sortingFilter', value: '' },
  {
    title: 'app.availabilityProductFilter',
    value: ProdyctSortType.Availability,
  },
  {
    title: 'app.transitProductFilter',
    value: ProdyctSortType.Transit,
  },
];
