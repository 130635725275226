import { TRoutes } from '../../utils/helpers';

export const returnsOptions = [
  { link: TRoutes.RETURNS_RTS_LIST, title: 'app.rts' },
  { link: TRoutes.RETURNS_RMA_LIST, title: 'app.rma' },
];

export const sendingToWarehouseOptions = [
  { link: TRoutes.WAREHOUSE_SHIPMENT_LIST, title: 'app.productDelivery' },
  { link: TRoutes.WAREHOUSE_SHIPMENT_FORWARDING_LIST, title: 'app.forwarding' },
  { link: TRoutes.UNDECLARED_ARRIVAL_LIST, title: 'app.undeclaredArrival' },
];
