import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { COLORS } from '../../utils/constants';

export const styles = (theme: Theme) =>
  createStyles({
    mainTitle: {
      textAlign: 'center',
      marginBottom: 32,

      [theme.breakpoints.down('sm')]: {
        fontSize: 23,
        marginBottom: 15,
      },
    },
    inputLabel: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    input: { maxWidth: '280px', marginRight: '30px' },
    itemInfo: {
      fontSize: 18,
    },
    contactFieldsTitle: {
      marginBottom: 17,
    },
    selectContacts: {
      maxWidth: 204,
      width: '100%',

      '& .MuiSelect-select': {
        height: '41px',
        paddingRight: 45,
        boxSizing: 'border-box',
      },

      '& .MuiSelect-icon': {
        right: 14,
      },
    },
    labelRow: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: 0,
      marginRight: 0,
      paddingTop: '10px',
    },
    radio: {
      padding: 0,
    },
    labelRowText: {
      fontWeight: 'bold',
      marginRight: 10,
    },
    tooltipIconButton: {
      padding: 0,
    },
    tableCell: {
      paddingLeft: 0,
    },
    errorIcon: {
      color: COLORS.RED,
    },
    itemResult: {
      fontWeight: 'bold',
      fontSize: 16,
    },
    circularProgress: {
      overflow: 'hidden',
    },
    calculateButton: {
      marginTop: '20px',
    },
    errorsMessage: {
      color: '#f44336',
    },
  });

export default makeStyles(styles);
