import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { COLORS } from '../../../utils/constants';

export const styles = (theme: Theme) =>
  createStyles({
    mainTitle: {
      marginBottom: 32,

      [theme.breakpoints.down('sm')]: {
        fontSize: 23,
        marginBottom: 15,
      },
    },
    selectContactsWrap: {
      display: 'flex',
    },
    selectSenderContactsControl: {
      alignItems: 'start-end',
    },
    selectReceiverContactsControl: {
      alignItems: 'flex-end',
    },
    title: {
      marginTop: 24,
      marginBottom: 24,
      paddingLeft: 32,

      [theme.breakpoints.down('sm')]: {
        paddingLeft: 10,
        fontSize: 16,
      },
    },
    accordion: {
      marginBottom: 40,
    },
    accordionSummary: {
      margin: 0,
      padding: 0,

      '& .MuiAccordionSummary-content': {
        margin: 0,
      },
    },
    accordionDetails: {
      padding: 0,

      '& > div': {
        width: '100%',
      },
    },
    wrapper: {
      borderTop: '1px solid #e0e0e0',
      paddingLeft: 40,
      paddingRight: 40,
      paddingTop: 24,
      width: '100%',

      [theme.breakpoints.down('sm')]: {
        paddingLeft: 20,
        paddingRight: 20,
      },
    },
    selectContacts: {
      maxWidth: 204,
      marginBottom: 24,
      width: '100%',

      '& .MuiSelect-select': {
        height: '41px',
        paddingRight: 45,
        boxSizing: 'border-box',
      },

      '& .MuiSelect-icon': {
        right: 14,
      },
    },
    contactFields: {
      maxWidth: 344,

      '& .MuiFormControl-root': {
        marginBottom: 24,
      },

      '& .MuiFormControl-root:last-child': {
        marginBottom: 0,
      },

      [theme.breakpoints.down('sm')]: {
        padding: 0,
        marginBottom: 50,
        maxWidth: 'unset',

        '& .MuiFormControl-root': {
          maxWidth: 'unset',
          width: '100%',
        },

        '&:last-child': {
          marginBottom: 0,
        },
      },
    },
    contactFieldsTitle: {
      marginBottom: 17,
    },
    departureDatePicker: {
      maxWidth: 261,

      '& .MuiInput-root': {
        '&:after': {
          content: 'none',
        },
        '&:before': {
          content: 'none',
        },
      },
    },
    textarea: {
      maxWidth: 528,
      minHeight: 160,
      width: '100%',
      fontFamily: 'inherit',
      fontSize: 16,
      padding: 10,
    },
    infoDivider: {
      paddingLeft: 40,
      paddingRight: 40,

      [theme.breakpoints.down('xs')]: {
        paddingLeft: 20,
        paddingRight: 20,
      },
    },
    infoText: {
      fontSize: '16px',
      paddingLeft: 40,
      paddingRight: 40,
      lineHeight: '24px',

      '& .MuiLink-root': {
        color: 'inherit',
      },

      [theme.breakpoints.down('xs')]: {
        paddingLeft: 20,
        paddingRight: 20,
      },
    },
    confirmText: {
      fontSize: '16px',
      paddingLeft: 40,
      paddingRight: 40,
      lineHeight: '24px',
      maxWidth: 534,

      '& .MuiLink-root': {
        color: 'inherit',
      },

      [theme.breakpoints.down('xs')]: {
        paddingLeft: 20,
        paddingRight: 20,
      },
    },
    actions: {
      paddingLeft: 31,
      marginTop: 24,
      marginBottom: 63,

      [theme.breakpoints.down('xs')]: {
        paddingLeft: 20,
        paddingRight: 20,
      },
    },
    arrangeAction: {
      marginRight: 16,
    },
    action: {
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        marginBottom: 10,
      },
    },
    errorsMessage: {
      color: '#f44336',
    },
    parcelInfo: {
      fontSize: 16,
      marginTop: 20,
      marginBottom: 20,
      display: 'flex',
      flexWrap: 'wrap',
      whiteSpace: 'nowrap',
      [theme.breakpoints.down(1050)]: {
        flexDirection: 'column',
      },
    },
    parcelInfoDivider: {
      height: 22,
      marginRight: 5,
      marginLeft: 5,

      [theme.breakpoints.down(1050)]: {
        display: 'none',
      },
    },
    parcelSelect: {
      marginBottom: '24px',
    },
    removeCircleIcon: {
      position: 'absolute',
      top: '43px',
      left: '-34px',
    },
    //styles to cargo places
    packageItem: {
      padding: '20px',
      borderRadius: '10px',
      border: '2px solid transparent',

      '&:hover': {
        backgroundColor: '#dcdcdc',
        border: '2px solid brown',
      },
    },
    root: {
      marginTop: 30,
      paddingTop: 30,
      borderTop: '1px solid #e0e0e0',

      '&:first-child': {
        marginTop: 0,
        paddingTop: 0,
        border: 'none',
      },
    },
    removePackage: {
      cursor: 'pointer',
      color: COLORS.RED,
    },
    addParcelAction: {
      marginTop: 40,
    },
    showButton: {
      marginBottom: 24,
      backgroundColor: '#ebebeb',
      borderRadius: '3px',
      border: '1px solid #ebebeb',
    },
    searchButton: {
      marginTop: '5px',
      paddingLeft: '13px',
      paddingRight: '13px',
      backgroundColor: '#ebebeb',
      borderRadius: '3px',
      border: '1px solid #ebebeb',
    },
    openMyPackages: {
      display: 'flex',
      alignItems: 'center',
      position: 'absolute',
      right: 0,
      cursor: 'pointer',
      fontWeight: 'normal',
      fontSize: 15,
      letterSpacing: 0,
    },
    placeholder: {
      color: 'grey',
    },
    infoWrapper: {
      display: 'flex',
    },
    carrierCodeIcon: {
      width: 20,
      maxHeight: 16,
      marginRight: 10,
    },
    carrierCodeSelectedItem: {
      display: 'flex',
      alignItems: 'center',
    },
    trackNumberItem: { fontSize: '1rem', lineHeight: '1.5' },
  });

export default makeStyles(styles);
