import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  itemInfo: {
    fontSize: 18,
  },
  title: { textTransform: 'uppercase' },
  image: {
    width: '157px',
    height: '150px',
    margin: '16px',
    cursor: 'pointer',
  },
}));
