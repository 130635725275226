import React from 'react';
import { useSnackbar } from 'notistack';

import PrivateLayout from './PrivateLayout/PrivateLayout';
import PublicLayout from './PublicLayout/PublicLayout';

import { useGetCurrentUserQuery } from '../generated/graphql';
import { USER_STATUS } from '../utils/helpers';
import { ShowLoadingText } from '../utils/helperComponents';
import { useTranslation } from 'react-i18next';

export default function Routes() {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const {
    loading: isLoadingCurrentUser,
    error: errorCurrentUser,
    data: dataCurrentUser,
  } = useGetCurrentUserQuery();

  if (isLoadingCurrentUser) return <ShowLoadingText name={t('app.users')} />;

  if (errorCurrentUser) {
    enqueueSnackbar(errorCurrentUser.message, { variant: 'error' });
  }

  if (dataCurrentUser?.currentUser) {
    if (dataCurrentUser.currentUser.status === USER_STATUS.ACTIVE)
      return <PrivateLayout user={dataCurrentUser.currentUser} />;
    return <PublicLayout user={dataCurrentUser.currentUser} />;
  }

  return <PublicLayout />;
}
