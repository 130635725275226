import { Field, FieldProps } from 'formik';
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import React from 'react';

const VatValue: React.FC<{
  fieldName?: string;
  unitIndex?: number;
  handlerChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  label: any;
}> = ({ fieldName, unitIndex, handlerChange, label }) => {
  const theme = useTheme();
  const hidden = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <>
      <Field name={fieldName}>
        {({ field: { value, ...field }, meta }: FieldProps) => (
          <FormControl error={!!(meta.touched && meta.error && value !== 0)}>
            {label && label !== 'EMPTY' ? (
              <>
                <Grid container justify='space-between'>
                  <InputLabel htmlFor='input-vat-value'>{label}</InputLabel>
                  {hidden ? null : (
                    <Tooltip title={label}>
                      <InfoIcon />
                    </Tooltip>
                  )}
                </Grid>
                <Input
                  disableUnderline
                  id='input-vat-value'
                  {...field}
                  defaultValue={value}
                  onChange={handlerChange}
                />
                <Box
                  visibility={
                    meta.touched && meta.error && value !== 0
                      ? 'visible'
                      : 'hidden'
                  }
                >
                  <FormHelperText>{meta.error}</FormHelperText>
                </Box>
              </>
            ) : null}
          </FormControl>
        )}
      </Field>
    </>
  );
};

export default VatValue;
