import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  itemInfo: {
    fontSize: 18,
  },
  btnIcon: {
    float: 'right',
    marginLeft: 5,
    lineHeight: '30px',
  },
  wrapButtons: {
    display: 'flex',
    flexDirection: 'row',
    padding: '30px 16px 10px',
    alignItems: 'center',
    justifyContent: 'center',

    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  actionIcon: {
    paddingRight: '5px',
    fontSize: '20px',
    alignSelf: 'flex-start',
  },
  tableStyles: {
    width: 'auto',
    '& th': {
      padding: '0 20px',
    },
    '& td': {
      padding: '4px 20px',
    },
  },
}));
