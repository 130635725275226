import { getInitialCargoPlace } from '../../../../utils/helpers';
import {
  GetCurrentUserQuery,
  WarehouseShipment,
} from '../../../../generated/graphql';

type createInitValueType = {
  currentUserData: GetCurrentUserQuery | undefined;
  isForwarding: boolean | undefined;
  warehouseShipment: WarehouseShipment | null;
};

export const createInitValueWarehouse = ({
  currentUserData,
  isForwarding,
  warehouseShipment,
}: createInitValueType) => {
  const currentUser = currentUserData?.currentUser;

  const initialValues = !!warehouseShipment
    ? {
        ...(isForwarding
          ? {
              trackNumberItems: warehouseShipment.pieces?.map(
                (piece) => piece?.customIdentifier,
              ),
            }
          : {
              products: warehouseShipment.products?.map((product) => ({
                id: Number(product?.product?.id),
                quantity: product?.quantity,
              })),
              productsToSumm: warehouseShipment.products?.map((product) => ({
                quantity: product?.quantity,
                price: product?.product?.declaredValue,
                netWeight: product?.product?.netWeight,
              })),
              productsSku: warehouseShipment.products?.map(
                (product) => product?.product?.sku,
              ),
            }),
        cargoPlaces: warehouseShipment.packages,
        from: {
          address: currentUser?.address,
          address2: currentUser?.address2,
          address3: currentUser?.address3,
          city: currentUser?.city,
          company: currentUser?.company,
          country: currentUser?.country,
          email: currentUser?.email,
          eoriCode: currentUser?.eoriCode,
          id: currentUser?.id,
          //@ts-ignore
          innKppCode: currentUser?.innKppCode,
          name: currentUser?.name,
          phone: currentUser?.phone,
          //@ts-ignore
          remark: currentUser?.remark,
          state: currentUser?.state,
          //@ts-ignore
          type: currentUser?.type,
          zipCode: currentUser?.zipCode,
        },
        warehouseId: warehouseShipment.warehouse?.id,
        carrierCode: warehouseShipment.carrierCode,
        trackNumber: warehouseShipment.trackNumber,
        expectedArrivalDate: warehouseShipment.expectedArrivalDate,
        comment: warehouseShipment.comment,
      }
    : {
        ...(isForwarding ? { trackNumberItems: [] } : { products: [] }),
        cargoPlaces: [getInitialCargoPlace()],
        from: {
          address: currentUser?.address,
          address2: currentUser?.address2,
          address3: currentUser?.address3,
          city: currentUser?.city,
          company: currentUser?.company,
          country: currentUser?.country,
          email: currentUser?.email,
          eoriCode: currentUser?.eoriCode,
          id: currentUser?.id,
          //@ts-ignore
          innKppCode: currentUser?.innKppCode,
          name: currentUser?.name,
          phone: currentUser?.phone,
          //@ts-ignore
          remark: currentUser?.remark,
          state: currentUser?.state,
          //@ts-ignore
          type: currentUser?.type,
          zipCode: currentUser?.zipCode,
        },
        warehouseId: 0,
        carrierCode: '',
        trackNumber: '',
        expectedArrivalDate: '',
        comment: '',
      };

  return initialValues;
};
