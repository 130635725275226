import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  tooltipIconButton: {
    padding: 0,
  },
  inputLabelMini: {
    [theme.breakpoints.up('lg')]: {
      fontSize: '17px',
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.down(1290)]: {
      fontSize: 'inherit',
    },
  },
}));
