import React, { useEffect, useState } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';

import { DEPARTURE_DATE_FILTER, LIMIT_ITEM_PAGE } from '../../../utils/helpers';
import useStyles from './styles';

import FilterSelectLimitItems from '../../../components/_Fields/FilterSelectLimitItems/FilterSelectLimitItems';
import {
  LOCAL_STORAGE_KEYS,
  UNDECLARED_ARRIVAL_TABLE_CELLS_ARRAY,
} from '../../../utils/constants';

import FilterInputSearch from '../../../components/_Fields/FilterInputSearch/FilterInputSearch';
import { useTranslation } from 'react-i18next';
import { IFilters } from '../../../utils/types';
import FilterSelectDepartureDate from '../../../components/_Fields/FilterSelectDepartureDate/FilterSelectDepartureDate';
import TableBodyRequestHandler from '../../../components/TableBodyRequestHandler/TableRequestHandler';
import UndeclaredArrivalTableRowComponent from '../../../components/UndeclaredArrivalComponents/UndeclaredArrivalTableRowComponent/UndeclaredArrivalTableRowComponent';
import PaginationComponent from '../../../components/PaginationComponent/PaginationComponent';
import {
  UndeclaredShipmentsList,
  WarehouseShipmentStatus,
  useGetUndeclaredShipmentsListLazyQuery,
} from '../../../generated/graphql';
import FilterSelectWarehouseShipmentStatus from '../../../components/_Fields/FilterSelectWarehouseShipmentStatus/FilterSelectWarehouseShipmentStatus';

const itemsLimit = localStorage.getItem(
  LOCAL_STORAGE_KEYS.ITEMS_LIMIT_SHIPMENTS_ALL_HISTORY,
);

const UndeclaredArrivalList: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [udeclaredShipmentList, setUdeclaredShipmentList] =
    useState<UndeclaredShipmentsList | null>(null);

  const [getUndeclaredArrivalListLazyQuery, { data, loading }] =
    useGetUndeclaredShipmentsListLazyQuery();

  const [filter, setFilter] = useState<IFilters>({
    warehouseStatus: '',
    search: '',
    page: 1,
    itemsLimit: itemsLimit ? +itemsLimit : LIMIT_ITEM_PAGE,
  });

  useEffect(() => {
    if (data?.getUndeclaredShipmentsList) {
      setUdeclaredShipmentList(data?.getUndeclaredShipmentsList);
    }
  }, [data]);

  useEffect(() => {
    getUndeclaredArrivalListLazyQuery({
      variables: {
        filter: {
          offset: (filter.page! - 1) * filter.itemsLimit!,
          limit: filter.itemsLimit,
          ...(filter.dateFilter !== DEPARTURE_DATE_FILTER.ALL_TIME && {
            dateFilter: Number(filter.dateFilter),
          }),
          ...(filter.warehouseStatus && {
            warehouseStatus: filter.warehouseStatus as WarehouseShipmentStatus,
          }),
        },
        search: filter.search,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  return (
    <>
      <Typography variant='h2' align='center'>
        {t('app.undeclaredArrival').toUpperCase()}
      </Typography>

      <div className={classes.filters}>
        <FilterSelectDepartureDate
          value={filter.dateFilter}
          setFilter={setFilter}
        />

        <FilterSelectWarehouseShipmentStatus
          value={filter.warehouseStatus}
          setFilter={setFilter}
        />

        <FilterSelectLimitItems
          value={filter.itemsLimit as number}
          setFilter={setFilter}
          localStorageItemsLimitKey={
            LOCAL_STORAGE_KEYS.ITEMS_LIMIT_SHIPMENTS_ALL_HISTORY
          }
        />
        <FilterInputSearch setFilter={setFilter} />
      </div>

      <TableContainer component={Paper}>
        <Table aria-label='simple table'>
          <TableHead>
            <TableRow>
              {UNDECLARED_ARRIVAL_TABLE_CELLS_ARRAY.map((text) => (
                <TableCell align='center' rowSpan={2}>
                  {t(text)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableBodyRequestHandler
              dataLength={2}
              colSpan={UNDECLARED_ARRIVAL_TABLE_CELLS_ARRAY.length}
              loading={loading}
            >
              {udeclaredShipmentList?.rows?.length
                ? udeclaredShipmentList?.rows?.map((item) => (
                    <UndeclaredArrivalTableRowComponent
                      key={item?.id}
                      item={item}
                    />
                  ))
                : null}
            </TableBodyRequestHandler>
          </TableBody>
        </Table>
      </TableContainer>
      <PaginationComponent
        count={udeclaredShipmentList?.count}
        limit={filter?.itemsLimit as number}
        currentPage={filter.page!}
        setFilter={setFilter}
      />
    </>
  );
};

export default UndeclaredArrivalList;
