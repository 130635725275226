import React from 'react';
import { Button, Typography } from '@material-ui/core';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import ModalBox from '../ModalBox/ModalBox';

interface IDeleteModal {
  isOpenModal: boolean;
  setIsOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  handleCloseModal: () => void;
  loading: boolean;
  title: string;
  handleDelete: () => void;
}

const DeleteItemModal = ({
  isOpenModal,
  setIsOpenModal,
  handleCloseModal,
  loading,
  title,
  handleDelete,
}: IDeleteModal) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <ModalBox isOpen={isOpenModal} setOpen={setIsOpenModal}>
      <Typography variant='h6'>{title}</Typography>
      <div className={classes.boxModalButtons}>
        <Button
          variant='contained'
          onClick={handleCloseModal}
          color='secondary'
        >
          {t('app.close')}
        </Button>
        <Button disabled={loading} variant='contained' onClick={handleDelete}>
          {t('app.delete')}
        </Button>
      </div>
    </ModalBox>
  );
};

export default DeleteItemModal;
