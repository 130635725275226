import React from 'react';
import { FormControl, MenuItem, Select } from '@material-ui/core';
import { EXTENDED_DATE_FILTERS } from '../../../utils/helpers';
import { useTranslation } from 'react-i18next';

const FilterSelectDatePeriodExtended: React.FC<{
  value: number;
  // setFilter: React.Dispatch<React.SetStateAction<{date: number, page: number}>>
  setFilter: any;
}> = ({ value, setFilter }) => {
  const { t } = useTranslation();

  return (
    <FormControl className='filtersItemSelect'>
      <Select
        displayEmpty
        disableUnderline
        value={value}
        onChange={(e) => {
          setFilter((state: any) => ({
            ...state,
            date: Number(e.target.value),
            page: 1,
          }));
        }}
      >
        <MenuItem value='' disabled>
          {t('app.periods')}
        </MenuItem>
        <MenuItem value={EXTENDED_DATE_FILTERS.ALL_TIME}>
          {t('app.allPeriod')}
        </MenuItem>
        <MenuItem value={EXTENDED_DATE_FILTERS.TODAY}>
          {t('app.today')}
        </MenuItem>
        <MenuItem value={EXTENDED_DATE_FILTERS.YESTERDAY}>
          {t('app.yesterday')}
        </MenuItem>
        <MenuItem value={EXTENDED_DATE_FILTERS.LAST_7_DAYS}>
          {t('app.lastDays', { value: 7 })}
        </MenuItem>
        <MenuItem value={EXTENDED_DATE_FILTERS.LAST_30_DAYS}>
          {t('app.lastDays', { value: 30 })}
        </MenuItem>
        <MenuItem value={EXTENDED_DATE_FILTERS.RANGE}>
          {t('app.dateRange')}
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export default FilterSelectDatePeriodExtended;
