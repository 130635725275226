import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { Switch as SwitchIf, Case, Default } from 'react-if';

import SignIn from '../viewes/Authorization/SignIn';
import SignUp from '../viewes/Authorization/SignUp';
import Reset from '../viewes/Authorization/Reset';
import Confirm from '../viewes/Authorization/Confirm';
import AddEmail from '../viewes/Authorization/AddEmail';
import RecoveryPassword from '../viewes/Authorization/RecoveryPassword';
import FulfilmentCalculator from '../viewes/FulfilmentCalculator/FulfilmentCalculator';

import { TRoutes } from '../utils/helpers';
import { User } from '../generated/graphql';
import LegalInformation from '../viewes/TextPages/LegalInformation/LegalInformation';
import Eula from '../viewes/TextPages/Eula/Eula';
import Requisites from '../viewes/TextPages/Requisites/Requisites';

const PublicRoutes: React.FC<{ user?: User }> = ({ user }) => {
  return (
    <SwitchIf>
      <Case condition={!!user && !user.email}>
        <Switch>
          <Route path={TRoutes.AUTH_ADD_EMAIL} exact component={AddEmail} />
          <Route
            path={TRoutes.SELECT_TYPE_CALCULATOR}
            exact
            component={FulfilmentCalculator}
          />
          <Route
            path={TRoutes.LEGAL_INFORMATION}
            exact
            component={LegalInformation}
          />
          <Route path={TRoutes.EULA} exact component={Eula} />
          <Route path={TRoutes.REQUISITES} exact component={Requisites} />

          <Redirect from={TRoutes.MAIN} exact to={TRoutes.AUTH_ADD_EMAIL} />
          <Redirect from='*' to={TRoutes.MAIN} exact />
        </Switch>
      </Case>
      <Case condition={!!user}>
        <Switch>
          <Route path={TRoutes.AUTH_CONFIRM} exact component={Confirm} />
          <Route
            path={TRoutes.SELECT_TYPE_CALCULATOR}
            exact
            component={FulfilmentCalculator}
          />
          <Route
            path={TRoutes.LEGAL_INFORMATION}
            exact
            component={LegalInformation}
          />
          <Route path={TRoutes.EULA} exact component={Eula} />
          <Route path={TRoutes.REQUISITES} exact component={Requisites} />

          <Redirect from={TRoutes.MAIN} exact to={TRoutes.AUTH_CONFIRM} />
          <Redirect from='*' to={TRoutes.MAIN} exact />
        </Switch>
      </Case>
      <Default>
        <Switch>
          <Route path={TRoutes.AUTH_SIGN_IN} exact component={SignIn} />
          <Route path={TRoutes.AUTH_SIGN_UP} exact component={SignUp} />
          <Route path={TRoutes.AUTH_RESET} exact component={Reset} />
          <Route
            path={TRoutes.AUTH_RECOVERY}
            exact
            component={RecoveryPassword}
          />
          <Route
            path={TRoutes.SELECT_TYPE_CALCULATOR}
            exact
            component={FulfilmentCalculator}
          />
          <Route
            path={TRoutes.LEGAL_INFORMATION}
            exact
            component={LegalInformation}
          />
          <Route path={TRoutes.EULA} exact component={Eula} />
          <Route path={TRoutes.REQUISITES} exact component={Requisites} />

          <Redirect from={TRoutes.MAIN} exact to={TRoutes.AUTH_SIGN_IN} />
          <Redirect from={TRoutes.AUTH} exact to={TRoutes.AUTH_SIGN_IN} />
          <Redirect from='*' to={TRoutes.MAIN} exact />
        </Switch>
      </Default>
    </SwitchIf>
  );
};

export default PublicRoutes;
