import React from 'react';

import { Button, Typography } from '@material-ui/core';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import { B2BOrderCargoPlace, B2BOrderProduct } from '../../generated/graphql';
import ModalBox from '../ModalBox/ModalBox';
import TableList from '../TableList/TableList';
import { Maybe } from 'graphql/jsutils/Maybe';

export type B2BOrderProductWithCargoQuantity = B2BOrderProduct & {
  b2bCargoProductsQuantity: number;
};

export interface IB2bShipmentViewCargoPlaceModal {
  isOpenModal: boolean;
  setIsOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  cargoPlace: B2BOrderCargoPlace | null;
  cargoPlaceNumber: number | null;
  rowsProducts: {
    id: string | undefined;
    titleId: string | undefined;
    productSku: Maybe<string> | undefined;
    productName: Maybe<string> | undefined;
    b2bCargoProductsQuantity: number;
  }[];
}

export const cargoProductsHeadCells = [
  { label: 'app.number', name: 'number' },
  { label: 'app.id', name: 'id' },
  { label: 'app.sku', name: 'productSku' },
  { label: 'app.nomination', name: 'productName' },
  { label: 'app.b2bCargoProductsQuantity', name: 'b2bCargoProductsQuantity' },
];

const B2bShipmentViewCargoPlaceModal = ({
  isOpenModal,
  setIsOpenModal,
  cargoPlace,
  cargoPlaceNumber,
  rowsProducts,
}: IB2bShipmentViewCargoPlaceModal) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <ModalBox isOpen={isOpenModal} setOpen={setIsOpenModal}>
      <Typography variant='h6'>{`${t('app.cargoPlaceComposition')} ${
        Number(cargoPlaceNumber) + 1
      }`}</Typography>
      <Typography>{`${t('app.numberOfSku')} ${
        cargoPlace?.uniqSkuCount
      }`}</Typography>
      <Typography style={{ marginBottom: '12px' }}>{`${t(
        'app.totalNumberOfProduct',
      )} ${cargoPlace?.productQty}`}</Typography>
      {rowsProducts.length ? (
        <TableList headCells={cargoProductsHeadCells} rows={rowsProducts} />
      ) : null}
      <div className={classes.boxModalButtons}>
        <Button
          variant='contained'
          onClick={() => {
            setIsOpenModal(false);
          }}
          color='secondary'
        >
          {t('app.close')}
        </Button>
      </div>
    </ModalBox>
  );
};

export default B2bShipmentViewCargoPlaceModal;
