import { COLORS } from './../../../../utils/constants';
export const options = {
  cutout: '80%',
  layout: {
    padding: {
      top: 14, // Увеличиваем отступ сверху
    },
  },
  plugins: {
    legend: {
      position: 'bottom' as const,
      align: 'start' as const,
      labels: {
        font: { size: 18, family: '"Source Sans 3", sans-serif' },
        boxHeight: 16,
        boxWidth: 22,
        color: COLORS.BLACK,
        padding: 15,
      },
    },
    tooltip: {
      enabled: false,
    },
    datalabels: {
      color: COLORS.BLACK, // Цвет текста меток данных
      font: {
        size: 14,
        weight: 'normal' as const,
      },
      anchor: 'end' as const, // Якорь для текста
      align: 'end' as const, // Выравнивание текста
      clip: false,
      offset: -5,
      padding: { left: 10 },
    },

    responsive: true,
    maintainAspectRatio: false,
  },
};
