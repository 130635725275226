import React from 'react';
import { FormControl, MenuItem, Select } from '@material-ui/core';
import { MARKETPLACES } from '../../../utils/constants';
import { useTranslation } from 'react-i18next';

const FilterSelectMarketplace: React.FC<{
  value: string;
  setFilter: any;
}> = ({ value, setFilter }) => {
  const { t } = useTranslation();

  return (
    <FormControl className='filtersItemSelect'>
      <Select
        displayEmpty
        disableUnderline
        value={value}
        onChange={(e) => {
          setFilter((state: any) => ({
            ...state,
            marketplace: e.target.value,
            page: 1,
          }));
        }}
      >
        <MenuItem value='disabled' disabled>
          {t('app.marketplace')}
        </MenuItem>
        <MenuItem value=''>{t('app.allMarketplaces')}</MenuItem>
        <MenuItem value={MARKETPLACES.AMAZON}>Amazon</MenuItem>
        <MenuItem value={MARKETPLACES.EBAY}>Ebay</MenuItem>
        <MenuItem value={MARKETPLACES.ETSY}>Etsy</MenuItem>
      </Select>
    </FormControl>
  );
};

export default FilterSelectMarketplace;
