import React, { useEffect, useRef } from 'react';
import {
  Button,
  CircularProgress,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  List,
  ListItem,
  MenuItem,
  Select,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import useStyles from './styles';
import {
  Field,
  FieldProps,
  Form,
  Formik,
  FormikHelpers,
  FormikProps,
} from 'formik';
import ups from '../../assets/img/carriers/ups.svg';
import dhl from '../../assets/img/carriers/dhl.svg';
import fedex from '../../assets/img/carriers/fedex.svg';
import usps from '../../assets/img/carriers/usps.png';
import tnt from '../../assets/img/carriers/TNT.png';
import apcLogistics from '../../assets/img/carriers/apc_logistics.png';
import SearchIcon from '@material-ui/icons/Search';
import {
  GetDeliveryCountriesQuery,
  GetDeliveryCountriesQueryVariables,
  useTrackParcelLazyQuery,
} from '../../generated/graphql';
import { parcelTrackSchema } from '../../utils/validationSchemes';
import { useSnackbar } from 'notistack';
import { COLORS } from '../../utils/constants';
import {
  GetCarrierCodeImage,
  RenderCountryFlagImage,
} from '../../utils/helperComponents';
import { useApolloClient } from '@apollo/client';
import { QUERY_DELIVERY_COUNTRIES } from '../../GraphQL/queries/getDeliveryCountries';
import { BoxCentered } from '../../components/BoxCentered/BoxCentered';
import { TRoutes } from '../../utils/helpers';
import { useTranslation } from 'react-i18next';

interface IParcelTrackForm {
  trackNumber: string;
  carrierCode: 'ups' | 'fedex' | 'dhl' | 'usps' | 'tnt' | 'apc';
}

export default function TrackParcelForm() {
  const classes = useStyles();
  const { t } = useTranslation();

  const { enqueueSnackbar } = useSnackbar();
  const client = useApolloClient();
  const history = useHistory();

  const { trackNumberParam, carrierCodeParam } = useParams<{
    trackNumberParam?: string;
    carrierCodeParam?: string;
  }>();
  const formikRef = useRef<FormikProps<IParcelTrackForm>>(null);

  const countries = client.readQuery<
    GetDeliveryCountriesQuery,
    GetDeliveryCountriesQueryVariables
  >({
    query: QUERY_DELIVERY_COUNTRIES,
  });

  useEffect(() => {
    // TODO: remove if fedex
    if (trackNumberParam && carrierCodeParam && carrierCodeParam === 'fedex') {
      trackParcelLazyQuery({
        variables: {
          barcode: trackNumberParam,
          carrier_code: carrierCodeParam,
        },
      });
    }
    // eslint-disable-next-line
  }, []);

  const [trackParcelLazyQuery, { loading: isLoadingLazyQuery, error, data }] =
    useTrackParcelLazyQuery({
      fetchPolicy: 'network-only',
    });

  if (error?.message) {
    enqueueSnackbar(error?.message, { variant: 'error' });
  }

  const setUrl = (carrier: string, track: string) => {
    window.history.pushState({}, '', `/track/${carrier}/${track}`);
  };

  const handlerSubmit = (
    values: IParcelTrackForm,
    { setSubmitting }: FormikHelpers<IParcelTrackForm>,
  ) => {
    trackParcelLazyQuery({
      variables: {
        barcode: values?.trackNumber,
        carrier_code: values?.carrierCode,
      },
    });
    setSubmitting(false);
  };

  const Placeholder = ({ children }: { children: string }) => (
    <div className={classes.placeholder}>{children}</div>
  );

  return (
    <Container>
      <Typography variant='h2' className={classes.mainTitle} align='center'>
        {t('app.tracking')}
      </Typography>
      <Formik
        initialValues={
          {
            trackNumber: trackNumberParam || '',
            carrierCode: carrierCodeParam || '',
          } as IParcelTrackForm
        }
        enableReinitialize
        innerRef={formikRef}
        validationSchema={parcelTrackSchema}
        onSubmit={handlerSubmit}
      >
        {({
          values,
          isSubmitting,
          setFieldValue,
        }: FormikProps<IParcelTrackForm>) => {
          return (
            <Form noValidate autoComplete='off'>
              <Grid
                container
                spacing={2}
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <Grid item xs={10} sm={8}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                      <Field name='trackNumber'>
                        {({ field: { value }, meta }: FieldProps) => (
                          <FormControl error={meta.touched && !!meta.error}>
                            <Input
                              disableUnderline
                              autoFocus
                              disabled={isSubmitting || isLoadingLazyQuery}
                              value={value}
                              placeholder={t('app.enterTrackingNumber2')}
                              onChange={(event) => {
                                setUrl(values?.carrierCode, event.target.value);
                                setFieldValue(
                                  'trackNumber',
                                  event.target.value,
                                );
                              }}
                            />
                            {meta.touched && !!meta.error && (
                              <FormHelperText>{meta.error}</FormHelperText>
                            )}
                          </FormControl>
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Field name='carrierCode'>
                        {({ field: { value }, meta }: FieldProps) => (
                          <FormControl error={meta.touched && !!meta.error}>
                            <Select
                              disabled={isSubmitting || isLoadingLazyQuery}
                              renderValue={
                                value !== ''
                                  ? undefined
                                  : () => (
                                      <Placeholder>
                                        {t('app.chooseCarrier')}
                                      </Placeholder>
                                    )
                              }
                              disableUnderline
                              displayEmpty
                              value={value}
                              onChange={(event) => {
                                setFieldValue(
                                  'carrierCode',
                                  event.target.value,
                                );
                                setUrl(
                                  event.target.value as string,
                                  values?.trackNumber,
                                );
                              }}
                            >
                              <MenuItem
                                value=''
                                className={classes.placeholder}
                                disabled
                              />
                              <MenuItem
                                value='ups'
                                className={classes.carrierCodeSelectedItem}
                              >
                                <img
                                  className={classes.carrierCodeIcon}
                                  src={ups}
                                  alt={meta.value}
                                />{' '}
                                UPS
                              </MenuItem>
                              <MenuItem
                                value='dhl'
                                className={classes.carrierCodeSelectedItem}
                              >
                                <img
                                  className={classes.carrierCodeIcon}
                                  src={dhl}
                                  alt={meta.value}
                                />{' '}
                                DHL
                              </MenuItem>
                              <MenuItem
                                value='usps'
                                className={classes.carrierCodeSelectedItem}
                              >
                                <img
                                  className={classes.carrierCodeIcon}
                                  src={usps}
                                  alt={meta.value}
                                />{' '}
                                USPS
                              </MenuItem>
                              <MenuItem
                                value='fedex'
                                className={classes.carrierCodeSelectedItem}
                              >
                                <img
                                  className={classes.carrierCodeIcon}
                                  src={fedex}
                                  alt={meta.value}
                                />{' '}
                                FEDEX
                              </MenuItem>
                              <MenuItem
                                value='tnt'
                                className={classes.carrierCodeSelectedItem}
                              >
                                <img
                                  className={classes.carrierCodeIcon}
                                  src={tnt}
                                  alt={meta.value}
                                />{' '}
                                TNT
                              </MenuItem>
                              <MenuItem
                                value='apc'
                                className={classes.carrierCodeSelectedItem}
                              >
                                <img
                                  className={classes.carrierCodeIcon}
                                  src={apcLogistics}
                                  alt={'apcLogistics'}
                                />{' '}
                                APC Postal Logistics
                              </MenuItem>
                            </Select>
                            {meta.touched && meta.error && (
                              <FormHelperText>{meta.error}</FormHelperText>
                            )}
                          </FormControl>
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Button
                        type='submit'
                        variant='contained'
                        disabled={isSubmitting || isLoadingLazyQuery}
                        startIcon={
                          isLoadingLazyQuery ? (
                            <CircularProgress size={22} />
                          ) : (
                            <SearchIcon />
                          )
                        }
                      >
                        {t('app.track')}
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      {(data?.trackParcel?.error ||
                        data?.trackParcel?.message) && (
                        <div className={classes.virtualizedWrapper}>
                          <FormHelperText
                            style={{
                              color: data?.trackParcel?.error
                                ? COLORS.RED
                                : COLORS.GREEN,
                              textAlign: 'center',
                            }}
                          >
                            {data?.trackParcel?.error ||
                              data?.trackParcel?.message}
                          </FormHelperText>
                        </div>
                      )}

                      {!data?.trackParcel?.error &&
                        data?.trackParcel?.message && (
                          <>
                            <Typography align='center'>
                              {data?.trackParcel?.statusDescription}
                            </Typography>

                            <Stepper
                              //@ts-ignore
                              activeStep={data?.trackParcel?.currentStep}
                              alternativeLabel
                            >
                              <Step>
                                <StepLabel className={classes.stepLabel}>
                                  {t('app.created')}
                                </StepLabel>
                              </Step>
                              <Step>
                                <StepLabel className={classes.stepLabel}>
                                  {t('app.accepted')}
                                </StepLabel>
                              </Step>
                              <Step>
                                <StepLabel className={classes.stepLabel}>
                                  {t('app.inTransit')}
                                </StepLabel>
                              </Step>
                              <Step>
                                <StepLabel className={classes.stepLabel}>
                                  {t('app.delivered')}
                                </StepLabel>
                              </Step>
                            </Stepper>

                            <BoxCentered flexDirection='column' mb={2}>
                              {!!data?.trackParcel?.carrier_code &&
                                !!data?.trackParcel?.track_number && (
                                  <div>
                                    <div
                                      className='cursorPointer'
                                      onClick={() => {
                                        setFieldValue(
                                          'carrierCode',
                                          data?.trackParcel?.carrier_code?.toLowerCase()!,
                                        );
                                        setFieldValue(
                                          'trackNumber',
                                          data?.trackParcel?.track_number!,
                                        );
                                        setUrl(
                                          data?.trackParcel?.carrier_code?.toLowerCase()!,
                                          data?.trackParcel?.track_number!,
                                        );
                                        history.push(
                                          TRoutes.TRACK_WITH_NUMBER_AND_CARRIED_CODE.replace(
                                            ':carrierCodeParam',
                                            data?.trackParcel?.carrier_code?.toLowerCase()!,
                                          ).replace(
                                            ':trackNumberParam',
                                            data?.trackParcel?.track_number!,
                                          ),
                                        );
                                      }}
                                    >
                                      <GetCarrierCodeImage
                                        carrierCode={
                                          data?.trackParcel?.carrier_code
                                        }
                                      />
                                      {data?.trackParcel?.track_number}
                                    </div>
                                  </div>
                                )}
                              <BoxCentered justifyContent='flex-start'>
                                {t('app.currentCountry')}: &nbsp;
                                {data?.trackParcel?.countryISO ? (
                                  <RenderCountryFlagImage
                                    countryName=''
                                    countryISO={data?.trackParcel?.countryISO}
                                  />
                                ) : (
                                  t('app.unknown')
                                )}
                                {
                                  countries?.deliveryCountries?.find((c) => {
                                    return (
                                      c &&
                                      c?.iso === data?.trackParcel?.countryISO
                                    );
                                  })?.name
                                }
                              </BoxCentered>
                              <div>
                                {t('app.currentCity')}:{' '}
                                {data?.trackParcel?.city || t('app.unknown')}
                              </div>
                            </BoxCentered>

                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={6}>
                                <Typography variant='h3' align='center'>
                                  {t('app.sender')}
                                </Typography>
                                <List>
                                  <ListItem className={classes.listItem}>
                                    {t('app.city')}: &nbsp;
                                    {data?.trackParcel?.senderCity}
                                  </ListItem>
                                  <ListItem className={classes.listItem}>
                                    {t('app.regionStateCode')}: &nbsp;
                                    {
                                      data?.trackParcel
                                        ?.senderStateOrProvinceCode
                                    }
                                  </ListItem>
                                  <ListItem className={classes.listItem}>
                                    {t('app.country')}: &nbsp;
                                    <RenderCountryFlagImage
                                      //@ts-ignore
                                      countryName={
                                        data?.trackParcel?.senderCountryISO
                                      }
                                      //@ts-ignore
                                      countryISO={
                                        data?.trackParcel?.senderCountryISO
                                      }
                                    />
                                    {
                                      countries?.deliveryCountries?.find(
                                        (c) => {
                                          return (
                                            c &&
                                            c?.iso ===
                                              data?.trackParcel
                                                ?.senderCountryISO
                                          );
                                        },
                                      )?.name
                                    }
                                  </ListItem>
                                </List>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <Typography variant='h3' align='center'>
                                  {t('app.recipient')}
                                </Typography>
                                <List>
                                  <ListItem className={classes.listItem}>
                                    {t('app.city')}: &nbsp;
                                    {data?.trackParcel?.receiverCity}
                                  </ListItem>
                                  <ListItem className={classes.listItem}>
                                    {t('app.regionStateCode')}: &nbsp;
                                    {
                                      data?.trackParcel
                                        ?.receiverStateOrProvinceCode
                                    }
                                  </ListItem>
                                  <ListItem className={classes.listItem}>
                                    {t('app.country')}: &nbsp;
                                    <RenderCountryFlagImage
                                      //@ts-ignore
                                      countryName={
                                        data?.trackParcel?.receiverCountryISO
                                      }
                                      //@ts-ignore
                                      countryISO={
                                        data?.trackParcel?.receiverCountryISO
                                      }
                                    />
                                    {
                                      countries?.deliveryCountries?.find(
                                        (c) => {
                                          return (
                                            c &&
                                            c?.iso ===
                                              data?.trackParcel
                                                ?.receiverCountryISO
                                          );
                                        },
                                      )?.name
                                    }
                                  </ListItem>
                                </List>
                              </Grid>
                            </Grid>
                          </>
                        )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Container>
  );
}
