import { ChartOptions } from 'chart.js';
import { COLORS } from '../../../../utils/constants';

const stepSize = 10;
const fontSize = 16;

const tension = 0;
const pointRadius = 4;
const borderWidth = 2;
const borderColor = COLORS.BLACK;
const pointBackgroundColor = COLORS.WHITE;
const pointBorderColor = COLORS.BLACK;
const pointBorderWidth = 2;
const fill = false;

export const options: ChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      grid: {
        color: function (context) {
          if (context.tick.value === 0) {
            return 'rgba(128, 128, 128, 0.2)';
          }
          return 'rgba(0, 0, 0, 0)';
        },
      },
      ticks: {
        color: COLORS.GREY,
        font: {
          weight: 'bold',
        },
      },
    },
    y: {
      ticks: {
        stepSize,
        color: COLORS.GREY,
        font: {
          weight: 'bold',
        },
      },
      grid: {
        drawBorder: false,
        color: function (context) {
          if (context.tick.value === 0) {
            return 'rgba(0, 0, 0, 0)';
          }
          return 'rgba(128, 128, 128, 0.2)';
        },
      },
    },
  },
  plugins: {
    legend: {
      display: false,
      labels: {
        font: {
          size: fontSize,
        },
      },
    },
    datalabels: {
      display: false,
    },
    title: {
      display: false,
    },
    tooltip: {
      mode: 'nearest',
      intersect: false,
      callbacks: {
        label: function (tooltipItem) {
          return `${tooltipItem.raw}`;
        },
      },
    },
  },
};

export const datasetsOptions = {
  tension,
  pointRadius,
  borderWidth,
  borderColor,
  pointBackgroundColor,
  pointBorderColor,
  pointBorderWidth,
  fill,
};
