import { gql } from '@apollo/client';

export const QUERY_MY_STORES = gql`
  query getMyStores {
    getMyStores {
      id
      name
      url
      userId
      storeId
      isActive
      marketplace
    }
  }
`;
