import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const styles = (theme: Theme) =>
  createStyles({
    wrapperOuter: {
      fontSize: '16px',
    },
    wrapperInner: {
      width: '300px',
      overflow: 'hiden',
      [theme.breakpoints.down('sm')]: {
        width: '200px',
      },
    },
    line: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '10px',
    },
    icons: {
      marginRight: '20px !important',
    },
    mainTitle: {
      marginBottom: 32,

      [theme.breakpoints.down('sm')]: {
        fontSize: 23,
        marginBottom: 15,
      },
    },
    alertWrapper: {
      display: 'flex',
      height: '70px',
      border: '1px solid #d7d713',
      marginTop: '20px',
    },
    alertIconWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '70px',
      borderRight: '1px solid #d7d713',
    },
    alertTextWrapper: {
      padding: '10px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    buttonAddWrapper: {
      marginTop: '20px',
      display: 'flex',
      cursor: 'pointer',
    },
    modal: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      backgroundColor: 'white',
      // border: '2px solid #000',
    },
  });

export default makeStyles(styles);
