import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
  registerables as registerablesJS,
} from 'chart.js';
import useStyles from './styles';

import { options, datasetsOptions } from './options';
import { Box, Typography } from '@material-ui/core';
import { Chart } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { OrderPerDey } from '../../../../generated/graphql';
import { getFormatDateWithDayByLocale } from '../../../../utils/helpers';

ChartJS.register(...registerablesJS);
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

interface IGraphicComponent {
  ordersPerDayList: OrderPerDey[];
}

const GraphicComponent = ({ ordersPerDayList }: IGraphicComponent) => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const dates = ordersPerDayList?.map((order) =>
    getFormatDateWithDayByLocale(i18n.resolvedLanguage, order.date),
  );
  const countsData = ordersPerDayList?.map((order) => Number(order.count));

  const data: ChartData = {
    labels: dates,
    datasets: [
      {
        data: countsData || [],
        ...datasetsOptions,
      },
    ],
  };
  return (
    <Box className={classes.graphicWrapper}>
      <Typography className={classes.graphicTitle}>
        {t('app.graphShipments')}
      </Typography>
      <Chart
        type='line'
        options={options}
        data={data}
        style={{ height: '100%' }}
      />
    </Box>
  );
};

export default GraphicComponent;
