import { makeStyles } from '@material-ui/core/styles';

export const styles = makeStyles((theme) => ({
  mainTitle: {
    marginBottom: 32,

    [theme.breakpoints.down('sm')]: {
      fontSize: 23,
      marginBottom: 15,
    },
  },
  tableTitle: {
    textAlign: 'center',
    margin: '15px 0px',
  },
  itemInfo: {
    fontSize: 18,
  },
  wrapButtons: {
    display: 'flex',
    flexDirection: 'row',
    padding: '30px 16px 10px',
    alignItems: 'center',
    justifyContent: 'center',

    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  boxModalButtons: {
    margin: '30px 0 0',
    display: 'flex',
    justifyContent: 'space-around',
  },
  warehouseSelect: {
    marginLeft: '16px',
    width: '226px',
  },
  actionIcon: {
    paddingRight: '5px',
    fontSize: '20px',
    alignSelf: 'flex-start',
  },
}));

export default styles;
