import { gql } from '@apollo/client';

export const GET_B2B_ORDER_BY_ID_QUERY = gql`
  query GetB2BOrderById($getB2BOrderByIdId: Int!) {
    getB2BOrderById(id: $getB2BOrderByIdId) {
      id
      client {
        id
        name
      }
      wrapType
      wrapWay
      shipmentInfo {
        driverInfo {
          carModel
          carNumber
          fullName
          mobileNumber
        }
        scheduledDate
        scheduledTime
        shipmentLabelFileUrl
        typeMethod
      }
      barcodeId
      externalOrdeId
      shipmentPointTitle
      warehouseName
      comment
      technicalTaskFileUrl
      products {
        productId
        expectedQuantity
        acceptedQuantity
        relatedProduct {
          barcodeId
          name
          sku
        }
      }
      cargoPlaces {
        id
        type
        externalBarcodeUrl
        uniqSkuCount
        productQty
        weight
        length
        width
        height
        productsItems {
          id
          cargoId
          productId
          quantity
        }
      }
      servicesWithCost {
        costInUSD
        description
        id
        title
        qty
      }
      totalCost
      warehouseStatus
      createdAt
      shipmentLabelFileUrl
      transportFileUrl
    }
  }
`;
