import React from 'react';
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  Link as MuiLink,
  Typography,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { Link, useHistory } from 'react-router-dom';
import { logout, TRoutes } from '../../utils/helpers';
import { email as emailSchema } from '../../utils/validationSchemes';
import useStyles from './styles';
import {
  AddEmailMutationVariables,
  useAddEmailMutation,
} from '../../generated/graphql';
import { useTranslation } from 'react-i18next';

export default function AddEmail() {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [addEmailMutation] = useAddEmailMutation();

  const { t } = useTranslation();

  const handleLogout = (e: React.MouseEvent) => {
    e.preventDefault();
    logout(history);
  };

  const handlerSubmit = (
    values: AddEmailMutationVariables,
    { setSubmitting }: FormikHelpers<AddEmailMutationVariables>,
  ) => {
    addEmailMutation({
      variables: values,
    })
      .then(() => {
        enqueueSnackbar(t('app.emailSaved'), { variant: 'success' });
        history.push(TRoutes.MAIN);
      })
      .catch((error) => enqueueSnackbar(error.message, { variant: 'error' }))
      .finally(() => setSubmitting(false));
  };

  return (
    <div>
      <Typography className={classes.authTitleConfirm} variant='h2'>
        {t('app.registrationCompletion')}
      </Typography>
      <Divider className={classes.divider} />
      <div className={classes.authInfo}>
        <p style={{ margin: 0 }}>{t('app.registrationThankYou')}</p>
      </div>
      <Formik
        initialValues={{ email: '' } as AddEmailMutationVariables}
        onSubmit={(e, v) => handlerSubmit(e, v)}
        validationSchema={emailSchema}
      >
        {({ isSubmitting, getFieldMeta, getFieldProps }) => (
          <Form noValidate autoComplete='off'>
            <Field name='email'>
              {() => (
                <FormControl
                  error={
                    !!(
                      getFieldMeta('email').touched &&
                      getFieldMeta('email').error
                    )
                  }
                  className={classes.formControl}
                >
                  <InputLabel shrink={false} htmlFor='input-email'>
                    {t('app.email')}
                  </InputLabel>
                  <Input
                    disableUnderline
                    fullWidth
                    required
                    id='input-email'
                    {...getFieldProps('email')}
                  />
                  {getFieldMeta('email').touched &&
                    getFieldMeta('email').error && (
                      <FormHelperText>
                        {getFieldMeta('email').error}
                      </FormHelperText>
                    )}
                </FormControl>
              )}
            </Field>
            <Button disabled={isSubmitting} type='submit' variant='contained'>
              {t('app.save')}
            </Button>
          </Form>
        )}
      </Formik>
      <Box mt={2}>
        <MuiLink
          className={classes.returnToSignIn}
          component={Link}
          variant='body2'
          underline='always'
          onClick={handleLogout}
          to={TRoutes.AUTH_SIGN_IN}
        >
          {t('app.backToLogin')}
        </MuiLink>
      </Box>
    </div>
  );
}
