import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { GetContactsQuery } from '../../../generated/graphql';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

const WarehouseShipmentFormStep3: React.FC<{
  values: any;
  setFieldValue: any;
  setUpdateView: any;
  contactsData: GetContactsQuery | undefined;
}> = ({ values, setFieldValue, setUpdateView, contactsData }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const contactsDataSenders = contactsData?.contacts?.filter(
    /* prettier-ignore */
    //@ts-ignore
    (contact) => contact?.type === "SENDER",
  );

  return (
    <>
      <FormControl className={classes.selectSenderContactsControl}>
        <InputLabel shrink={false} htmlFor='eori-input'>
          {t('app.chooseFromAddressBook')}
        </InputLabel>
        <Select
          className={classes.selectContacts}
          disableUnderline
          value={values?.from?.id || ''}
          onChange={(e) => {
            const contact = contactsDataSenders?.find(
              (item) => item && item.id === e.target.value,
            );
            if (contact) {
              const contactCopy = { ...contact };
              delete contactCopy.contactName;
              setFieldValue('from', contactCopy);
              setUpdateView((updateView: any) => ++updateView);
            }
          }}
          displayEmpty
        >
          {contactsDataSenders?.map((contact) =>
            contact ? (
              <MenuItem key={contact.id} value={contact.id}>
                {contact.contactName}
              </MenuItem>
            ) : null,
          )}
        </Select>
      </FormControl>
      {values.from && (
        <>
          <Typography>{values.from?.company}</Typography>
          <Typography>{values.from?.address}</Typography>
          <Typography>
            {values.from?.country?.name}, {values.from?.zipCode},{' '}
            {values.from?.city}
          </Typography>
          <Typography>{values.from?.phone}</Typography>
        </>
      )}
    </>
  );
};

export default WarehouseShipmentFormStep3;
