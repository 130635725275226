import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  authTitle: {
    marginBottom: 20,
  },
  authTitleConfirm: {
    marginBottom: 16,
  },
  resetAuthTitle: {
    marginBottom: 15,
  },
  formControl: {
    marginBottom: 24,
  },
  noAccountText: {
    marginBottom: 20,
  },
  social: {
    marginBottom: 20,
  },
  socialLabel: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#333333',
    marginBottom: 8,
    display: 'block',
  },
  socialButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'noWrap',
      flexDirection: 'column',
    },
  },
  socialButton: {
    [theme.breakpoints.down('md')]: {
      marginBottom: 5,
      width: '100%',
    },
  },
  socialButtonFullWidth: {
    marginTop: 5,
    width: '100%',

    [theme.breakpoints.down('md')]: {
      marginBottom: 0,
    },

    '& img': {
      width: 19,
      height: 19,
    },
  },
  divider: {
    marginBottom: 25,
  },
  rememberMe: {
    marginBottom: 30,
  },
  signButton: {
    marginBottom: 22,
  },
  personalDataCheckboxWrapper: {
    alignItems: 'start',
    fontSize: '16px',
    lineHeight: '24px',
    marginTop: 12,
    marginBottom: 24,
  },
  personalDataCheckbox: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  personalDataCheckboxLabel: {
    fontSize: '16px',
    lineHeight: '24px',
  },
  email: {
    fontSize: '16px',
    lineHeight: '24px',
  },
  alreadyAccountText: {
    marginTop: 17,
    marginBottom: 13,
  },
  formSignUp: {
    marginBottom: 28,
  },
  resetButton: {
    marginBottom: 48,
  },
  authInfo: {
    border: '1px solid #e0e0e0',
    padding: '24px 32px',
    marginBottom: '32px',
    fontSize: '16px',
    lineHeight: '24px',
  },
  authAlert: {
    marginTop: 0,
    marginBottom: 16,
    fontWeight: 500,
  },
  sendLetterButton: {
    marginTop: 32,
    marginBottom: 21,
    fontSize: '16px',
    lineHeight: '24px',
  },
  returnToSignIn: {
    fontSize: '16px',
    lineHeight: '24px',
    marginBottom: 20,
    display: 'block',
  },
}));
