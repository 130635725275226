import { FormControl, MenuItem, Select } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useStyles from '../styles';
import { IFilters } from '../../../utils/types';
import { filterSelectItems } from './options';

interface IFilterSelectDeliveryStatus {
  value?: string;
  setFilter: React.Dispatch<React.SetStateAction<IFilters>>;
}

const FilterSelectPaymentStatus = ({
  value,
  setFilter,
}: IFilterSelectDeliveryStatus) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <FormControl className={classes.filtersItemSelect}>
      <Select
        displayEmpty
        disableUnderline
        value={value}
        onChange={(e) => {
          setFilter((state) => ({
            ...state,
            paymentStatusId: e.target.value as string,
            page: 1,
          }));
        }}
      >
        {filterSelectItems.map(({ value, label }) => {
          if (!value)
            return (
              <MenuItem key={label} value='' disabled>
                {t(label)}
              </MenuItem>
            );
          return (
            <MenuItem key={label} value={value}>
              {t(label)}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default FilterSelectPaymentStatus;
