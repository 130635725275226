import React from 'react';
import { Formik, Form } from 'formik';
import { Button, Grid, Box } from '@material-ui/core';
import { Dropzone } from '../Dropzone/Dropzone';
import { CircularProgress } from '@material-ui/core';

import {
  useAddEntityLegalDataMutation,
  useUpdateEntityLegalDataMutation,
} from '../../generated/graphql';
import { ukFormShema } from '../../utils/validationSchemes';
import { FastFieldCustom } from './formUtils/FastFieldCustom';
import { FastFieldCustomCheckbox } from './formUtils/FastFieldCustomCheckbox';
import { useTranslation } from 'react-i18next';

type PasportFomrType = {
  urData?: any;
  handleRefetch: () => void;
};

export const UrForm = ({ urData, handleRefetch }: PasportFomrType) => {
  const [addEntityLegalDataMutation, { loading: loadingData }] =
    useAddEntityLegalDataMutation();

  const [updateEntityLegalDataMutation, { loading: loadingUpdate }] =
    useUpdateEntityLegalDataMutation();

  const { t } = useTranslation();

  if (loadingData || loadingUpdate) {
    return (
      <Box
        style={{
          height: '80vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Formik
      enableReinitialize={true}
      validationSchema={ukFormShema}
      initialValues={initDataCreated(urData)}
      onSubmit={(data) => {
        let input = {
          company: data.company,
          inn: data.inn,
          kpp: data.kpp,
          MSRN: data.MSRN,
          generalManager: data.generalManager,
          legalAddress: data.legalAddress,
          nameOfTheBank: data.nameOfTheBank,
          BIK: data.BIK,
          paymentAccount: data.paymentAccount,
          correspondentAccount: data.correspondentAccount,
          dataUpToDate: data.dataUpToDate,
        };
        if (urData) {
          updateEntityLegalDataMutation({
            variables: {
              input: input,
              file: typeof data.file === 'string' ? null : data.file,
            },
          })
            .then(() => {
              handleRefetch();
            })
            .catch((error: any) => {
              console.info(error);
            });
        } else {
          addEntityLegalDataMutation({
            variables: {
              input: input,
              file: data.file as any,
            },
          })
            .then(() => {
              handleRefetch();
            })
            .catch((error: any) => {
              console.info(error);
            });
        }
      }}
    >
      {({ setFieldValue, errors }) => {
        return (
          <Form style={{ maxWidth: '900px' }}>
            <Grid container spacing={3}>
              <Grid container item spacing={3}>
                <Grid item sm={12} md={8}>
                  <FastFieldCustom
                    setFieldValue={setFieldValue}
                    name='company'
                    label={`${t('app.organizationName')} *`}
                  />
                </Grid>
                <Grid item md={4}></Grid>
              </Grid>
              <Grid item sm={12} md={4}>
                <FastFieldCustom
                  setFieldValue={setFieldValue}
                  name='inn'
                  label={`${t('app.tin')} *`}
                />
              </Grid>
              <Grid item sm={12} md={4}>
                <FastFieldCustom
                  setFieldValue={setFieldValue}
                  name='kpp'
                  label={t('app.kpp')}
                />
              </Grid>
              <Grid item sm={12} md={4}>
                <FastFieldCustom
                  setFieldValue={setFieldValue}
                  name='MSRN'
                  label={`${t('app.ogrnOrOgrnip')} *`}
                />
              </Grid>
              <Grid container item spacing={3}>
                <Grid item sm={12} md={8}>
                  <FastFieldCustom
                    setFieldValue={setFieldValue}
                    name='generalManager'
                    label={`${t('app.ceo')} *`}
                  />
                </Grid>
                <Grid item md={4}></Grid>
              </Grid>
              <Grid container item spacing={3}>
                <Grid item sm={12} md={8}>
                  <FastFieldCustom
                    setFieldValue={setFieldValue}
                    name='legalAddress'
                    label={`${t('app.legalAddress')} *`}
                  />
                </Grid>
                <Grid item md={4}></Grid>
              </Grid>
              <Grid container item spacing={3}>
                <Grid item sm={12} md={8}>
                  <FastFieldCustom
                    setFieldValue={setFieldValue}
                    name='nameOfTheBank'
                    label={`${t('app.fullBankName')} *`}
                  />
                </Grid>
                <Grid item md={4}></Grid>
              </Grid>
              <Grid container item spacing={3}>
                <Grid item sm={12} md={4}>
                  <FastFieldCustom
                    setFieldValue={setFieldValue}
                    name='BIK'
                    label={`${t('app.bic')} *`}
                  />
                </Grid>
                <Grid item sm={12} md={4}>
                  <FastFieldCustom
                    setFieldValue={setFieldValue}
                    name='paymentAccount'
                    label={`${t('app.checkingAccount')} *`}
                  />
                </Grid>
                <Grid item sm={12} md={4}>
                  <FastFieldCustom
                    setFieldValue={setFieldValue}
                    name='correspondentAccount'
                    label={`${t('app.correspondentAccount')} *`}
                  />
                </Grid>
              </Grid>
              <Grid item sm={12} md={12}>
                <Dropzone name='file' label={t('app.uploadCertificate')} />
                <Box style={{ marginLeft: '35px' }}>
                  {t('app.filesJpgJpegPngGif')}
                </Box>
              </Grid>
              <Grid item sm={12} md={12}>
                <FastFieldCustomCheckbox
                  setFieldValue={setFieldValue}
                  name='dataUpToDate'
                  label={t('app.confirmData')}
                />
              </Grid>
              <Grid item sm={12} md={4}>
                <Button type='submit' variant='contained'>
                  {t('app.save')}
                </Button>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

const initDataCreated = (data: any) => {
  if (data) {
    return {
      company: data.company,
      inn: data.inn,
      kpp: data.kpp,
      MSRN: data.MSRN,
      generalManager: data.generalManager,
      legalAddress: data.legalAddress,
      nameOfTheBank: data.nameOfTheBank,
      BIK: data.BIK,
      paymentAccount: data.paymentAccount,
      correspondentAccount: data.correspondentAccount,
      dataUpToDate: data.dataUpToDate,
      file: '',
    };
  }
  return {
    company: '',
    inn: '',
    kpp: '',
    MSRN: '',
    generalManager: '',
    legalAddress: '',
    nameOfTheBank: '',
    BIK: '',
    paymentAccount: '',
    correspondentAccount: '',
    dataUpToDate: false,
    file: '',
  };
};
