import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { Switch as SwitchIf, Case } from 'react-if';
import FormPersonalData from '../viewes/FormPersonalData/FormPersonalData';
import NotFound from '../viewes/TextPages/NotFound/NotFound';
import { TRoutes } from '../utils/helpers';
import { User } from '../generated/graphql';
import { routes } from './routes';

const PrivateRoutes: React.FC<{ user?: User }> = ({ user }) => {
  return (
    <SwitchIf>
      <Case condition={!!user && user.fullAccess}>
        <Switch>
          {routes.map((route) => (
            <Route {...route} exact />
          ))}
        </Switch>
      </Case>
      <Case condition={!!user && !user.fullAccess}>
        <Switch>
          <Route path={TRoutes.NOT_FOUND} exact component={NotFound} />
          <Route path={TRoutes.PERSONAL} exact component={FormPersonalData} />
          <Redirect from={TRoutes.AUTH_CONFIRM} exact to={TRoutes.PERSONAL} />
          <Redirect from='*' to={TRoutes.PERSONAL} exact />
        </Switch>
      </Case>
    </SwitchIf>
  );
};

export default PrivateRoutes;
