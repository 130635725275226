import { FormControl, MenuItem, Select } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useStyles from '../styles';
import { filterSelectSortingProductsOptions } from './options';
import {
  Maybe,
  ProductSort,
  ProdyctSortType,
  SortType,
} from '../../../generated/graphql';

interface IFilterSelectSortingProducts {
  value?: Maybe<ProdyctSortType> | '';
  setFilter: React.Dispatch<React.SetStateAction<ProductSort>>;
}

const FilterSelectSortingProducts = ({
  value = '',
  setFilter,
}: IFilterSelectSortingProducts) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const onChangeHandler = (
    e: React.ChangeEvent<{
      name?: string;
      value: unknown;
    }>,
  ) => {
    setFilter((state) => ({
      ...state,
      ...(!state.way && { way: SortType.Desc }),
      type: e.target.value as ProdyctSortType,
    }));
  };

  return (
    <FormControl className={classes.filtersItemSelect}>
      <Select
        displayEmpty
        disableUnderline
        value={value}
        onChange={onChangeHandler}
      >
        {filterSelectSortingProductsOptions.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            disabled={!option.value}
          >
            {t(option.title)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default FilterSelectSortingProducts;
