import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import ExposureIcon from '@material-ui/icons/Exposure';
import InfoIcon from '@material-ui/icons/Info';
import useStyles from './styles';
import clsx from 'clsx';
import {
  FastField,
  Field,
  FieldProps,
  Form,
  Formik,
  useFormikContext,
} from 'formik';
import debounce from 'debounce';
import { fullfilmentCalculatorForm } from '../../utils/validationSchemes';
import {
  DeliveryOptionsResponseObject,
  GetDeliveryCountriesQuery,
  GetDeliveryCountriesQueryVariables,
  useGetCitiesByCountryIsoLazyQuery,
  useGetWarehouseShipmentOrderDeliveryOptionsListLazyQuery,
  useGetWarehousesListQuery,
  Warehouse,
  WarehouseShipmentOrderDeliveryOptionsInput,
} from '../../generated/graphql';
import client from '../../GraphQL/client';
import { QUERY_DELIVERY_COUNTRIES } from '../../GraphQL/queries/getDeliveryCountries';
import {
  RenderCountryFlagImage,
  ShowLoadingText,
} from '../../utils/helperComponents';
import { BoxCentered } from '../../components/BoxCentered/BoxCentered';
import { COLORS, DEBOUNCE } from '../../utils/constants';
import { Autocomplete } from '@material-ui/lab';
import { TFormikSetFieldValueDebounce } from '../../interfaces';
import { useSnackbar } from 'notistack';
import FieldWeightGross from '../../components/Packages/FieldWeightGross/FieldWeightGross';
import FieldLengthCm from '../../components/Packages/FieldLengthCm/FieldLengthCm';
import FieldWidthCm from '../../components/Packages/FieldWidthCm/FieldWidthCm';
import FieldHeightCm from '../../components/Packages/FieldHeightCm/FieldHeightCm';
import { When } from 'react-if';
import { getInfo } from '../../utils/helpers';
import { useTranslation } from 'react-i18next';

const FulfilmentCalculator = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { enqueueSnackbar } = useSnackbar();
  const formikContext = useFormikContext();
  const [cities, setCities] = useState<string[]>([]);
  const [
    warehouseShipmentDeliveryOptions,
    setWarehouseShipmentDeliveryOptions,
  ] = useState<DeliveryOptionsResponseObject | null>(null);
  const [warehouse, setWarehouse] = useState<Warehouse | null>(null);
  const [warehouseList, setWarehouseList] = useState<Warehouse[]>([]);

  const [
    getWarehouseShipmentOrderDeliveryOptionsListLazyQuery,
    { data, error, loading },
  ] = useGetWarehouseShipmentOrderDeliveryOptionsListLazyQuery({
    fetchPolicy: 'network-only',
  });

  const { data: warehouses } = useGetWarehousesListQuery();

  const dataCountries = client.readQuery<
    GetDeliveryCountriesQuery,
    GetDeliveryCountriesQueryVariables
  >({
    query: QUERY_DELIVERY_COUNTRIES,
  });

  const [
    getCitiesByCountryIsoLazyQuery,
    {
      data: dataLazyQueryGetCitiesByISO,
      loading: isLoadingLazyQueryGetCitiesByISO,
      error: errorLazyQueryGetCitiesByISO,
    },
  ] = useGetCitiesByCountryIsoLazyQuery();

  if (errorLazyQueryGetCitiesByISO?.message) {
    enqueueSnackbar(errorLazyQueryGetCitiesByISO?.message);
  }

  useEffect(() => {
    if (!data?.getWarehouseShipmentOrderDeliveryOptionsList) return;

    data?.getWarehouseShipmentOrderDeliveryOptionsList &&
      setWarehouseShipmentDeliveryOptions(
        data?.getWarehouseShipmentOrderDeliveryOptionsList as DeliveryOptionsResponseObject,
      );
  }, [data]);

  useEffect(() => {
    if (!warehouses?.getWarehousesList?.rows) return;
    warehouses?.getWarehousesList?.rows &&
      setWarehouseList(warehouses?.getWarehousesList?.rows as Warehouse[]);
  }, [warehouses]);

  /** New cities array fetched from query getCitiesByCountryIso(iso: $iso) */
  useEffect(() => {
    if (dataLazyQueryGetCitiesByISO?.getCitiesByCountryIso?.length) {
      let sorted = dataLazyQueryGetCitiesByISO?.getCitiesByCountryIso?.slice();
      sorted = sorted.sort((a, b) => {
        return a?.name && b?.name && a?.name < b?.name
          ? -1
          : a?.name && b?.name && a?.name > b.name
          ? 1
          : 0;
      });

      const uniqueCities = [...new Set(sorted.map((c) => c?.name!))];

      setCities(uniqueCities);
    }
  }, [dataLazyQueryGetCitiesByISO]);

  const handlerChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    setFieldValue: TFormikSetFieldValueDebounce,
  ) => {
    const name = e.target.name;
    const value = Number(e.target.value);
    setFieldValue(name, value);
  };

  const setSelectedCountry = (selectedCountry: any) => {
    let iso: string | undefined | null;

    iso = dataCountries?.deliveryCountries?.find(
      (c) =>
        c?.name === selectedCountry?.name ||
        c?.nameEng === selectedCountry?.nameEng,
    )?.iso;

    if (iso) {
      getCitiesByCountryIsoLazyQuery({
        variables: {
          iso: iso,
        },
      });
    }
  };

  error?.message && enqueueSnackbar(error.message, { variant: 'error' });

  const handleGetWarehouseShipmentOrderDeliveryOptionsList = (values: any) => {
    const input: WarehouseShipmentOrderDeliveryOptionsInput = {
      //@ts-ignore
      warehouseId: values?.warehouseId,
      dimensions: values?.dimensions,
      wrap: values?.wrap,
      products: [],

      receiver: {
        ...values?.receiver,
        id: Number(values?.receiver?.id),
        //@ts-ignore
        countryId: values?.receiver?.country?.id,
      },

      only_calculation: true,
      address_validation: false,
    };
    //@ts-ignore
    delete input.receiver.country;
    //@ts-ignore
    delete input.receiver.id;
    //@ts-ignore
    getWarehouseShipmentOrderDeliveryOptionsListLazyQuery({
      variables: {
        data: input,
      },
    });
  };

  const countriesToItems = useMemo(() => {
    const newArr = dataCountries?.deliveryCountries?.length
      ? [...dataCountries?.deliveryCountries].sort((a, b) => {
          return a && b && a.name < b.name
            ? -1
            : a && b && a.name > b.name
            ? 1
            : 0;
        })
      : [];

    return newArr.map((country) => {
      return (
        country && (
          <MenuItem key={country.name} value={country as any}>
            <BoxCentered justifyContent='flex-start'>
              {country?.iso && (
                <RenderCountryFlagImage
                  countryISO={country?.iso}
                  countryName={country.name}
                />
              )}
              {country.name}
            </BoxCentered>
          </MenuItem>
        )
      );
    });
    // eslint-disable-next-line
  }, []);

  const renderField = (
    name: string,
    label: string,
    setFieldValue: TFormikSetFieldValueDebounce,
    disable?: boolean,
  ) => (
    <Field name={name}>
      {({ field: { value, ...field }, meta }: FieldProps) => (
        <FormControl error={!!(meta.touched && meta.error)}>
          <InputLabel
            className={classes.inputLabel}
            shrink={false}
            htmlFor={`${name}-input`}
          >
            {label}
          </InputLabel>
          <Input
            disabled={disable}
            disableUnderline
            id={`${name}-input`}
            {...field}
            defaultValue={value}
            onChange={(e) => {
              const name = e.target.name;
              const value = e.target.value;
              setFieldValue(name, value);
            }}
          />
          {meta.touched && meta.error && (
            <FormHelperText>{meta.error}</FormHelperText>
          )}
        </FormControl>
      )}
    </Field>
  );

  return (
    <>
      <Grid container spacing={2} justify='center'>
        <Grid item xs={12} sm={12}>
          <Typography variant='h2' className={classes.mainTitle}>
            {t('app.fulfilmentCalculator').toUpperCase()}
          </Typography>
        </Grid>
      </Grid>

      <div>
        <Formik
          initialValues={{
            dimensions: { weight: '', length: '', height: '', width: '' },
            receiver: {
              country: '',
              city: '',
              zipCode: '',
              state: '',
            },
            wrap: 'DEFAULT',
          }}
          validationSchema={fullfilmentCalculatorForm}
          onSubmit={handleGetWarehouseShipmentOrderDeliveryOptionsList}
        >
          {({ errors, setFieldValue, handleSubmit }) => {
            const setFieldValueDebounce = debounce(setFieldValue, DEBOUNCE);
            return (
              <Form>
                <Box padding='20px' margin='auto'>
                  <Typography
                    className={classes.contactFieldsTitle}
                    variant='h3'
                  >
                    {t('app.from')}
                  </Typography>
                  <FormControl
                    style={{ marginBottom: '24px' }}
                    variant='standard'
                  >
                    <InputLabel shrink={false} htmlFor='warehouse'>
                      {t('app.selectWarehouse')}
                    </InputLabel>
                    <Select
                      className={classes.selectContacts}
                      id='warehouse'
                      value={
                        warehouse
                          ? warehouse
                          : warehouseList?.find(
                              (warehouse) =>
                                warehouse.id ===
                                //@ts-ignore
                                formikContext?.values?.warehouseId,
                            )
                      }
                      onChange={(e) => {
                        setFieldValue(
                          'warehouseId',
                          //@ts-ignore
                          e.target.value.id as Number,
                        );
                        setWarehouse(e.target.value as Warehouse);
                      }}
                    >
                      <MenuItem
                        value={t('app.chooseWarehouse')}
                        disabled
                        style={{ display: 'none' }}
                      >
                        {t('app.chooseWarehouse')}
                      </MenuItem>
                      {warehouseList?.map((item: any) => (
                        <MenuItem key={item.code} value={item}>
                          {item.code}
                        </MenuItem>
                      ))}
                    </Select>
                    {/* @ts-ignore */}
                    {errors?.warehouseId && (
                      <FormHelperText style={{ color: COLORS.RED }}>
                        {/* @ts-ignore */}
                        {errors?.warehouseId}
                      </FormHelperText>
                    )}
                  </FormControl>
                  <Typography
                    className={classes.contactFieldsTitle}
                    variant='h3'
                  >
                    {t('app.to')}
                  </Typography>
                  <Box margin='auto'>
                    <Grid
                      container
                      spacing={3}
                      style={{ marginBottom: '30px' }}
                    >
                      <Grid item xs={12} className={classes.input}>
                        <FastField name='receiver.country'>
                          {({ field: { value }, meta }: FieldProps) => {
                            return (
                              <FormControl
                                error={!!(meta.touched && meta.error)}
                              >
                                <InputLabel
                                  shrink={false}
                                  htmlFor={`receiver-country-input`}
                                >
                                  {t('app.destinationCountry')}
                                </InputLabel>
                                <Select
                                  disableUnderline
                                  id='receiver-country-input'
                                  value={value}
                                  onChange={(e) => {
                                    setFieldValueDebounce(
                                      'receiver.country',
                                      e.target.value,
                                    );
                                    //@ts-ignore
                                    setSelectedCountry(e.target.value);
                                  }}
                                >
                                  {countriesToItems}
                                </Select>
                                {meta.touched && meta.error && (
                                  <FormHelperText>{meta.error}</FormHelperText>
                                )}
                              </FormControl>
                            );
                          }}
                        </FastField>
                      </Grid>
                      <Grid item xs={12} md={5} className={classes.input}>
                        {renderField(
                          'receiver.zipCode',
                          `${t('app.postalZipCode')}*`,
                          setFieldValueDebounce,
                        )}
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      spacing={3}
                      style={{ marginBottom: '30px' }}
                    >
                      <Grid item xs={12} className={classes.input}>
                        <Field name='receiver.city'>
                          {({ field: { value }, meta }: FieldProps) => (
                            <Box pb={2}>
                              <InputLabel shrink={false}>
                                {`${t('app.city')}*`}
                              </InputLabel>
                              {!isLoadingLazyQueryGetCitiesByISO ? (
                                <>
                                  <Autocomplete
                                    disableClearable
                                    options={cities}
                                    freeSolo
                                    loading={isLoadingLazyQueryGetCitiesByISO}
                                    autoComplete={true}
                                    loadingText={t('app.upload')}
                                    closeText={t('app.close')}
                                    openText={t('app.open')}
                                    clearText={t('app.clear')}
                                    noOptionsText={t('app.noCities')}
                                    defaultValue={value || ''}
                                    onInputChange={(
                                      event,
                                      currentInputValue,
                                    ) => {
                                      setFieldValue(
                                        'receiver.city',
                                        currentInputValue,
                                      );
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        inputProps={{
                                          ...params.inputProps,
                                          autoComplete:
                                            'unique-city-identifier',
                                        }}
                                        InputProps={{
                                          ...params.InputProps,
                                          type: 'search',
                                        }}
                                      />
                                    )}
                                  />
                                </>
                              ) : (
                                <BoxCentered border={1} mb={2} p={2}>
                                  {value}
                                  <ShowLoadingText name={t('app.cities')} />
                                </BoxCentered>
                              )}

                              {meta?.touched && !!meta?.error && (
                                <FormHelperText style={{ color: COLORS.RED }}>
                                  {meta?.error}
                                </FormHelperText>
                              )}
                            </Box>
                          )}
                        </Field>
                      </Grid>
                      <Grid item xs={12} className={classes.input}>
                        {renderField(
                          'receiver.state',
                          `${t('app.stateRegion')}*`,
                          setFieldValueDebounce,
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                  <Box mb={3.125}>
                    <Divider />
                  </Box>
                  <Typography
                    className={classes.contactFieldsTitle}
                    variant='h3'
                  >
                    {t('app.dimensionsWeight')}
                  </Typography>
                  <Grid container spacing={4}>
                    <Grid item xs={12} sm={3}>
                      <FieldWeightGross
                        handlerChange={(e) => {
                          handlerChange(e, setFieldValueDebounce);
                        }}
                        fieldName='dimensions.weight'
                      />
                    </Grid>

                    <Grid item xs={12} sm={3}>
                      <FieldLengthCm
                        handlerChange={(e) => {
                          handlerChange(e, setFieldValueDebounce);
                        }}
                        fieldName='dimensions.length'
                      />
                    </Grid>

                    <Grid item xs={12} sm={3}>
                      <FieldWidthCm
                        handlerChange={(e) => {
                          handlerChange(e, setFieldValueDebounce);
                        }}
                        fieldName='dimensions.width'
                      />
                    </Grid>

                    <Grid item xs={12} sm={3}>
                      <FieldHeightCm
                        handlerChange={(e) => {
                          handlerChange(e, setFieldValueDebounce);
                        }}
                        fieldName='dimensions.height'
                      />
                    </Grid>
                  </Grid>

                  <Box mt={3.125} mb={3.125}>
                    <Divider />
                  </Box>
                  <Typography
                    className={classes.contactFieldsTitle}
                    variant='h3'
                  >
                    {t('app.carrier')}
                  </Typography>
                  {warehouseShipmentDeliveryOptions && (
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableCell className={classes.tableCell}>
                            {t('app.shippingOption')}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {t('app.deliveryTime')}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {t('app.cost')}
                          </TableCell>
                        </TableHead>
                        <TableBody>
                          {warehouseShipmentDeliveryOptions?.variants?.map(
                            (variant, index) => {
                              return (
                                <TableRow>
                                  <TableCell className={classes.tableCell}>
                                    <label className={classes.labelRow}>
                                      <span className={classes.labelRowText}>
                                        {variant?.name}
                                      </span>
                                      {variant?.info && (
                                        <Tooltip
                                          className={classes.tooltipIconButton}
                                          placement='top'
                                          title={getInfo(
                                            variant?.info,
                                            variant?.priceCurrency,
                                          )}
                                        >
                                          <IconButton>
                                            <InfoIcon />
                                          </IconButton>
                                        </Tooltip>
                                      )}
                                    </label>
                                  </TableCell>
                                  <TableCell className={classes.tableCell}>
                                    {variant?.days_to_delivery
                                      ? `${variant?.days_to_delivery} ${t(
                                          'app.days2',
                                        )}`
                                      : t('app.unknown')}
                                  </TableCell>
                                  <TableCell
                                    className={clsx(
                                      classes.tableCell,
                                      classes.itemResult,
                                    )}
                                  >
                                    {variant?.summary_amount
                                      ? `${variant?.summary_amount?.toFixed(
                                          2,
                                        )} ${variant.priceCurrency}`
                                      : t('app.unknown')}
                                  </TableCell>
                                </TableRow>
                              );
                            },
                          )}
                          {loading ? (
                            <div className={classes.circularProgress}>
                              <CircularProgress size={40} />
                            </div>
                          ) : null}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                  <Box mt={6} mb={6}>
                    <Divider />
                  </Box>
                  <Button
                    className={classes.calculateButton}
                    variant='contained'
                    disabled={loading}
                    onClick={() => {
                      handleSubmit();
                    }}
                    startIcon={
                      loading ? (
                        <CircularProgress size={22} />
                      ) : (
                        <ExposureIcon />
                      )
                    }
                  >
                    {t('app.calculateCost2')}
                  </Button>
                  <When condition={Boolean(Object.values(errors).length)}>
                    <p className={classes.errorsMessage}>
                      {t('app.checkAllFieldsAbove')}
                    </p>
                  </When>
                  <Box mt={3.125} mb={3.125}>
                    <Divider />
                  </Box>
                  <List>
                    <ListItem className={classes.itemInfo}>
                      {t('app.acceptanceWarehouse')}
                    </ListItem>
                    <ListItem className={classes.itemInfo}>
                      {t('app.orderProcessingCost')}
                    </ListItem>
                    <ListItem className={classes.itemInfo}>
                      {`${t('app.storageCost')}: $ ${
                        warehouseShipmentDeliveryOptions?.monthly_pay_amount
                          ? warehouseShipmentDeliveryOptions?.monthly_pay_amount
                          : t('app.calculateCost3')
                      }`}
                    </ListItem>
                    <ListItem className={classes.itemInfo}>
                      {t('app.discountInformation')}
                    </ListItem>
                  </List>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default FulfilmentCalculator;
