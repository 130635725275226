import { History } from 'history';
import { t } from 'i18next';
import moment from 'moment';

type createWarehouseShipmentType = {
  createWarehouseForwardingMutation: any;
  createWarehouseShipmentUseProductsMutation: any;
  editWarehouseShipmentUseProductsMutation: any;
  values: any;
  enqueueSnackbar: any;
  history: History;
  setSavedWarehouseShipmentId: React.Dispatch<
    React.SetStateAction<string | null>
  >;
  isForwarding?: boolean;
  isEditShipment?: boolean;
  id?: string;
};

export const createWarehouseShipment = ({
  createWarehouseForwardingMutation,
  createWarehouseShipmentUseProductsMutation,
  editWarehouseShipmentUseProductsMutation,
  values,
  enqueueSnackbar,
  history,
  setSavedWarehouseShipmentId,
  isForwarding,
  isEditShipment,
  id,
}: createWarehouseShipmentType) => {
  let input = {
    from: {
      name: values.from.name,
      company: values.from.company,
      //@ts-ignore
      countryId: values.from.country.id,
      address: values.from.address,
      address2: values.from.address2,
      address3: values.from.address3,
      zipCode: values.from.zipCode,
      city: values.from.city,
      state: values.from.state,
      phone: values.from.phone,
      email: values.from.email,
      innKppCode: values.from.innKppCode,
      remark: values.from.remark,
      eoriCode: values.from.eoriCode,
    },
    //@ts-ignore
    packages: values.cargoPlaces.map((place) => ({
      width: place.width,
      height: place.height,
      weight: place.weight,
      length: place.length,
    })),
    warehouseId: values.warehouseId,
    ...((!isForwarding || isEditShipment) && {
      products: values.products,
      expectedArrivalDate: values.expectedArrivalDate,
      comment: values.comment,
    }),
    ...(isForwarding && {
      trackNumberItems: values.trackNumberItems,
    }),

    carrierCode: values.carrierCode,
    trackNumber: values.trackNumber,
  };

  const editInput = {
    products: values.products,
    expectedArrivalDate: moment(values.expectedArrivalDate).toISOString(),
    comment: values.comment,
    trackNumber: values.trackNumber,
    warehouseId: values.warehouseId,
    //@ts-ignore
    packages: values.cargoPlaces.map((place) => ({
      width: place.width,
      height: place.height,
      weight: place.weight,
      length: place.length,
    })),
  };

  if (isForwarding) {
    createWarehouseForwardingMutation({
      variables: {
        input,
      },
    })
      .then((response: any) => {
        enqueueSnackbar(
          t('app.shipmentToWarehouseSuccess', {
            value:
              response?.data?.createWarehouseForwarding?.barcode?.substring(
                0,
                response?.data?.createWarehouseForwarding?.barcode.length - 4,
              ),
          }),
          {
            variant: 'success',
          },
        );
        setSavedWarehouseShipmentId(
          response?.data?.createWarehouseForwarding?.id,
        );
      })
      .catch((err: any) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      });
  } else if (isEditShipment) {
    editWarehouseShipmentUseProductsMutation({
      variables: {
        input: editInput,
        editWarehouseShipmentUseProductsId: Number(id),
      },
    })
      .then((response: any) => {
        enqueueSnackbar(
          t('app.shipmentToWarehouseSuccess', {
            value:
              response?.data?.editWarehouseShipmentUseProducts?.barcode?.substring(
                0,
                response?.data?.editWarehouseShipmentUseProducts?.barcode
                  .length - 4,
              ),
          }),
          {
            variant: 'success',
          },
        );
        setSavedWarehouseShipmentId(
          response?.data?.editWarehouseShipmentUseProducts?.id,
        );
      })
      .catch((err: any) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      });
  } else {
    createWarehouseShipmentUseProductsMutation({
      variables: {
        input,
      },
    })
      .then((response: any) => {
        enqueueSnackbar(
          t('app.shipmentToWarehouseSuccess', {
            value:
              response?.data?.createWarehouseShipmentUseProducts?.barcode?.substring(
                0,
                response?.data?.createWarehouseShipmentUseProducts?.barcode
                  .length - 4,
              ),
          }),
          {
            variant: 'success',
          },
        );
        setSavedWarehouseShipmentId(
          response?.data?.createWarehouseShipmentUseProducts?.id,
        );
      })
      .catch((err: any) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      });
  }
};
