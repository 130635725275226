import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { COLORS } from '../../../utils/constants';

export const styles = (theme: Theme) =>
  createStyles({
    mainTitle: {
      marginBottom: 32,
      textTransform: 'uppercase',

      [theme.breakpoints.down('sm')]: {
        fontSize: 23,
        marginBottom: 15,
      },
    },
    actions: {
      paddingLeft: 31,
      marginTop: 24,
      marginBottom: 63,

      [theme.breakpoints.down('xs')]: {
        paddingLeft: 20,
        paddingRight: 20,
      },
    },
    arrangeAction: {
      marginRight: 16,
    },
    action: {
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        marginBottom: 10,
      },
    },
    errorsMessage: {
      color: '#f44336',
    },
    wrapper: {
      borderTop: '1px solid #e0e0e0',
      paddingLeft: 40,
      paddingRight: 40,
      paddingTop: 24,
      width: '100%',

      [theme.breakpoints.down('sm')]: {
        paddingLeft: 20,
        paddingRight: 20,
      },
    },
    contactFieldsTitle: {
      marginBottom: 17,
    },
    selectContacts: {
      maxWidth: 204,
      marginBottom: 24,
      width: '100%',

      '& .MuiSelect-select': {
        height: '41px',
        paddingRight: 45,
        boxSizing: 'border-box',
      },

      '& .MuiSelect-icon': {
        right: 14,
      },
    },
    contactFields: {
      maxWidth: 344,

      '& .MuiFormControl-root': {
        marginBottom: 24,
      },

      '& .MuiFormControl-root:last-child': {
        marginBottom: 0,
      },

      [theme.breakpoints.down('sm')]: {
        padding: 0,
        marginBottom: 50,
        maxWidth: 'unset',

        '& .MuiFormControl-root': {
          maxWidth: 'unset',
          width: '100%',
        },

        '&:last-child': {
          marginBottom: 0,
        },
      },
    },
    departureDatePicker: {
      maxWidth: 261,

      '& .MuiInput-root': {
        '&:after': {
          content: 'none',
        },
        '&:before': {
          content: 'none',
        },
      },
    },
    showButton: {
      marginBottom: 24,
      backgroundColor: '#ebebeb',
      borderRadius: '3px',
      border: '1px solid #ebebeb',
    },
    labelRow: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: 0,
      marginRight: 0,
      paddingTop: '10px',
    },
    radio: {
      padding: 0,
    },
    labelRowText: {
      fontWeight: 'bold',
      marginLeft: 10,
      marginRight: 10,
    },
    tooltipIconButton: {
      padding: 0,
    },
    tableCell: {
      paddingLeft: 0,
    },
    errorIcon: {
      color: COLORS.RED,
    },
    itemResult: {
      fontWeight: 'bold',
      fontSize: 16,
    },
    root: {
      marginTop: 30,
      paddingTop: 30,
      borderTop: '1px solid #e0e0e0',

      '&:first-child': {
        marginTop: 0,
        paddingTop: 0,
        border: 'none',
      },
    },
    productItem: {
      padding: '20px',
      borderRadius: '10px',
      border: '2px solid transparent',

      '&:hover': {
        backgroundColor: '#dcdcdc',
        border: '2px solid brown',
      },
    },
    removeProduct: {
      cursor: 'pointer',
      color: COLORS.RED,
    },
    circularProgress: {
      overflow: 'hidden',
    },
    calculateButton: {
      marginRight: '20px',
    },
    parcelInfo: {
      fontSize: 16,
      marginTop: 20,
      marginBottom: 20,
      display: 'flex',
      flexWrap: 'wrap',
      whiteSpace: 'nowrap',
      [theme.breakpoints.down(1050)]: {
        flexDirection: 'column',
      },
    },
    parcelInfoDivider: {
      height: 22,
      marginRight: 5,
      marginLeft: 5,

      [theme.breakpoints.down(1050)]: {
        display: 'none',
      },
    },
    closeIcon: {
      cursor: 'pointer',
      width: '14px',
      height: '14px',
      marginRight: '5px',
    },
  });

export default makeStyles(styles);
