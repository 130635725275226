import {
  IconButton,
  List,
  ListItem,
  Tooltip,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import useStyles from './styles';
import InfoIcon from '@material-ui/icons/Info';
import { BoxCentered } from '../../components/BoxCentered/BoxCentered';
import { ShowLoadingText } from '../../utils/helperComponents';
import { AnalyticData, useGetAnalyticDataQuery } from '../../generated/graphql';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

const Analytics = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [analyticData, setAnalyticData] = useState<AnalyticData | null>(null);
  const { t } = useTranslation();

  const { data, error, loading } = useGetAnalyticDataQuery();

  if (error) enqueueSnackbar(error.message, { variant: 'error' });

  useEffect(() => {
    if (data?.getAnalyticData) {
      setAnalyticData(data?.getAnalyticData);
    }
  }, [data]);

  return (
    <>
      {!loading ? (
        <>
          <Typography variant='h2' align='center' className={classes.mainTitle}>
            {t('app.analytics')}
          </Typography>

          <List>
            <ListItem>
              <Typography variant='h6'>
                {t('app.storageOfGoodsInWarehouse')}
                <Tooltip placement='top' title='///'>
                  <IconButton>
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              </Typography>
            </ListItem>
            {analyticData?.quantity &&
            analyticData?.volume &&
            analyticData?.fee ? (
              <>
                <ListItem className={classes.itemInfo}>
                  {t('app.storageOfGoodsInWarehouse', {
                    value: analyticData?.quantity,
                  })}
                </ListItem>
                <ListItem className={classes.itemInfo}>
                  {t('app.volumeOfAllGoods', {
                    value: analyticData?.volume?.toFixed(8),
                  })}
                </ListItem>
                <ListItem className={classes.itemInfo}>
                  {t('app.costOfStoringAllGoods', {
                    value: analyticData?.fee?.toFixed(2),
                  })}
                </ListItem>
              </>
            ) : (
              <ListItem className={classes.itemInfo}>
                {t('app.waitForFirstAnalyticsWriteOff')}
              </ListItem>
            )}
          </List>
        </>
      ) : (
        <BoxCentered>
          <ShowLoadingText name={t('app.analytics2')} />
        </BoxCentered>
      )}
    </>
  );
};

export default Analytics;
