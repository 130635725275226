import React, { useState } from 'react';
import {
  Typography,
  Link as MuiLink,
  FormControl,
  Checkbox,
  InputLabel,
  Button,
  Grid,
  Input,
  InputAdornment,
  IconButton,
  Divider,
  FormHelperText,
  Tooltip,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { Formik, Field, Form, FormikProps, FormikHelpers } from 'formik';
import { useSnackbar } from 'notistack';
import useStyles from './styles';
import { TRoutes } from '../../utils/helpers';
import { signUp as signUpSchema } from '../../utils/validationSchemes';
import { QUERY_CURRENT_USER } from '../../GraphQL/queries/getCurrentUser';
import {
  SignUpMutationVariables,
  useSignUpMutation,
} from '../../generated/graphql';
import { useApolloClient } from '@apollo/client';
import { useHistory } from 'react-router';
import { LOCAL_STORAGE_KEYS } from '../../utils/constants';
import { BoxCentered } from '../../components/BoxCentered/BoxCentered';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { useTranslation } from 'react-i18next';

const SignUp: React.FC = () => {
  const [isShowPassword, toggleShowPassword] = useState(false);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const client = useApolloClient();
  const history = useHistory();
  const [signUpMutation, { loading: isLoadingMutationSignUp }] =
    useSignUpMutation();

  const { t } = useTranslation();

  const handlerSubmit = (
    values: SignUpMutationVariables,
    { setSubmitting }: FormikHelpers<SignUpMutationVariables>,
  ) => {
    signUpMutation({
      variables: {
        email: values.email,
        name: values.name,
        password: values.password,
      },
    })
      .then(({ data }) => {
        if (data && data.signUp && data.signUp.token) {
          localStorage.setItem(
            LOCAL_STORAGE_KEYS.AUTH_TOKEN,
            data.signUp.token,
          );

          client
            .query({
              query: QUERY_CURRENT_USER,
              fetchPolicy: 'network-only',
            })
            .then(() => history.push(TRoutes.MAIN));
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.message, {
          variant: 'error',
        });
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <div>
      <Typography variant='h2' align='center'>
        {t('app.registration')}
      </Typography>
      <BoxCentered className={classes.alreadyAccountText}>
        {t('app.alreadyHaveAccount')} &nbsp;
        <Tooltip title='Войти в существующий аккаунт через почту и пароль или с помощью социальной сети?'>
          <MuiLink component={Link} variant='body2' to={TRoutes.AUTH_SIGN_IN}>
            {t('app.logInAlt')}
          </MuiLink>
        </Tooltip>
      </BoxCentered>
      <Divider className={classes.divider} />

      <Formik
        initialValues={
          {
            name: '',
            email: '',
            password: '',
            agreePersonalData: false,
          } as SignUpMutationVariables
        }
        validationSchema={signUpSchema}
        onSubmit={handlerSubmit}
      >
        {(props: FormikProps<SignUpMutationVariables>) => {
          const { isSubmitting, getFieldMeta, getFieldProps } = props;

          return (
            <Form className={classes.formSignUp} noValidate autoComplete='on'>
              <Field name='name'>
                {() => (
                  <FormControl
                    error={
                      !!(
                        getFieldMeta('name').touched &&
                        getFieldMeta('name').error
                      )
                    }
                    className={classes.formControl}
                  >
                    <InputLabel shrink={false} htmlFor='input-name'>
                      {t('app.name2')}
                    </InputLabel>
                    <Input
                      disableUnderline
                      fullWidth
                      required
                      id='input-name'
                      {...getFieldProps('name')}
                    />
                    {getFieldMeta('name').touched &&
                      getFieldMeta('name').error && (
                        <FormHelperText>
                          {getFieldMeta('name').error}
                        </FormHelperText>
                      )}
                  </FormControl>
                )}
              </Field>
              <Field name='email'>
                {() => (
                  <FormControl
                    error={
                      !!(
                        getFieldMeta('email').touched &&
                        getFieldMeta('email').error
                      )
                    }
                    className={classes.formControl}
                  >
                    <InputLabel shrink={false} htmlFor='input-email'>
                      {t('app.email')}
                    </InputLabel>
                    <Input
                      disableUnderline
                      fullWidth
                      required
                      id='input-email'
                      {...getFieldProps('email')}
                    />
                    {getFieldMeta('email').touched &&
                      getFieldMeta('email').error && (
                        <FormHelperText>
                          {getFieldMeta('email').error}
                        </FormHelperText>
                      )}
                  </FormControl>
                )}
              </Field>
              <Field name='password'>
                {() => (
                  <FormControl
                    error={
                      !!(
                        getFieldMeta('password').touched &&
                        getFieldMeta('password').error
                      )
                    }
                    className={classes.formControl}
                  >
                    <InputLabel shrink={false} htmlFor='input-password'>
                      {t('app.password')}
                    </InputLabel>
                    <Input
                      disableUnderline
                      fullWidth
                      id='input-password'
                      type={isShowPassword ? 'text' : 'password'}
                      {...getFieldProps('password')}
                      endAdornment={
                        <InputAdornment
                          position='end'
                          onClick={() => toggleShowPassword(!isShowPassword)}
                        >
                          <IconButton aria-label='toggle password visibility'>
                            {isShowPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {getFieldMeta('password').touched &&
                      getFieldMeta('password').error && (
                        <FormHelperText>
                          {getFieldMeta('password').error}
                        </FormHelperText>
                      )}
                  </FormControl>
                )}
              </Field>
              <Field name='agreePersonalData'>
                {() => (
                  <FormControl
                    error={
                      !!(
                        getFieldMeta('agreePersonalData').touched &&
                        getFieldMeta('agreePersonalData').error
                      )
                    }
                    className={classes.personalDataCheckboxWrapper}
                  >
                    <Grid container wrap='nowrap' alignItems='flex-start'>
                      <Checkbox
                        className={classes.personalDataCheckbox}
                        id='input-agree-personal-data'
                        color='default'
                        {...getFieldProps('agreePersonalData')}
                      />
                      <label htmlFor='input-agree-personal-data'>
                        {t('app.agreeToTerms')}&nbsp;
                        <Tooltip title='Кликните на эту ссылку, чтобы открыть пользовательское соглашение для веб-сайта в новой вкладке браузера.'>
                          <MuiLink
                            className={classes.personalDataCheckboxLabel}
                            variant='body2'
                            target='_blank'
                            href='https://picknpack.pro/privacy-policy'
                          >
                            {t('app.personalData')}
                          </MuiLink>
                        </Tooltip>
                      </label>
                    </Grid>
                    {getFieldMeta('agreePersonalData').touched &&
                      getFieldMeta('agreePersonalData').error && (
                        <FormHelperText>
                          {getFieldMeta('agreePersonalData').error}
                        </FormHelperText>
                      )}
                  </FormControl>
                )}
              </Field>

              <BoxCentered>
                <Tooltip title={t('app.clickToValidateForm')}>
                  <Button
                    className={classes.signButton}
                    disabled={isSubmitting || isLoadingMutationSignUp}
                    type='submit'
                    variant='contained'
                    startIcon={<PersonAddIcon />}
                  >
                    {t('app.signUpAlt')}
                  </Button>
                </Tooltip>
              </BoxCentered>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default SignUp;
