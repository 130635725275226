import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  mainTitle: {
    marginBottom: 32,

    [theme.breakpoints.down('sm')]: {
      fontSize: 23,
      marginBottom: 15,
    },
  },
  itemInfo: {
    fontSize: 18,
  },
  btnIcon: {
    float: 'right',
    marginLeft: 5,
    lineHeight: '30px',
  },
  title: { textTransform: 'uppercase' },
}));
