import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { COLORS } from '../../../utils/constants';

export const styles = (theme: Theme) =>
  createStyles({
    mainTitle: {
      marginBottom: 32,
      textTransform: 'uppercase',

      [theme.breakpoints.down('sm')]: {
        fontSize: 23,
        marginBottom: 15,
      },
    },
    actions: {
      paddingLeft: 31,
      marginTop: 24,
      marginBottom: 63,

      [theme.breakpoints.down('xs')]: {
        paddingLeft: 20,
        paddingRight: 20,
      },
    },
    arrangeAction: {
      marginRight: 16,
    },
    action: {
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        marginBottom: 10,
      },
    },
    errorsMessage: {
      color: '#f44336',
    },
    wrapper: {
      borderTop: '1px solid #e0e0e0',
      paddingLeft: 40,
      paddingRight: 40,
      paddingTop: 24,
      width: '100%',

      [theme.breakpoints.down('sm')]: {
        paddingLeft: 20,
        paddingRight: 20,
      },
    },
    contactFieldsTitle: {
      marginBottom: 24,
    },
    selectContacts: {
      maxWidth: 204,
      width: '100%',

      '& .MuiSelect-select': {
        height: '41px',
        paddingRight: 45,
        boxSizing: 'border-box',
      },

      '& .MuiSelect-icon': {
        right: 14,
      },
    },
    contactFields: {
      maxWidth: 344,

      '& .MuiFormControl-root': {
        marginBottom: 24,
      },

      '& .MuiFormControl-root:last-child': {
        marginBottom: 0,
      },

      [theme.breakpoints.down('sm')]: {
        padding: 0,
        marginBottom: 50,
        maxWidth: 'unset',

        '& .MuiFormControl-root': {
          maxWidth: 'unset',
          width: '100%',
        },

        '&:last-child': {
          marginBottom: 0,
        },
      },
    },
    noStylesTable: {
      boxShadow: 'none',
      '& .MuiTable-root': {
        border: 'none',
      },
      '& .MuiTableCell-root': {
        border: 'none',
        backgroundColor: 'transparent',
      },
      '& .MuiTableRow-root': {
        backgroundColor: 'transparent',
      },
    },
    packageIconWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      cursor: 'pointer',
      border: `1px solid ${COLORS.BLACK}`,
      padding: '10px 20px 10px 20px',
    },
    packageIconsWrapper: {
      display: 'flex',
      gap: '24px',
      marginBottom: 24,
    },
    packageIcon: { width: '58px', height: '58px' },
    labelRow: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: 0,
      marginRight: 0,
      paddingTop: '10px',
    },
    radio: {
      padding: 0,
    },
    labelRowText: {
      marginLeft: 10,
      marginRight: 10,
    },
    tooltipIconButton: {
      padding: 0,
    },
    closeIcon: {
      cursor: 'pointer',
      width: '14px',
      height: '14px',
      marginRight: '5px',
    },
    datePicker: {
      width: '148px',
      borderRedius: '3px',
      '& .MuiInputBase-input': { padding: '4px 8px' },
    },
    adornment: {
      height: '28px',
      backgroundColor: COLORS.LIGHT_GREY,
      borderRadius: '0 4px 4px 0',
      padding: '0 4px',
      display: 'flex',
      alignItems: 'center',
    },
    calendarIcon: { width: '15px', height: '15px' },
    pickerWrapper: { display: 'flex', gap: '24px', marginBottom: '24px' },
    checkBox: { paddingLeft: 0 },
    productsInformation: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: '20px',
      marginTop: '24px',
    },
  });

export default makeStyles(styles);
