import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: '24px',
    marginTop: '24px',
  },
  filtersItemSelect: {
    width: 'auto',
    '& .MuiInput-formControl': {
      border: 'none',
      '& .MuiInput-input': {
        fontSize: '22px',
        lineHeight: '100%',
      },

      [theme.breakpoints.down('xs')]: {
        '& .MuiInput-input': {
          paddingLeft: 0,
        },
      },
    },
  },
  icon: {
    width: '48px',
    height: '48px',
  },
}));
