import React from 'react';
import {
  Box,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';

const Requisites: React.FC = () => {
  return (
    <div>
      <Box m={'15px 0'}>
        <Typography variant='h2' align='center'>
          ЮРИДИЧЕСКАЯ ИНФОРМАЦИЯ
        </Typography>
      </Box>

      <Divider />

      <Box m={'15px 0'}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell colSpan={2}>
                  ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ "СКАЛА ГРУПП"
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>ОГРН</TableCell>
                <TableCell>1215700000680</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>ИНН</TableCell>
                <TableCell>5753075251</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>КПП</TableCell>
                <TableCell>575301001</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Банк</TableCell>
                <TableCell>МОСКОВСКИЙ ФИЛИАЛ АО КБ "МОДУЛЬБАНК"</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>БИК</TableCell>
                <TableCell>044525092</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Корреспондентский счёт</TableCell>
                <TableCell>30101810645250000092</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Cчёт</TableCell>
                <TableCell>40702810270010260524</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </div>
  );
};

export default Requisites;
