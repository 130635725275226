import React, { useEffect, useRef, useState } from 'react';
import useStyles from './styles';
import { Box, Button, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import {
  LIMIT_ITEM_UNREAD_NOTIFICATION,
  TRoutes,
} from '../../../utils/helpers';
import { INewNotificationsListComponent } from './types';
import {
  Maybe,
  Notification,
  useGetMyLatesUnreadNotificationsListLazyQuery,
  useReadNotificationsMutation,
} from '../../../generated/graphql';
import { useSnackbar } from 'notistack';
import { IFilters } from '../../../utils/types';
import RequestHandler from '../../RequestHandler/RequestHandler';
import PaginationComponent from '../../PaginationComponent/PaginationComponent';
import NotificationItemComponent from './NotificationItemComponent';

const NewNotificationsListComponent = ({
  onCloseList,
  handleRefetchMyLatestMessages,
  messageCount,
}: INewNotificationsListComponent) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const listRef = useRef<HTMLDivElement>(null);

  const [filter, setFilter] = useState<IFilters>({
    page: 1,
    itemsLimit: LIMIT_ITEM_UNREAD_NOTIFICATION,
  });

  const [getNotificationListLazyQuery, { data, loading }] =
    useGetMyLatesUnreadNotificationsListLazyQuery({
      onError: (error) => {
        if (error) {
          enqueueSnackbar(error.message, { variant: 'error' });
        }
      },
      fetchPolicy: 'network-only',
    });

  const [readNotification, { loading: readNotificationLoading }] =
    useReadNotificationsMutation();

  const refetchNotificationListLazyQuery = () => {
    getNotificationListLazyQuery({
      variables: {
        offset: (filter.page! - 1) * filter.itemsLimit!,
        limit: filter.itemsLimit,
      },
    });
    handleRefetchMyLatestMessages();
  };

  useEffect(() => {
    refetchNotificationListLazyQuery();
    // eslint-disable-next-line
  }, [filter, messageCount]);

  const handleClickOutside = (event: MouseEvent) => {
    if (listRef.current && !listRef.current.contains(event.target as Node)) {
      onCloseList();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleReadNotification = (notificationId?: Maybe<number>) => () => {
    readNotification({
      variables: { notificationId },
      onCompleted: (res) => {
        if (res) {
          refetchNotificationListLazyQuery();
          handleRefetchMyLatestMessages();
        }
      },
      onError: (error) => {
        if (error) {
          enqueueSnackbar(error.message, { variant: 'error' });
        }
      },
    });
  };

  const handleNavigateToNotificationsPage = () => {
    handleReadAllNotifications();
    history.push(TRoutes.NOTIFICATIONS);
    onCloseList();
  };

  const handleReadAllNotifications = () => {
    readNotification({
      onCompleted: (res) => {
        if (res) {
          refetchNotificationListLazyQuery();
          handleRefetchMyLatestMessages();
        }
      },
      onError: (error) => {
        if (error) {
          enqueueSnackbar(error.message, { variant: 'error' });
        }
      },
    });
  };

  const rows = data?.getMyLatesUnreadNotificationsList?.rows;
  const count = data?.getMyLatesUnreadNotificationsList?.count;

  return (
    <div className={classes.wrapper} ref={listRef}>
      <RequestHandler loading={loading}>
        {count ? (
          <>
            <Box className={classes.titleWrapper}>
              <Typography className={classes.title}>
                {t('app.notifications')}
              </Typography>
              <Button
                variant='outlined'
                className={classes.button}
                onClick={handleReadAllNotifications}
              >
                {t('app.clearAll')}
              </Button>
            </Box>
            {rows?.map((item) => (
              <NotificationItemComponent
                item={item as Notification}
                readNotification={handleReadNotification}
                loading={readNotificationLoading}
              />
            ))}
            {count && count > filter?.itemsLimit! ? (
              <PaginationComponent
                count={count}
                limit={filter?.itemsLimit as number}
                currentPage={filter.page!}
                setFilter={setFilter}
              />
            ) : null}
          </>
        ) : (
          <Typography>
            <Typography className={classes.title}>
              {t('app.noNewNotifications')}
            </Typography>
          </Typography>
        )}
        <Box marginBottom='20px' />
        <Link
          className={classes.link}
          to='#'
          onClick={handleNavigateToNotificationsPage}
        >
          {t('app.showAll')}
        </Link>
      </RequestHandler>
    </div>
  );
};

export default NewNotificationsListComponent;
