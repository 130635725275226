import { Table, TableBody, TableContainer } from '@material-ui/core';
import React from 'react';
import { ITableList } from './types';
import { TableListHeader } from './TableListHeader/TableListHeader';
import { TableListRow } from './TableListRow/TableListRow';

const TableList = ({ headCells, rows, currency }: ITableList) => {
  return (
    <TableContainer>
      <Table>
        <TableListHeader headCells={headCells} currency={currency} />
        <TableBody>
          {rows?.map((row, index) => (
            <TableListRow
              key={row.id}
              row={row}
              headCells={headCells}
              number={index + 1}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableList;
