import React, { useState } from 'react';
import { useGetCurrentUserQuery } from '../../generated/graphql';
import { Box, Typography, FormControlLabel, Grid } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';

import useStyles from './style';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import AddIcon from '@material-ui/icons/Add';
import ModalBox from '../../components/ModalBox/ModalBox';
import { PasportForm } from '../../components/PasportForm/PasportForm';
import { UrForm } from '../../components/PasportForm/UrForm';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import fetchImageFromServer from '../../utils/fetchImageFromServer';

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const UserDataVerify = () => {
  const { t } = useTranslation();

  const [imageURL, setImageURL] = useState('');
  const [isOpenModal, setIsOpenModal] = useState(false);
  const { data: userData, refetch } = useGetCurrentUserQuery({
    fetchPolicy: 'cache-first',
  });

  const [selectedValue, setSelectedValue] = useState('ur');

  const user = userData?.currentUser;
  const classes = useStyles();

  const [isOpenUnitForm, setIsOpenUnitForm] = useState(false);

  const handleRefetch = () => {
    refetch();
    setIsOpenUnitForm(false);
  };

  const verify =
    user?.passportData?.isDataVerified || user?.entityLegalData?.isDataVerified;

  return (
    <Box>
      <Typography variant='h2' className={classes.mainTitle} align='center'>
        {t('app.verification').toUpperCase()}
      </Typography>
      {user?.passportData ? (
        <Grid item md={6} style={{ display: 'flex' }}>
          <Box className={classes.wrapperInner}>
            <Box className={classes.line}>{t('app.passportData')}</Box>
            <Box className={classes.line}>
              {`${t('app.fullName')}: ${user?.passportData?.lastName} ${
                user?.passportData?.firstName
              } ${user?.passportData?.middleName}`}
            </Box>
            <Box className={classes.line}>
              {`${t('app.dateOfBirth')}: ${user?.passportData?.birthDay}`}
            </Box>
            <Box className={classes.line}>
              {`${t('app.seriesAndNumber')}: ${
                user?.passportData?.seriesAndNumberOfPassport
              }`}
            </Box>
            <Box className={classes.line}>
              {`${t('app.dateOfIssue')}: ${
                user?.passportData?.dateOfIssueOfTheDocument
              }`}
            </Box>
            <Box className={classes.line}>
              {`${t('app.tin')}: ${user?.passportData?.inn}`}
            </Box>
            <Link
              className={classes.line}
              to='#'
              onClick={() => {
                fetchImageFromServer(setImageURL, user);
                setIsOpenModal(true);
              }}
            >
              {t('app.scannedCopyPassport')}
            </Link>
          </Box>
          <Box>
            {user?.passportData?.isDataVerified ? <VerifiedUserIcon /> : null}
          </Box>
        </Grid>
      ) : null}

      {user?.entityLegalData ? (
        <Grid item md={6} style={{ display: 'flex' }}>
          <Box className={classes.wrapperInner}>
            <Box className={classes.line}>{t('app.organization')}</Box>
            <Box className={classes.line}>{`${t(
              'app.individualEntrepreneur',
            )}: ${user?.entityLegalData?.company}`}</Box>
            <Box className={classes.line}>{`${t('app.tinKpp')}: ${
              user?.entityLegalData?.inn
            }/${user?.entityLegalData?.kpp}`}</Box>
            <Box className={classes.line}>{`${t('app.ogrn')}: ${
              user?.entityLegalData?.MSRN
            }`}</Box>
            <Box className={classes.line}>{`${t('app.generalDirector')}: ${
              user?.entityLegalData?.generalManager
            }`}</Box>
            <Box className={classes.line}>{`${t('app.legalAddress')}: ${
              user?.entityLegalData?.legalAddress
            }`}</Box>
            <Box className={classes.line}>{`${t('app.fullBankName')}:${
              user?.entityLegalData?.nameOfTheBank
            }`}</Box>
            <Box className={classes.line}>{`${t('app.bic')}:${
              user?.entityLegalData?.BIK
            }`}</Box>
            <Box className={classes.line}>{`${t('app.accountNumber2')}: ${
              user?.entityLegalData?.paymentAccount
            }`}</Box>
            <Box className={classes.line}>{`${t(
              'app.correspondentAccount2',
            )}: ${user?.entityLegalData?.correspondentAccount}`}</Box>
            <Link
              className={classes.line}
              to='#'
              onClick={() => {
                fetchImageFromServer(setImageURL, user);
                setIsOpenModal(true);
              }}
            >
              {t('app.document')}
            </Link>
          </Box>
          <Box>
            {user?.entityLegalData?.isDataVerified ? (
              <VerifiedUserIcon />
            ) : null}
          </Box>
        </Grid>
      ) : null}
      {!verify ? (
        <>
          <Box className={classes.alertWrapper}>
            <Box className={classes.alertIconWrapper}>
              <ReportProblemIcon />
            </Box>
            <Box className={classes.alertTextWrapper}>
              <Box>{t('app.accountVerificationRequiredUpload')}.</Box>
              <Box>{t('app.waitVerificationCompletion')}</Box>
            </Box>
          </Box>
          <Box
            className={classes.buttonAddWrapper}
            onClick={() => setIsOpenUnitForm(true)}
          >
            <AddIcon className={classes.icons} />
            {t('app.addDocument')}
          </Box>
        </>
      ) : null}
      <ModalBox isOpen={isOpenUnitForm} setOpen={setIsOpenUnitForm}>
        <RadioButtonsGroup
          selectedValue={selectedValue}
          setSelectedValue={setSelectedValue}
        />
        {selectedValue === 'fiz' ? (
          <PasportForm
            passportData={user?.passportData}
            handleRefetch={handleRefetch}
          />
        ) : (
          <UrForm
            urData={user?.entityLegalData}
            handleRefetch={handleRefetch}
          />
        )}
      </ModalBox>
      <ModalBox isOpen={isOpenModal} setOpen={setIsOpenModal}>
        <img src={`data:mime/jpg;base64,${imageURL}`} alt='document' />
      </ModalBox>
    </Box>
  );
};

export default UserDataVerify;

const RadioButtonsGroup = ({ selectedValue, setSelectedValue }: any) => {
  const handleChange = (event: any) => {
    setSelectedValue(event.target.value);
  };
  const { t } = useTranslation();

  return (
    <div style={{ width: '100%' }}>
      <FormControlLabel
        value='top'
        control={
          <Radio
            checked={selectedValue === 'fiz'}
            onChange={handleChange}
            value='fiz'
            name='radio-button-demo'
            inputProps={{ 'aria-label': 'A' }}
          />
        }
        style={{ marginLeft: '0px', marginRight: '200px' }}
        label={t('app.individual')}
        labelPlacement='top'
      />
      <FormControlLabel
        value='top'
        control={
          <Radio
            checked={selectedValue === 'ur'}
            onChange={handleChange}
            value='ur'
            name='radio-button-demo'
            inputProps={{ 'aria-label': 'B' }}
          />
        }
        label={t('app.legalEntityIndividualEntrepreneur')}
        labelPlacement='top'
      />
    </div>
  );
};
