import React from 'react';
import { FastField, FieldProps } from 'formik';
import {
  FormControl,
  FormHelperText,
  Input,
  InputAdornment,
  InputLabel,
  Tooltip,
} from '@material-ui/core';
import { MAX_PARCEL_WEIGHT, TEXT } from '../../../utils/constants';
import { useTranslation } from 'react-i18next';

const FieldWeightGross: React.FC<{
  packageIndex?: number;
  handlerChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  fieldName?: string;
}> = ({ packageIndex, handlerChange, fieldName }) => {
  const { t } = useTranslation();

  return (
    <FastField
      name={
        fieldName
          ? fieldName
          : Number.isInteger(packageIndex)
          ? `packages[${packageIndex}].weightKg`
          : 'weight'
      }
    >
      {({ field: { value, ...field }, meta }: FieldProps) => (
        <FormControl error={!!(meta.touched && meta.error && value !== 0)}>
          <InputLabel
            shrink={false}
            htmlFor={`input-weight-package-${packageIndex}`}
          >
            <Tooltip title={t(TEXT.GROSS)}>
              <span>
                {Number.isInteger(packageIndex)
                  ? t('app.grossWeight')
                  : t('app.weight')}
              </span>
            </Tooltip>
          </InputLabel>
          <Input
            disableUnderline
            id={`input-weight-package-${packageIndex}`}
            {...field}
            inputProps={{
              type: 'number',
              step: 0.01,
              min: 0,
              max: MAX_PARCEL_WEIGHT,
            }}
            startAdornment={
              <InputAdornment position='end'>{t('app.kg')}</InputAdornment>
            }
            defaultValue={value}
            onChange={handlerChange}
            value={value}
          />
          {meta.touched && meta.error && value !== 0 && (
            <FormHelperText>{meta.error}</FormHelperText>
          )}
        </FormControl>
      )}
    </FastField>
  );
};

export default FieldWeightGross;
