import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  notificationMessage: {
    whiteSpace: 'pre-wrap',
  },
  messageTitle: {
    fontSize: '14px',
    lineHeight: '20px',
  },
}));
