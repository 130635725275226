import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from '@material-ui/core';
import React, { ChangeEvent, useState } from 'react';
import useStyles from './styles';
import { FastField, Field, FieldProps, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import CloseIcon from '@material-ui/icons/Close';
import InfoIcon from '@material-ui/icons/Info';
import { instructionOptions, typePackageOptions } from './options';
import { COLORS } from '../../../utils/constants';
import { CreateB2BOrderInput } from '../../../generated/graphql';

const B2bShipmentSendFormStep3 = ({
  isComment,
  setIsComment,
}: {
  isComment: boolean;
  setIsComment: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext<CreateB2BOrderInput>();

  const [labelName, setLabelName] = useState<string>('');

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      const uploadedLabelName = file.name.split('.')[0];
      setLabelName(uploadedLabelName);
      setFieldValue('technicalTaskFile', file);
    }
  };

  const hadleDeleteLabel = () => {
    setFieldValue('technicalTaskFile', 'null');
    setLabelName('');
  };

  const handleInstructionChange = (
    e: React.ChangeEvent<{
      label?: string;
      value: unknown;
    }>,
  ) => {
    setFieldValue('wrapWay', e.target.value);
  };

  const handleTypePackageChange = (value: string) => () => {
    setFieldValue('wrapType', value);
  };

  const isLabelUpdated = !!values.technicalTaskFile && !!labelName;

  return (
    <div>
      <Typography className={classes.contactFieldsTitle} variant='h3'>
        {t('app.typeOfPackaging')}
      </Typography>
      <Box className={classes.packageIconsWrapper}>
        {typePackageOptions.map((option) => (
          <Box
            key={option.value}
            className={classes.packageIconWrapper}
            onClick={handleTypePackageChange(option.value)}
            style={
              values.wrapType === option.value
                ? {
                    backgroundColor: COLORS.MEDIUM_GREY,
                  }
                : {}
            }
          >
            <img
              className={classes.packageIcon}
              src={option.imgSrc}
              alt={option.label}
            />
            <Typography>{t(option.label)}</Typography>
          </Box>
        ))}
      </Box>
      <FormControl variant='standard'>
        <InputLabel shrink={false} htmlFor='instruction'>
          {t('app.instructionForPackaging')}
        </InputLabel>
        <Select
          className={classes.selectContacts}
          id='instruction'
          value={values.wrapWay}
          onChange={handleInstructionChange}
        >
          {instructionOptions?.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {t(option.label)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControlLabel
        className={classes.labelRow}
        control={
          <Checkbox
            className={classes.radio}
            color='primary'
            checked={isComment}
            onChange={(e) =>
              setIsComment((prevState) => {
                if (prevState === true) setFieldValue('comment', '');
                return !prevState;
              })
            }
          />
        }
        label={
          <Box display={'flex'} alignItems={'center'}>
            <span className={classes.labelRowText}>
              {t('app.warehouseComment')}
            </span>
            <Tooltip
              style={{ padding: 0 }}
              title={t('app.leaveImportantMessageForWarehouse')}
            >
              <IconButton>
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </Box>
        }
      />
      {!!isComment && (
        <Box maxWidth={280} marginTop={'12px'}>
          <FastField name='comment'>
            {({ field: { value }, meta }: FieldProps) => {
              return (
                <FormControl error={!!(meta.touched && meta.error)}>
                  <Input
                    disableUnderline
                    id='comment'
                    defaultValue={value}
                    inputProps={{
                      type: 'text',
                      maxlength: '100',
                    }}
                    onChange={(e) => setFieldValue('comment', e.target.value)}
                  />
                  {meta.touched && meta.error && (
                    <FormHelperText>{meta.error}</FormHelperText>
                  )}
                </FormControl>
              );
            }}
          </FastField>
        </Box>
      )}

      <Field name={'technicalTaskFile'}>
        {({ field: { value, ...field }, meta }: FieldProps) => (
          <FormControl variant='standard'>
            <Box display={'flex'} alignItems={'center'} mt={3}>
              <label
                htmlFor='file-upload'
                style={{ marginRight: '5px', cursor: 'pointer' }}
              >
                <Box display='flex'>
                  <IconButton component='span' style={{ padding: 0 }}>
                    <AttachFileIcon />
                  </IconButton>
                  <Typography style={{ marginLeft: 5 }}>
                    {!isLabelUpdated ? t('app.attachTaskFile') : labelName}
                  </Typography>
                </Box>
              </label>
              {isLabelUpdated ? (
                <CloseIcon
                  onClick={hadleDeleteLabel}
                  className={classes.closeIcon}
                />
              ) : null}
              <input
                id='file-upload'
                type='file'
                style={{ display: 'none' }}
                accept='.doc],.pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,application/vnd.ms-excel.sheet.macroEnabled.12'
                onChange={handleFileChange}
              />
            </Box>
            {meta?.touched && !!meta?.error && (
              <FormHelperText style={{ color: COLORS.RED }}>
                {meta?.error}
              </FormHelperText>
            )}
          </FormControl>
        )}
      </Field>
    </div>
  );
};

export default B2bShipmentSendFormStep3;
