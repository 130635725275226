import React from 'react';
import { Box, Divider, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import FormContact from '../../components/FormContact/FormContact';
import useStyles from './styles';
import { QUERY_CURRENT_USER } from '../../GraphQL/queries/getCurrentUser';
import { ShowLoadingText } from '../../utils/helperComponents';
import { TRoutes } from '../../utils/helpers';
import {
  Contact,
  EditCurrentUserMutationVariables,
  GetCurrentUserQuery,
  GetCurrentUserQueryVariables,
  useEditCurrentUserMutation,
  useGetCurrentUserQuery,
} from '../../generated/graphql';
import { FormikHelpers } from 'formik';
import { useApolloClient } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function FormPersonalData() {
  const classes = useStyles();
  const client = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const { t } = useTranslation();

  const {
    loading,
    error,
    data: dataGetCurrentUserQuery,
  } = useGetCurrentUserQuery();
  const [editCurrentUserMutation] = useEditCurrentUserMutation();

  const handlerSubmit = (
    values: EditCurrentUserMutationVariables,
    { setSubmitting }: FormikHelpers<EditCurrentUserMutationVariables>,
  ) => {
    editCurrentUserMutation({
      variables: {
        name: values.name,
        company: values.company,
        //@ts-ignore
        countryId: values?.country.id,
        address: values.address,
        address2: values.address2,
        address3: values.address3,
        zipCode: values.zipCode,
        city: values.city,
        state: values.state,
        phone: values.phone,
        eoriCode: values.eoriCode,
      },
    })
      .then(() => {
        client
          .query<GetCurrentUserQuery, GetCurrentUserQueryVariables>({
            query: QUERY_CURRENT_USER,
            fetchPolicy: 'network-only',
          })
          .then(() => {
            enqueueSnackbar(t('app.dataSuccessfullyUpdated'), {
              variant: 'success',
            });
            history.push(TRoutes.PERSONAl_VERIFY);
          });
      })
      .catch((error) => enqueueSnackbar(error.message, { variant: 'error' }))
      .finally(() => setSubmitting(false));
  };

  if (loading) {
    return <ShowLoadingText name={t('app.users')} />;
  }

  if (error) {
    enqueueSnackbar(error.message, { variant: 'error' });
  }

  if (!dataGetCurrentUserQuery || !dataGetCurrentUserQuery.currentUser) {
    return <div>{t('app.unableToLoadUserData')}</div>;
  }

  return (
    <div className={classes.root}>
      <Typography className={classes.title} variant='h2' align='center'>
        {t('app.personalData2')}
      </Typography>

      <Divider className={classes.divider} />
      <Box className={classes.formWrapper}>
        <FormContact
          onSubmit={handlerSubmit}
          initialValues={
            {
              ...dataGetCurrentUserQuery.currentUser,
              innKppCode: '',
            } as Contact
          }
          isPersonal={true}
        />
      </Box>
    </div>
  );
}
