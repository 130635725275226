import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const styles = (theme: Theme) =>
  createStyles({
    //styles to cargo places
    root: {
      marginTop: 30,
      paddingTop: 30,
      borderTop: '1px solid #e0e0e0',

      '&:first-child': {
        marginTop: 0,
        paddingTop: 0,
        border: 'none',
      },
    },
    packageItem: {
      padding: '20px',
      borderRadius: '10px',
      border: '2px solid transparent',
      marginBottom: '32px',

      '&:hover': {
        backgroundColor: '#dcdcdc',
        border: '2px solid brown',
      },
    },
    inputLabel: {
      maxWidth: '280px',
      marginBottom: '30px',
    },
    tabs: {
      width: '882px',
    },
    inputLabelPackage: {
      maxWidth: '174px',
    },
    tooltipIconButton: {
      padding: 0,
    },
    boxModalButtons: {
      display: 'flex',
      justifyContent: 'flex-start',
      padding: '8px',
    },
    modalButton: {
      marginRight: '30px',
    },
    errorsMessage: {
      color: '#f44336',
    },
    priceWrapper: {
      display: 'flex',
      alignItems: 'flex-end',
      gap: '20px',
    },
  });

export default makeStyles(styles);
