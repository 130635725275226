import React, { useState } from 'react';
import {
  Box,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  Contact,
  CreateWarehouseShipmentOrderInput,
  GetDeliveryCountriesQuery,
  GetDeliveryCountriesQueryVariables,
  useUploadMarketplaceInvoiceMutation,
} from '../../../generated/graphql';
import { TFormikSetFieldValueDebounce } from '../../../interfaces';
import { VATKEY } from '../../../utils/constants';

import VatValue from '../../../components/Packages/VatValue/VatValue';
import VatKey from '../../../components/Packages/VatKey/VatKey';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { useFormikContext } from 'formik';
import useStyles from './styles';
import { useApolloClient } from '@apollo/client';
import { QUERY_DELIVERY_COUNTRIES } from '../../../GraphQL/queries/getDeliveryCountries';
import { CardWarning } from '../../../components/CardWarning/CardWarning';

const ShipmentFromWarehouseFormStepTaxInformation: React.FC<{
  setFieldValueDebounce: TFormikSetFieldValueDebounce;
}> = ({ setFieldValueDebounce }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const client = useApolloClient();
  const classes = useStyles();
  const { values, setFieldValue } =
    useFormikContext<CreateWarehouseShipmentOrderInput>();
  const [fileName, setFileName] = useState<string>('');

  const [
    uploadMarketPlaceInvoiceMutation,
    { loading: uploadMarketPlaceInvoiceLoading },
  ] = useUploadMarketplaceInvoiceMutation();

  const cachedCountries = client.readQuery<
    GetDeliveryCountriesQuery,
    GetDeliveryCountriesQueryVariables
  >({
    query: QUERY_DELIVERY_COUNTRIES,
  });

  const setHtmlUrlsFromString = (string: string) => {
    if (string) {
      return string.replace(/(((https?:\/\/)|(www\.))[^\s]+)/g, (url) => {
        return `<a href="${url}" target="_blank">${url}</a>`;
      });
    } else {
      return string;
    }
  };

  const handlerChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const name = e.target.name;
    const value = e.target.value;

    setFieldValue(name, value);
  };

  const selectMarketPlaceInvoice = () => {
    let fileInput = document.getElementById('inputUploadFile');
    if (!fileInput) {
      fileInput = document.createElement('input');
      fileInput.setAttribute('id', 'inputUploadFile');
      fileInput.setAttribute(
        'accept',
        '.pdf,.jpeg,.png, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,application/vnd.ms-excel.sheet.macroEnabled.12',
      );
      fileInput.style.visibility = 'hidden';
      document.body.append(fileInput);
      //@ts-ignore
      fileInput.addEventListener('change', uploadMarketPlaceInvoiceFile, false);
      //@ts-ignore
      fileInput.type = 'file';
    }
    fileInput.click();
  };

  const uploadMarketPlaceInvoiceFile = ({
    target: {
      //@ts-ignore
      validity,
      //@ts-ignore
      files: [file],
    },
  }) => {
    let dataArray = new FormData();
    const inputUploadFile = document.getElementById('inputUploadFile');
    //@ts-ignore
    if (inputUploadFile?.files?.length) {
      //@ts-ignore
      dataArray.append('file', inputUploadFile.files[0]);

      const uploadedFileName = file.name.split('.')[0];

      validity.valid &&
        uploadMarketPlaceInvoiceMutation({
          variables: {
            file: file,
          },
          onCompleted: (data) => {
            if (data) {
              setFieldValue(
                'marketplaceInvoiceToken',
                data.uploadMarketplaceInvoice,
              );
              setFileName(uploadedFileName);
              enqueueSnackbar(t('app.marketplaceInvoiceUploaded'), {
                variant: 'success',
              });
            }
          },
          onError: (error) => {
            if (error) {
            }
          },
        });
      inputUploadFile.remove();
    }
  };

  const hadleDeleteFile = () => {
    setFieldValue('marketplaceInvoiceToken', '');
    setFileName('');
  };

  const receiver = values.receiver as unknown as Contact;

  return (
    <>
      <Grid container spacing={5}>
        <Grid item xs={12} sm={6}>
          <VatKey
            fieldName='vatKey'
            types={VATKEY}
            setFieldValue={setFieldValueDebounce}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <VatValue
            handlerChange={handlerChange}
            fieldName='vatValue'
            label={values.vatKey || ''}
          />
        </Grid>
      </Grid>
      <Box display={'flex'} alignItems={'center'} mb={3} mt={3}>
        <IconButton
          style={{ padding: 0 }}
          onClick={selectMarketPlaceInvoice}
          disabled={uploadMarketPlaceInvoiceLoading}
        >
          <AttachFileIcon />
        </IconButton>
        <Typography style={{ marginRight: 5 }}>
          {!values.marketplaceInvoiceToken ? t('app.attachInvoice') : fileName}
        </Typography>
        {values.marketplaceInvoiceToken ? (
          <CloseIcon onClick={hadleDeleteFile} className={classes.closeIcon} />
        ) : null}
        <Tooltip
          style={{ padding: 0 }}
          title={t('app.attachOrderInvoiceRecommended')}
        >
          <IconButton>
            <InfoIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Box mt={7.125} mb={7.125}>
        <Divider />
      </Box>
      {receiver?.country &&
        cachedCountries?.deliveryCountries?.find(
          (c) => c && c.name === receiver?.country?.name,
        )?.customsInfo && (
          <CardWarning title={t('app.customsTaxesInfo')}>
            <div
              dangerouslySetInnerHTML={{
                __html: setHtmlUrlsFromString(
                  cachedCountries?.deliveryCountries
                    ?.find((c) => c && c.name === receiver?.country?.name)
                    ?.customsInfo?.replaceAll('\n', '</br>') || '',
                ) as string,
              }}
            />
          </CardWarning>
        )}
    </>
  );
};

export default ShipmentFromWarehouseFormStepTaxInformation;
