import React from 'react';
import {
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
} from '@material-ui/core';

import {
  B2BShipmentPoint,
  CreateB2BOrderInput,
} from '../../../generated/graphql';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import { Field, FieldProps, useFormikContext } from 'formik';

const B2bShipmentSendFormStep4 = ({
  shipmentPoints,
}: {
  shipmentPoints: B2BShipmentPoint[];
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext<CreateB2BOrderInput>();

  const handleWarehouseChange = (
    e: React.ChangeEvent<{
      name?: string;
      value: unknown;
    }>,
  ) => {
    setFieldValue('shipmentPointId', e.target.value);
  };

  return (
    <div>
      <Field name={'shipmentPointId'}>
        {({ field: { value, ...field }, meta }: FieldProps) => (
          <FormControl
            style={{ marginBottom: '24px' }}
            variant='standard'
            error={!!(meta.touched && meta.error)}
          >
            <Select
              className={classes.selectContacts}
              id='warehouse'
              value={values.shipmentPointId}
              onChange={handleWarehouseChange}
            >
              <MenuItem value={0} disabled style={{ display: 'none' }}>
                {t('app.chooseShipmetPoint')}
              </MenuItem>
              {shipmentPoints?.map((item) => (
                <MenuItem key={item.id} value={Number(item.id)}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
            {meta.touched && meta.error && (
              <FormHelperText>{t(meta.error)}</FormHelperText>
            )}
          </FormControl>
        )}
      </Field>
    </div>
  );
};

export default B2bShipmentSendFormStep4;
