import { Box } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import useStyles from './styles';
import { IFilters } from '../../utils/types';
import BoxComponent from '../../components/DashboardComponents/BoxComponent/BoxComponent';
import {
  getFirstRowOption,
  getSecondRowOption,
  getThirdRowOption,
} from './options';
import GraphicComponent from '../../components/DashboardComponents/ChartComponents/GraphicComponent/GraphicComponent';
import DoghuntComponent from '../../components/DashboardComponents/ChartComponents/DoghuntComponent/DoghuntComponent';
import {
  DashboardStatistic,
  OrderPerDey,
  OrderStatusesStatictic,
  useGetDashboardStatisticLazyQuery,
} from '../../generated/graphql';
import { useSnackbar } from 'notistack';
import RequestHandler from '../../components/RequestHandler/RequestHandler';
import FilterSelectDashboardDepartureDate from '../../components/_Fields/DashboardFilters/FilterSelectDashboardDepartureDate/FilterSelectDashboardDepartureDate';

const Dashboard = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [filter, setFilter] = useState<IFilters>({
    dateFilter: 2,
  });

  const [getDashboardStatisticLazy, { data, loading }] =
    useGetDashboardStatisticLazyQuery({
      onError: (error) => {
        if (error) {
          enqueueSnackbar(error.message, { variant: 'error' });
        }
      },
      fetchPolicy: 'network-only',
    });

  useEffect(() => {
    getDashboardStatisticLazy({
      variables: { dateFilter: Number(filter.dateFilter) },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  return (
    <RequestHandler loading={loading}>
      <>
        <FilterSelectDashboardDepartureDate
          value={filter.dateFilter}
          setFilter={setFilter}
        />
        <Box className={classes.pageWrapper}>
          <Box className={classes.boxesWrapper}>
            {getFirstRowOption(
              data?.getDashboardStatistic as DashboardStatistic,
            ).map(({ title, count, Icon, isCurrency, iconUrl }) => (
              <BoxComponent
                key={title}
                title={title}
                count={count}
                Icon={Icon}
                isCurrency={isCurrency}
                iconUrl={iconUrl}
              />
            ))}
          </Box>
          <Box className={classes.graphWrapper}>
            <GraphicComponent
              ordersPerDayList={
                data?.getDashboardStatistic?.ordersPerDayList as OrderPerDey[]
              }
            />
            <DoghuntComponent
              orderStatuses={
                data?.getDashboardStatistic
                  ?.orderStatuses as OrderStatusesStatictic[]
              }
            />
          </Box>
          <Box className={classes.boxesWrapper}>
            {getSecondRowOption(
              data?.getDashboardStatistic as DashboardStatistic,
            ).map(({ title, count, Icon, isCurrency }) => (
              <BoxComponent
                key={title}
                title={title}
                count={count}
                Icon={Icon}
                isCurrency={isCurrency}
              />
            ))}
          </Box>
          <Box className={classes.boxesWrapper}>
            {getThirdRowOption(
              data?.getDashboardStatistic as DashboardStatistic,
            ).map(({ title, count, Icon, isCurrency, iconUrl }) => (
              <BoxComponent
                key={title}
                title={title}
                count={count}
                Icon={Icon}
                isCurrency={isCurrency}
                iconUrl={iconUrl}
              />
            ))}
          </Box>
        </Box>
      </>
    </RequestHandler>
  );
};

export default Dashboard;
