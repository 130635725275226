import { gql } from '@apollo/client';

export const QUERY_DELIVERY_COUNTRIES = gql`
  query getDeliveryCountries {
    deliveryCountries {
      id
      order
      name
      daysToDelivery
      zoneId
      nameEng
      iso
      # phoneCode
      # phoneMin
      # phoneMax
      customsInfo
    }
  }
`;
