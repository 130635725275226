import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  pagination: {
    marginTop: '32px',
    fontWeight: 'bold',
    '& .MuiPagination-ul': {
      [theme.breakpoints.down('xs')]: {
        flexWrap: 'nowrap',
        justifyContent: 'center',
      },
    },
    '& .MuiButtonBase-root': {
      width: 44,
      height: 44,
      borderRadius: '50%',
      padding: 0,
      margin: 0,
      marginRight: 8,

      [theme.breakpoints.down('xs')]: {
        margin: 0,
        width: 35,
        height: 35,
      },
    },
    '& .MuiPaginationItem-ellipsis': {
      width: 12,
      height: 44,
      padding: 0,
      margin: 0,
      display: 'flex',
      alignItems: 'center',
      minWidth: 'auto',
      marginRight: 8,
    },
    '& .MuiPaginationItem-icon': {
      fontSize: '25px',
    },
  },
}));
