import React, { useState } from 'react';
import { Button, Input } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { useTranslation } from 'react-i18next';

const FilterInputSearch: React.FC<{
  setFilter: any;
}> = ({ setFilter }) => {
  const [search, setSearch] = useState('');

  const { t } = useTranslation();

  const handleChange = (e: any) => {
    setSearch(e.target.value);
  };
  const startSearch = (e: any) => {
    e.preventDefault();

    setFilter((state: any) => ({
      ...state,
      search: search,
      page: 1,
    }));
  };

  return (
    <form className='filtersSearch' onSubmit={startSearch}>
      <Input
        placeholder={t('app.enterText')}
        className='filtersSearchField'
        onChange={handleChange}
        value={search}
        disableUnderline
      />
      <Button
        className='filtersSearchSubmit'
        variant='contained'
        type='submit'
        aria-label='search'
      >
        <SearchIcon />
      </Button>
    </form>
  );
};

export default FilterInputSearch;
