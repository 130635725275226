import { gql } from '@apollo/client';

export const USER_PASPORT = gql`
  fragment FragmentUserPasport on UserPasport {
    id
    firstName
    lastName
    middleName
    birthDay
    seriesAndNumberOfPassport
    dateOfIssueOfTheDocument
    inn
    scanPath
    userId
    isDataVerified
  }
`;
