import React, { useState } from 'react';
import clsx from 'clsx';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Link as MuiLink,
  Tooltip,
  Typography,
} from '@material-ui/core';
import GoogleLogin from 'react-google-login';
import VkontakteLoginButton from '../../components/VkontakteLoginButton';
// @ts-ignore
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { Visibility, VisibilityOff, VpnKey } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { Field, Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { useSnackbar } from 'notistack';

import authFacebookMutation from '../../GraphQL/mutations/authFacebook';
import authGoogleMutation from '../../GraphQL/mutations/authGoogle';
import authVkontakteMutation from '../../GraphQL/mutations/authVkontakte';
import { QUERY_CURRENT_USER } from '../../GraphQL/queries/getCurrentUser';

import FacebookIcon from '../../assets/img/auth/facebook-icon.svg';
import GoogleIcon from '../../assets/img/auth/google-icon.svg';

import useStyles from './styles';

import { TRoutes } from '../../utils/helpers';
import { signIn as signInSchema } from '../../utils/validationSchemes';
import { useHistory } from 'react-router';
import { useApolloClient } from '@apollo/client';
import {
  GetCurrentUserQuery,
  GetCurrentUserQueryVariables,
  SignInMutationVariables,
  useSignInMutation,
} from '../../generated/graphql';
import { TSignInForm } from '../../interfaces';
import { ShowLoadingText } from '../../utils/helperComponents';
import { LOCAL_STORAGE_KEYS } from '../../utils/constants';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import { BoxCentered } from '../../components/BoxCentered/BoxCentered';
import { useTranslation } from 'react-i18next';

const SignIn: React.FC = () => {
  const [isShowPassword, toggleShowPassword] = useState(false);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const client = useApolloClient();
  const [signInMutation, { loading: loadingSignInMutation }] =
    useSignInMutation();
  const [isLoadingSocial, setIsLoadingSocial] = useState(false);

  const { t } = useTranslation();

  const handlerSubmitThen = (token: string) => {
    if (token) {
      localStorage.setItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN, token);

      client
        .query<GetCurrentUserQuery, GetCurrentUserQueryVariables>({
          query: QUERY_CURRENT_USER,
          fetchPolicy: 'network-only',
        })
        .then(() => history.push(TRoutes.MAIN));
    }
  };

  const handlerSubmit = (
    values: SignInMutationVariables,
    { setSubmitting }: FormikHelpers<TSignInForm>,
  ) => {
    signInMutation({
      variables: {
        email: values.email,
        password: values.password,
        rememberMe: values.rememberMe,
      },
    })
      .then((response) => {
        if (
          response.data &&
          response.data.signIn &&
          response.data.signIn.token
        ) {
          handlerSubmitThen(response.data.signIn.token);
        } else {
          enqueueSnackbar(t('app.unableToRetrieveData'), {
            variant: 'error',
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.message, {
          variant: 'error',
        });
      })
      .finally(() => setSubmitting(false));
  };

  const responseSocial =
    /* prettier-ignore */
    // @ts-ignore
    ({ mutation, nameMutation, getToken }) =>
      /* prettier-ignore */
      // @ts-ignore
      (response) => {
        const token = getToken(response);
        if (token) {
          setIsLoadingSocial(true);
          client
            .mutate({
              mutation,
              variables: {
                accessToken: token,
              },
            })
            .then((response) =>
              handlerSubmitThen(response.data[nameMutation].token),
            )
            .catch((error) => {
              enqueueSnackbar(error.message, { variant: 'error' });
            })
            .catch((reason) => {
              console.error(reason);
              !!reason?.message &&
              enqueueSnackbar(reason.message, { variant: 'error' });
            })
            .finally(() => {
              setIsLoadingSocial(false);
            });
        } else {
          const message =
            response.details || response.error || t('app.tryAgainError');
          enqueueSnackbar(message, { variant: 'error' });
        }
      };

  return (
    <div>
      <Typography className={classes.authTitle} variant='h2' align='center'>
        {t('app.authorization')}
      </Typography>

      <BoxCentered className={classes.noAccountText}>
        {t('app.noAccount')}&nbsp;
        <Tooltip title={'app.createNewAccount'}>
          <MuiLink
            to={TRoutes.AUTH_SIGN_UP}
            underline='always'
            component={Link}
            variant='body2'
          >
            {t('app.signUp')}
          </MuiLink>
        </Tooltip>
      </BoxCentered>

      <div className={classes.social}>
        <BoxCentered className={classes.socialLabel}>
          {t('app.viaSocialNetworks')}
        </BoxCentered>
        <div className={classes.socialButtons}>
          <VkontakteLoginButton
            callback={responseSocial({
              mutation: authVkontakteMutation,
              nameMutation: 'authVkontakte',
              getToken: (token: string) => token,
            })}
          />
          <FacebookLogin
            appId={process.env.REACT_APP_FACEBOOK_APP_ID as string}
            autoLoad={false}
            fields='name,email'
            render={(renderProps: {
              onClick:
                | ((
                    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
                  ) => void)
                | undefined;
            }) => (
              <Button
                className={classes.socialButton}
                onClick={renderProps.onClick}
                variant='outlined'
                endIcon={<img src={FacebookIcon} alt='facebook icon' />}
              >
                Facebook
              </Button>
            )}
            callback={responseSocial({
              mutation: authFacebookMutation,
              nameMutation: 'authFacebook',
              getToken: (response: { accessToken: string }) =>
                response.accessToken,
            })}
          />
          <GoogleLogin
            clientId={process.env.REACT_APP_GOOGLE_APP_ID as string}
            onSuccess={responseSocial({
              mutation: authGoogleMutation,
              nameMutation: 'authGoogle',
              getToken: (response: { accessToken: string }) =>
                response.accessToken,
            })}
            onFailure={responseSocial({
              mutation: authGoogleMutation,
              nameMutation: 'authGoogle',
              getToken: (response: { accessToken: string }) =>
                response.accessToken,
            })}
            render={(renderProps) => (
              <Button
                className={clsx(
                  classes.socialButton,
                  classes.socialButtonFullWidth,
                )}
                variant='outlined'
                onClick={renderProps.onClick}
                endIcon={<img src={GoogleIcon} alt='google icon' />}
              >
                Google
              </Button>
            )}
            cookiePolicy='single_host_origin'
          />
        </div>
        {isLoadingSocial && (
          <Box
            mt={1}
            display='flex'
            alignItems='center'
            justifyContent='center'
          >
            <ShowLoadingText />
          </Box>
        )}
      </div>
      <Divider className={classes.divider} />
      <Formik
        initialValues={
          { email: '', password: '', rememberMe: false } as TSignInForm
        }
        validationSchema={signInSchema}
        onSubmit={handlerSubmit}
      >
        {(props: FormikProps<TSignInForm>) => {
          const { isSubmitting, getFieldProps, getFieldMeta } = props;

          return (
            <Form noValidate>
              <Field name='email'>
                {() => (
                  <FormControl
                    error={
                      !!(
                        getFieldMeta('getFieldMeta').touched &&
                        getFieldMeta('getFieldMeta').error
                      )
                    }
                    className={classes.formControl}
                  >
                    <InputLabel shrink={false} htmlFor='input-email'>
                      {t('app.email')}
                    </InputLabel>
                    <Input
                      disableUnderline
                      fullWidth
                      required
                      id='input-email'
                      {...getFieldProps('email')}
                      startAdornment={
                        <InputAdornment position='end'>
                          <AlternateEmailIcon />
                        </InputAdornment>
                      }
                    />
                    {getFieldMeta('getFieldMeta').touched &&
                      getFieldMeta('getFieldMeta').error && (
                        <FormHelperText>
                          {getFieldMeta('getFieldMeta').error}
                        </FormHelperText>
                      )}
                  </FormControl>
                )}
              </Field>

              <Field name='password'>
                {() => (
                  <FormControl
                    error={
                      !!(
                        getFieldMeta('password').touched &&
                        getFieldMeta('password').error
                      )
                    }
                    className={classes.formControl}
                  >
                    <InputLabel shrink={false} htmlFor='input-password'>
                      {t('app.password')}
                    </InputLabel>
                    <Input
                      disableUnderline
                      fullWidth
                      id='input-password'
                      type={isShowPassword ? 'text' : 'password'}
                      {...getFieldProps('password')}
                      endAdornment={
                        <InputAdornment
                          position='end'
                          onClick={() => toggleShowPassword(!isShowPassword)}
                        >
                          <IconButton aria-label='toggle password visibility'>
                            {isShowPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {getFieldMeta('password').touched &&
                      getFieldMeta('password').error && (
                        <FormHelperText>
                          {getFieldMeta('password').error}
                        </FormHelperText>
                      )}
                  </FormControl>
                )}
              </Field>

              <Field name='remember'>
                {() => (
                  <div className={classes.rememberMe}>
                    <FormControlLabel
                      label={t('app.rememberMe')}
                      control={
                        <Checkbox
                          color={'default'}
                          {...getFieldProps('remember')}
                        />
                      }
                    />
                  </div>
                )}
              </Field>

              <BoxCentered>
                <Button
                  className={classes.signButton}
                  disabled={isSubmitting}
                  type='submit'
                  variant='contained'
                  startIcon={
                    loadingSignInMutation ? (
                      <CircularProgress size={22} />
                    ) : (
                      <VpnKey />
                    )
                  }
                >
                  {t('app.logIn')}
                </Button>
              </BoxCentered>

              <Divider className={classes.divider} />
              <p>
                {t('app.forgotPassword')}
                <MuiLink
                  component={Link}
                  variant='body2'
                  to={TRoutes.AUTH_RESET}
                >
                  {t('app.recoverPassword')}
                </MuiLink>
              </p>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default SignIn;
