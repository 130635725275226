import { gql } from '@apollo/client';
import { DELIVERY_COUNTRY } from '../fragments/deliveryCountryFragment';
import { USER_PASPORT } from '../fragments/userPasportFragment';
import { ENTITY_LEGAL_DATA } from '../fragments/entityLegalData';

export const QUERY_CURRENT_USER = gql`
  ${DELIVERY_COUNTRY}
  ${USER_PASPORT}
  ${ENTITY_LEGAL_DATA}
  query getCurrentUser {
    currentUser {
      id
      email
      name
      company
      country {
        ...FragmentDeliveryCountry
      }
      address
      address2
      address3
      zipCode
      city
      state
      phone
      eoriCode
      status
      createdAt
      updatedAt
      balance
      passportData {
        ...FragmentUserPasport
      }
      entityLegalData {
        ...FragmentEntityLegalData
      }
      role
      fullAccess
    }
  }
`;
