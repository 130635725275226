import React from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  Tooltip,
  MenuItem,
  TextField,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { RenderCountryFlagImage } from '../../utils/helperComponents';
import { Field, FieldProps } from 'formik';
import { BoxCentered } from '../BoxCentered/BoxCentered';
import { useAppFormContext } from '../../context/FormContext';
import { DeliveryCountry } from '../../generated/graphql';
import { useTranslation } from 'react-i18next';

export const SearchSelect = ({
  nameShape,
  getFieldName,
  isPersonal,
  isChangePhoneAfterCountryChange,
  setIsChangePhoneAfterCountryChange,
  outerIsLoadingValidateAddress,
  setFieldValue,
  setInnerSelectedCountry,
}: any) => {
  const { allCountries } = useAppFormContext();

  const { t } = useTranslation();

  return (
    <Field name={getFieldName('country')}>
      {({ field: { value, ...field }, meta }: FieldProps) => (
        <FormControl error={!!(meta.touched && meta.error)}>
          <InputLabel shrink={false} htmlFor={`${nameShape}-country-input`}>
            {t('app.country')}*
          </InputLabel>
          <Tooltip title={t('app.checkboxDescription')}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isChangePhoneAfterCountryChange}
                  onClick={() =>
                    setIsChangePhoneAfterCountryChange(
                      (prevState: any) => !prevState,
                    )
                  }
                />
              }
              label={t('app.changeCountryPhone')}
            />
          </Tooltip>
          <Autocomplete
            id={`${nameShape}-country-input`}
            disabled={outerIsLoadingValidateAddress}
            disableClearable
            options={allCountries}
            getOptionLabel={(option) =>
              `${option.name}${option?.nameEng ? '/' + option?.nameEng : ''}`
            }
            //@ts-ignore
            defaultValue={
              value?.name
                ? { name: value?.name as any, nameEng: value?.nameEng }
                : { name: t('app.chooseCountry') }
            }
            renderOption={(country: DeliveryCountry) => {
              return (
                <MenuItem key={country?.name} value={country?.name}>
                  <BoxCentered justifyContent='flex-start'>
                    {country?.iso && (
                      <RenderCountryFlagImage
                        countryISO={country?.iso}
                        countryName={country?.name}
                      />
                    )}
                    {country?.name}
                    {country?.nameEng ? '/' : ''}
                    {country?.nameEng ? country?.nameEng : ''}
                  </BoxCentered>
                </MenuItem>
              );
            }}
            onChange={(event, newValue) => {
              setFieldValue(getFieldName('country'), newValue);
              setInnerSelectedCountry(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                disabled={outerIsLoadingValidateAddress}
                id={`${nameShape}-country-input`}
                {...field}
                value={value}
              />
            )}
          />

          {meta.touched && meta.error && (
            <FormHelperText>{meta.error}</FormHelperText>
          )}
        </FormControl>
      )}
    </Field>
  );
};
