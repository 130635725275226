import { Field, FieldProps } from 'formik';
import {
  FormControl,
  FormHelperText,
  Input,
  InputAdornment,
  InputLabel,
} from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

const FieldPrice: React.FC<{
  fieldName?: string;
  unitIndex?: number;
  currency?: string;
  handlerChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  isMarketPlace?: boolean;
}> = ({ fieldName, unitIndex, currency, handlerChange, isMarketPlace }) => {
  const { t } = useTranslation();

  return (
    <Field
      name={
        fieldName
          ? !isMarketPlace
            ? fieldName + '.price'
            : fieldName + '.priceAmount'
          : 'declaredValue'
      }
    >
      {({ field: { value, ...field }, meta }: FieldProps) => (
        <FormControl error={!!(meta.touched && meta.error && value !== 0)}>
          <InputLabel
            htmlFor={`input-price-product-${unitIndex}`}
            style={{ whiteSpace: 'nowrap' }}
          >
            {t('app.itemPrice')}
          </InputLabel>
          <Input
            disableUnderline
            id={`input-price-product-${unitIndex}`}
            {...field}
            startAdornment={
              <InputAdornment position='start' style={{ paddingLeft: 10 }}>
                {currency?.toUpperCase()}
              </InputAdornment>
            }
            inputProps={{
              type: 'number',
              min: 0,
              step: 0.1,
            }}
            value={value}
            defaultValue={value}
            onChange={handlerChange}
          />
          {meta.touched && meta.error && value !== 0 && (
            <FormHelperText>{meta.error}</FormHelperText>
          )}
        </FormControl>
      )}
    </Field>
  );
};

export default FieldPrice;
