import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../../../../utils/constants';

export const styles = makeStyles((theme) => ({
  doghuntWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 'calc(33.3% - 25.3px)',
    height: '420px',
    backgroundColor: COLORS.WHITE,
    borderRadius: '34px',
    padding: '32px',
    '& canvas': {
      height: '310px !important',
      width: '310px !important',
    },
  },
  doghuntTitle: {
    fontFamily: '"Source Sans 3", sans-serif',
    fontOpticalSizing: 'auto',
    fontWeight: 400,
    fontStyle: 'normal',
    fontSize: 24,
    marginBottom: '10px',
  },
}));

export default styles;
