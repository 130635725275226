import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from '@material-ui/core';
import React, { useState } from 'react';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import { TFormikSetFieldValue } from '../../interfaces';
import { deliveryOptions } from './options';
import { DatePicker } from '@material-ui/pickers';
import { FastField, FieldProps, useFormikContext } from 'formik';
import InfoIcon from '@material-ui/icons/Info';
import moment from 'moment';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { useParams } from 'react-router';

interface IDeliveryOptionsComponent {
  isForwarding?: boolean;
  carrierCode: string;
  trackNumber: string;
  setFieldValue: TFormikSetFieldValue;
  fieldCarrierName?: string;
  expectedArrivalDate?: string;
  comment?: string;
  isFullfilment?: boolean;
}

interface IPlaceholder {
  children: string;
}

const DeliveryOptionsComponent = ({
  isForwarding,
  carrierCode,
  trackNumber,
  expectedArrivalDate,
  comment,
  fieldCarrierName = 'carrierCode',
  isFullfilment = false,
}: IDeliveryOptionsComponent) => {
  const { id } = useParams<{ id: string }>();
  const classes = useStyles();
  const { t } = useTranslation();
  const { setFieldValue } = useFormikContext();
  const [isComment, setIsComment] = useState(!!comment);

  const Placeholder = ({ children }: IPlaceholder) => (
    <div className={classes.placeholder}>{children}</div>
  );

  return (
    <Box mb={5} className={classes.infoWrapper}>
      {!isForwarding && !isFullfilment ? (
        <>
          <Box>
            <InputLabel shrink={false} htmlFor='input-identifiers-type'>
              {t('app.expectedDateArrival')}
            </InputLabel>
            <DatePicker
              className={classes.datePicker}
              id='expectef_arrival_date'
              value={
                expectedArrivalDate
                  ? new Date(Number(expectedArrivalDate))
                  : new Date()
              }
              onChange={(date) => {
                date && setFieldValue('expectedArrivalDate', date.toDate());
              }}
              format='DD/MM/yyyy'
              minDate={moment().toDate()}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end' className={classes.adornment}>
                    <CalendarTodayIcon className={classes.calendarIcon} />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box mb={2.5}>
            <FormControlLabel
              className={classes.labelRow}
              control={
                <Checkbox
                  className={classes.radio}
                  color='primary'
                  checked={isComment}
                  onChange={(e) =>
                    setIsComment((prevState) => {
                      if (prevState === true) setFieldValue('comment', '');
                      return !prevState;
                    })
                  }
                />
              }
              label={
                <Box display={'flex'} alignItems={'center'}>
                  <span className={classes.labelRowText}>
                    {t('app.warehouseComment')}
                  </span>
                  <Tooltip
                    style={{ padding: 0 }}
                    title={t('app.leaveImportantMessageForWarehouse')}
                  >
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              }
            />
            {!!isComment && (
              <Box maxWidth={280} style={{ marginTop: '20px' }}>
                <FastField name='comment'>
                  {({ field: { value }, meta }: FieldProps) => {
                    return (
                      <FormControl error={!!(meta.touched && meta.error)}>
                        <Input
                          disableUnderline
                          id='comment'
                          defaultValue={value}
                          inputProps={{
                            type: 'text',
                            maxlength: '100',
                          }}
                          onChange={(e) =>
                            setFieldValue('comment', e.target.value)
                          }
                        />
                        {meta.touched && meta.error && (
                          <FormHelperText>{meta.error}</FormHelperText>
                        )}
                      </FormControl>
                    );
                  }}
                </FastField>
              </Box>
            )}
          </Box>
        </>
      ) : null}

      <Box mb={2.5} display={'flex'} flexDirection={'column'}>
        <InputLabel shrink={false} htmlFor='input-identifiers-type'>
          {t('app.deliveryMethod')}
        </InputLabel>
        <Box display={'flex'}>
          <Select
            renderValue={
              carrierCode
                ? undefined
                : () => <Placeholder>{t('app.selectCarrier')}</Placeholder>
            }
            onChange={(e) => setFieldValue(fieldCarrierName, e.target.value)}
            value={carrierCode}
            disableUnderline
            displayEmpty
            disabled={!!id}
          >
            {deliveryOptions.map(({ value, label, src }) => {
              if (!value) {
                return (
                  <MenuItem value='' className={classes.placeholder} disabled />
                );
              }
              return (
                <MenuItem
                  value={value}
                  className={classes.carrierCodeSelectedItem}
                >
                  <img
                    className={classes.carrierCodeIcon}
                    src={src}
                    alt={value}
                  />
                  {t(label as string)}
                </MenuItem>
              );
            })}
          </Select>
          <Box ml={2.5}>
            <Input
              value={trackNumber}
              disableUnderline
              placeholder={t('app.enterTrackingNumber3')}
              id={'input-track-number'}
              onChange={(
                e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
              ) => {
                e.persist();
                setFieldValue('trackNumber', e.target.value);
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DeliveryOptionsComponent;
