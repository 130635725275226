import SearchIcon from '@material-ui/icons/Search';
import CancelIcon from '@material-ui/icons/Cancel';
import { TRoutes } from '../../../utils/helpers';
import {
  B2BOrderListItem,
  WarehouseB2BStatus,
} from '../../../generated/graphql';
import { colorsEnum } from '../../../utils/constants';

export const getActionOptions = (
  order: B2BOrderListItem,
  openModalForCancelHandler: () => void,
) => {
  return [
    {
      link: `${TRoutes.B2B_ORDERS_DETAILS_WITHOUT_ID}${order?.id?.toString()}`,
      Icon: SearchIcon,
      title: 'app.view',
      color: colorsEnum.GREEN,
      tooltipText: 'app.clickToOpenViewShipments',
    },
    {
      link: `#`,
      Icon: CancelIcon,
      title: 'app.delete',
      onClick: openModalForCancelHandler,
      disabled: order?.warehouseStatus !== WarehouseB2BStatus.Created,
      color: colorsEnum.RED,
      tooltipText: 'app.clickToDeleteOrder',
    },
  ];
};
