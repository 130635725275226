import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  horizontalLine: {
    margin: '10px 0',
  },
  tableLeftPart: {
    width: '33%',
    textAlign: 'left',
    padding: 7,
  },
  tableCenterPart: {
    width: '33%',
    textAlign: 'center',
    padding: 7,
  },
  tableRightPart: {
    width: '33%',
    textAlign: 'right',
    padding: 7,
  },
  marketplaceSmallImg: {
    width: 60,
    height: 'auto',
  },
  disableMarketplaceBtn: {
    backgroundColor: '#e61610',
    color: '#ffffff',
  },
  modalFormTile: {
    margin: '15px 0',
  },
  modalFormWrap: {
    padding: '20px 0',
  },
  formControl: {
    marginBottom: 24,
  },
  modalBtn: {
    margin: '0 5px',
  },
  disableMarketplaceImg: {
    opacity: 0.25,
  },
  marketplaceImg: {
    cursor: 'pointer',
  },
  linearProgress: {
    height: 10,
    width: 190,
  },
  errorMsgWrap: {
    display: 'block',
    textAlign: 'center',
  },
  errorMsg: {
    color: '#f44336',
  },
  modalFormTileEtsy: {
    margin: '15px 0',
    fontSize: '18px',
    fontWeight: 400,
  },
}));
