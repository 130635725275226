import React, { useEffect, useState } from 'react';
import {
  Button,
  InputLabel,
  Input,
  Select,
  MenuItem,
  Box,
  FormHelperText,
  CircularProgress,
} from '@material-ui/core';
import ModalBox from '../ModalBox/ModalBox';
import { SnackbarMessage, useSnackbar } from 'notistack';
import SaveIcon from '@material-ui/icons/Save';
import useStyles from './styles';
import { checkAllowedCharacters } from '../../utils/helpers';
import ups from '../../assets/img/carriers/ups.svg';
import dhl from '../../assets/img/carriers/dhl.svg';
import usps from '../../assets/img/carriers/usps.png';
import fedex from '../../assets/img/carriers/fedex.svg';
import tnt from '../../assets/img/carriers/TNT.png';
import boxberry from '../../assets/img/carriers/boxberry.png';
import dpd from '../../assets/img/carriers/dpd.png';
import postRussia from '../../assets/img/carriers/post_russia.png';
import apcLogistics from '../../assets/img/carriers/apc_logistics.png';
import CDEK from '../../assets/img/carriers/CDEK.png';
import amazon from '../../assets/img/carriers/amazon.png';
import other from '../../assets/img/carriers/other.png';
import {
  useAddTrackNumberMutation,
  WarehouseShipment,
} from '../../generated/graphql';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';

const FormEditTrackNumber: React.FC<{
  setWarehouseShipmentList: React.Dispatch<
    React.SetStateAction<WarehouseShipment[]>
  >;
  isOpenEditTrackNumber: boolean;
  setIsOpenEditTrackNumber: any;
  currentChoiceWarehouseShipment: any;
  refetchWarehouseShipmentsList: any;
}> = ({
        setWarehouseShipmentList,
        isOpenEditTrackNumber,
        setIsOpenEditTrackNumber,
        currentChoiceWarehouseShipment,
        refetchWarehouseShipmentsList,
      }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoadingAction, setLoadingAction] = useState(false);
  const [trackNumberValue, setTrackNumberValue] = useState('');
  const [carrierCode, setCarrierCode] = useState('');
  const [errorTrackNumberValue, setErrorTrackNumberValue] = useState(false);
  const [warehouseShipmentId, setWarehouseShipmentId] = useState('');
  const [addTrackNumberMutation] = useAddTrackNumberMutation();

  const { t } = useTranslation();

  useEffect(() => {
    if (currentChoiceWarehouseShipment?.trackNumber) {
      setTrackNumberValue(currentChoiceWarehouseShipment.trackNumber);
    }
    if (
      currentChoiceWarehouseShipment?.carrierCode ||
      currentChoiceWarehouseShipment?.shipmentServiceName
    ) {
      setCarrierCode(
        currentChoiceWarehouseShipment.carrierCode ||
        currentChoiceWarehouseShipment?.shipmentServiceName,
      );
    }
    if (currentChoiceWarehouseShipment?.id) {
      setWarehouseShipmentId(currentChoiceWarehouseShipment.id);
    }

    return () => {
      setTrackNumberValue('');
      setCarrierCode('');
      setWarehouseShipmentId('');
    };
  }, [currentChoiceWarehouseShipment]);

  const [variables, message] = getVariables(
    trackNumberValue,
    carrierCode,
    warehouseShipmentId,
  );

  const handlerEdit = () => {
    setLoadingAction(true);
    addTrackNumberMutation({
      //@ts-ignore
      variables,
    })
      .then((response) => {
        enqueueSnackbar(message as unknown as SnackbarMessage, {
          variant: 'success',
        });
        setWarehouseShipmentList((prevState) => {
          //@ts-ignore
          return prevState.map((parcel) =>
            //@ts-ignore
            parcel.id === currentChoiceWarehouseShipment?.id
              ? //@ts-ignore
              response.data.addTrackNumberMutation
              : parcel,
          );
        });
        setIsOpenEditTrackNumber(false);
        setLoadingAction(false);
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: 'error' });
        setLoadingAction(false);
      })
      .finally(() => refetchWarehouseShipmentsList());
  };
  //@ts-ignore
  const Placeholder = ({ children }) => (
    <div className={classes.placeholder}>{children}</div>
  );

  const onClose = (isClose: any) => {
    setIsOpenEditTrackNumber(isClose);
    setCarrierCode('');
    setTrackNumberValue('');
  };

  return (
    <ModalBox isOpen={isOpenEditTrackNumber} setOpen={onClose}>
      <InputLabel shrink={false} htmlFor={'input-actual-weight'}>
        {t('app.enterTrackingNumber', {
          value: warehouseShipmentId ? warehouseShipmentId : '',
        })}
      </InputLabel>
      <Box mb={5} className={classes.infoWrapper}>
        <Box mb={2.5}>
          <Input
            value={trackNumberValue}
            disableUnderline
            id={'input-actual-weight'}
            onChange={(
              e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
            ) => {
              e.persist();
              setTrackNumberValue((prevState) => {
                return checkAllowedCharacters(e?.target?.value)
                  ? e.target.value
                  : prevState;
              });
              setErrorTrackNumberValue(false);
            }}
          />
          {errorTrackNumberValue && (
            <FormHelperText className={classes.errorMsg}>
              {t('app.fieldRequired')}
            </FormHelperText>
          )}
        </Box>

        <Box mb={2.5}>
          <Select
            renderValue={
              carrierCode
                ? undefined
                : () => <Placeholder>{t('app.selectCarrier')}</Placeholder>
            }
            //@ts-ignore
            onChange={(e) => setCarrierCode(e.target.value)}
            value={carrierCode}
            disableUnderline
            displayEmpty
          >
            <MenuItem value='' className={classes.placeholder} disabled />

            <MenuItem
              value='amazon logistics'
              className={classes.carrierCodeSelectedItem}
            >
              <img
                className={classes.carrierCodeIcon}
                src={amazon}
                alt={'amazon'}
              />{' '}
              Amazon logistics
            </MenuItem>
            <MenuItem value='ups' className={classes.carrierCodeSelectedItem}>
              <img className={classes.carrierCodeIcon} src={ups} alt={'ups'} />{' '}
              UPS
            </MenuItem>
            <MenuItem value='dhl' className={classes.carrierCodeSelectedItem}>
              <img className={classes.carrierCodeIcon} src={dhl} alt={'dhl'} />{' '}
              DHL
            </MenuItem>
            <MenuItem value='usps' className={classes.carrierCodeSelectedItem}>
              <img
                className={classes.carrierCodeIcon}
                src={usps}
                alt={'usps'}
              />{' '}
              USPS
            </MenuItem>
            <MenuItem value='fedex' className={classes.carrierCodeSelectedItem}>
              <img
                className={classes.carrierCodeIcon}
                src={fedex}
                alt={'fedex'}
              />{' '}
              FEDEX
            </MenuItem>
            <MenuItem value='tnt' className={classes.carrierCodeSelectedItem}>
              <img className={classes.carrierCodeIcon} src={tnt} alt={'tnt'} />{' '}
              TNT
            </MenuItem>
            <MenuItem
              value='post_russia'
              className={classes.carrierCodeSelectedItem}
            >
              <img
                className={classes.carrierCodeIcon}
                src={postRussia}
                alt={'boxberry'}
              />{' '}
              {t('app.russianPost')}
            </MenuItem>
            <MenuItem value='cdek' className={classes.carrierCodeSelectedItem}>
              <img
                className={classes.carrierCodeIcon}
                src={CDEK}
                alt={'CDEK'}
              />{' '}
              CDEK
            </MenuItem>
            <MenuItem value='dpd' className={classes.carrierCodeSelectedItem}>
              <img className={classes.carrierCodeIcon} src={dpd} alt={'dpd'} />{' '}
              DPD
            </MenuItem>
            <MenuItem value='apc' className={classes.carrierCodeSelectedItem}>
              <img
                className={classes.carrierCodeIcon}
                src={apcLogistics}
                alt={'apcLogistics'}
              />{' '}
              APC Postal Logistics
            </MenuItem>
            <MenuItem
              value='boxberry'
              className={classes.carrierCodeSelectedItem}
            >
              <img
                className={classes.carrierCodeIcon}
                src={boxberry}
                alt={'boxberry'}
              />{' '}
              BOXBERRY
            </MenuItem>
            <MenuItem value='other' className={classes.carrierCodeSelectedItem}>
              <img
                className={classes.carrierCodeIcon}
                src={other}
                alt={'other'}
              />{' '}
              {t('app.other')}
            </MenuItem>
          </Select>
        </Box>
      </Box>
      <div className={classes.boxModalButtons}>
        <Button
          className={classes.closeButton}
          variant='contained'
          onClick={() => setIsOpenEditTrackNumber(false)}
          color='secondary'
        >
          {t('app.close')}
        </Button>
        <Button
          variant='contained'
          onClick={handlerEdit}
          disabled={
            isLoadingAction ||
            !!errorTrackNumberValue ||
            !trackNumberValue ||
            trackNumberValue.length < 4 ||
            !carrierCode
          }
        >
          {isLoadingAction ? <CircularProgress size={22} /> : <SaveIcon />}
          {t('app.save')}
        </Button>
      </div>
    </ModalBox>
  );
};

const getVariables = (trackNumber: any, carrierCode: any, id: any) => {
  return [
    { shipmentId: id, trackNumber, carrierCode },
    t('app.trackingNumberCreated'),
  ];
};

export default FormEditTrackNumber;
