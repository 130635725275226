import { FastField, FieldProps } from 'formik';
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import LaunchIcon from '@material-ui/icons/Launch';
import React from 'react';
import styles from './styles';
import { useTranslation } from 'react-i18next';

const FieldCodeTNVED: React.FC<{
  fieldName?: string;
  unitIndex?: number;
  handlerChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  isDisabled?: boolean;
}> = ({ fieldName, unitIndex, handlerChange, isDisabled }) => {
  const classes = styles();
  const theme = useTheme();
  const hidden = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation();

  return (
    <>
      <FastField name={fieldName ? fieldName + '.code' : 'code'}>
        {({ field: { value, ...field }, meta }: FieldProps) => (
          <FormControl error={!!(meta.touched && meta.error)}>
            <Grid container justify='space-between'>
              <InputLabel
                style={{ fontSize: '13px', whiteSpace: 'nowrap' }}
                htmlFor={`input-code-product-${unitIndex}`}
              >
                {t('app.hsCode')}
              </InputLabel>
              {hidden ? null : (
                <Tooltip title={t('app.findHsCode')}>
                  <a
                    href='https://tnved.info/search'
                    target='_blank'
                    rel='noopener noreferrer'
                    className={classes.tooltipIconButton}
                  >
                    <LaunchIcon />
                  </a>
                </Tooltip>
              )}
              {/* @ts-ignore */}
            </Grid>
            <Input
              disabled={isDisabled}
              disableUnderline
              id={`input-code-product-${unitIndex}`}
              {...field}
              inputProps={{
                type: 'number',
                min: 0,
                max: Number.MAX_SAFE_INTEGER,
              }}
              value={value}
              defaultValue={value}
              onChange={handlerChange}
            />
            <Box visibility={meta.touched && meta.error ? 'visible' : 'hidden'}>
              <FormHelperText>
                {meta.error}
                <span style={{ visibility: 'hidden' }}>Q</span>
              </FormHelperText>
            </Box>
          </FormControl>
        )}
      </FastField>
    </>
  );
};

export default FieldCodeTNVED;
