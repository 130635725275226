import { FormControl, MenuItem, Select } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useStyles from '../styles';
import { DELIVERY_STATUS_BY_ID } from '../../../utils/constants';
import {
  GetStatusesListQuery,
  GetStatusesListQueryVariables,
} from '../../../generated/graphql';
import getStatusesList from '../../../GraphQL/queries/getStatusesList';
import { useApolloClient } from '@apollo/client';
import { IFilters } from '../../../utils/types';

interface IFilterSelectDeliveryStatus {
  value?: string | number;
  setFilter: React.Dispatch<React.SetStateAction<IFilters>>;
}

const FilterSelectDeliveryStatus = ({
  value = '',
  setFilter,
}: IFilterSelectDeliveryStatus) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const client = useApolloClient();

  const statuses = client.readQuery<
    GetStatusesListQuery,
    GetStatusesListQueryVariables
  >({
    query: getStatusesList,
  });

  return (
    <FormControl className={classes.filtersItemSelect}>
      <Select
        displayEmpty
        disableUnderline
        value={value}
        onChange={(e) => {
          setFilter((state) => ({
            ...state,
            deliveryStatusId: Number(e.target.value),
            page: 1,
          }));
        }}
      >
        <MenuItem value='' disabled>
          {t('app.deliveryStatus')}
        </MenuItem>
        {statuses?.getStatusesList?.DeliveryStatusList?.map((status) => {
          if (!status?.id) return null;
          return (
            <MenuItem key={status.id} value={status.id}>
              {t(DELIVERY_STATUS_BY_ID[Number(status.id)])}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default FilterSelectDeliveryStatus;
