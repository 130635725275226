import React from 'react';
import { FastField, FieldProps } from 'formik';
import {
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
} from '@material-ui/core';
import styles from './styles';
import { MAX_PARCEL_WEIGHT } from '../../../utils/constants';
import { useTranslation } from 'react-i18next';

const FieldWeightNet: React.FC<{
  handlerChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  fieldName?: string;
  unitIndex?: number;
  isDisabled?: boolean;
}> = ({ handlerChange, fieldName, unitIndex, isDisabled }) => {
  const classes = styles();
  const { t } = useTranslation();

  return (
    <FastField name={fieldName ? fieldName + '.netWeight' : 'netWeight'}>
      {({ field: { value, ...field }, meta }: FieldProps) => (
        <FormControl error={!!(meta.touched && meta.error && value !== 0)}>
          <Grid container justify='space-between'>
            <InputLabel
              className={classes.inputLabelMini}
              htmlFor={`input-net-weight-product-${unitIndex}`}
            >
              {t('app.netWeight')}
            </InputLabel>
          </Grid>
          <Input
            disabled={isDisabled}
            disableUnderline
            id={`input-net-weight-product-${unitIndex}`}
            {...field}
            inputProps={{
              type: 'number',
              min: 0,
              step: 0.01,
              max: MAX_PARCEL_WEIGHT,
            }}
            startAdornment={
              <InputAdornment position='end'>{t('app.kg')}</InputAdornment>
            }
            value={value}
            defaultValue={value}
            onChange={handlerChange}
          />
          {meta.touched && meta.error && value !== 0 && (
            <FormHelperText>{meta.error}</FormHelperText>
          )}
        </FormControl>
      )}
    </FastField>
  );
};

export default FieldWeightNet;
