import React from 'react';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import { Box, InputAdornment, Radio, Typography } from '@material-ui/core';
import { DatePicker, TimePicker } from '@material-ui/pickers';
import {
  B2BShipmentMethodTypeEnum,
  CreateB2BOrderInput,
} from '../../../generated/graphql';
import { useFormikContext } from 'formik';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import moment from 'moment';

const B2bShipmentSendFormStep5 = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext<CreateB2BOrderInput>();

  const handleCheckboxChange = (field: B2BShipmentMethodTypeEnum) => () => {
    setFieldValue('shipmentInfo.typeMethod', field);
  };

  return (
    <div>
      <Box display='flex' className={classes.pickerWrapper}>
        <Box>
          <Typography className={classes.contactFieldsTitle} variant='h3'>
            {t('app.date')}
          </Typography>
          <DatePicker
            className={classes.datePicker}
            id='start_date'
            value={values.shipmentInfo?.scheduledDate}
            onChange={(date) => {
              if (date) {
                setFieldValue('shipmentInfo.scheduledDate', date);
              }
            }}
            format='DD/MM/yyyy'
            minDate={moment().toDate()}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end' className={classes.adornment}>
                  <CalendarTodayIcon className={classes.calendarIcon} />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box>
          <Typography className={classes.contactFieldsTitle} variant='h3'>
            {t('app.time')}
          </Typography>
          <TimePicker
            className={classes.datePicker}
            id='start_time'
            value={values.shipmentInfo?.scheduledTime}
            onChange={(time) => {
              if (time) {
                setFieldValue('shipmentInfo.scheduledTime', time);
              }
            }}
            format='HH:mm'
            InputProps={{
              endAdornment: (
                <InputAdornment position='end' className={classes.adornment}>
                  <CalendarTodayIcon className={classes.calendarIcon} />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Box>
      <Box display='flex' flexDirection='column' alignItems='flex-start'>
        <Box display='flex' alignItems='center'>
          <Radio
            className={classes.checkBox}
            value={B2BShipmentMethodTypeEnum.PicknPack}
            checked={
              values?.shipmentInfo?.typeMethod ===
              B2BShipmentMethodTypeEnum.PicknPack
            }
            color='primary'
            onChange={handleCheckboxChange(B2BShipmentMethodTypeEnum.PicknPack)}
          />
          <Typography>{t('app.pnpDelivery')}</Typography>
        </Box>
        <Box display='flex' alignItems='center'>
          <Radio
            className={classes.checkBox}
            value={B2BShipmentMethodTypeEnum.Custom}
            checked={
              values?.shipmentInfo?.typeMethod ===
              B2BShipmentMethodTypeEnum.Custom
            }
            color='primary'
            onChange={handleCheckboxChange(B2BShipmentMethodTypeEnum.Custom)}
          />
          <Typography>{t('app.attachTransportLabel')}</Typography>
        </Box>
      </Box>
    </div>
  );
};

export default B2bShipmentSendFormStep5;
