import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../../utils/constants';

export default makeStyles((theme) => ({
  boxModalButtons: {
    margin: '30px 0 0',
    display: 'flex',
    justifyContent: 'space-around',
  },
  errorMsg: {
    color: COLORS.RED,
  },
  placeholder: {
    color: 'grey',
  },
  infoWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  closeButton: {
    marginRight: '10px',
  },
  carrierCodeIcon: {
    width: 20,
    maxHeight: 16,
    marginRight: 10,
  },
  carrierCodeSelectedItem: {
    display: 'flex',
    alignItems: 'center',
  },
}));
