import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const styles = (theme: Theme) =>
  createStyles({
    root: {
      paddingTop: 7,
    },
    title: {
      marginBottom: 16,
      paddingLeft: 63,

      [theme.breakpoints.down('md')]: {
        paddingLeft: 0,
      },
    },
    divider: {
      paddingLeft: 31,
      marginBottom: 18,
      [theme.breakpoints.down('md')]: {
        paddingLeft: 0,
      },
    },
    formWrapper: {
      paddingLeft: 63,

      [theme.breakpoints.down('md')]: {
        paddingLeft: 0,
      },
    },
  });

export default makeStyles(styles);
