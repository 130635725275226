import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../../../utils/constants';

export const styles = makeStyles((theme) => ({
  boxWrapper: {
    width: '100%',
    backgroundColor: COLORS.WHITE,
    borderRadius: '34px',
    padding: '32px',
  },
  boxCountWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  boxTitle: {
    fontFamily: '"Source Sans 3", sans-serif',
    fontOpticalSizing: 'auto',
    fontWeight: 400,
    fontStyle: 'normal',
    fontSize: 24,
  },
  boxCount: {
    fontFamily: '"Source Sans 3", sans-serif',
    fontOpticalSizing: 'auto',
    fontWeight: 600,
    fontStyle: 'normal',
    fontSize: 30,
  },
  icon: { width: '72px', color: COLORS.LIGHT_BLACK },
}));

export default styles;
