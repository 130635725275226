import PackageIcon from '../../assets/img/icon_package_black_big.png';
import PaymentsIcon from '../../assets/img/icon_payments_black_big.png';
import WarehouseIcon from '../../assets/img/icon_warehouse_black_big.png';
import FastShippingIcon from '../../assets/img/icon_fast_shipping_black_big.png';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import CategoryIcon from '@material-ui/icons/Category';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import { DashboardStatistic } from '../../generated/graphql';

export const getFirstRowOption = (statistic?: DashboardStatistic) => [
  {
    title: 'app.shipments',
    count: statistic?.warehouseShipmentsCount ?? 0,
    iconUrl: PackageIcon,
    isCurrency: false,
  },
  {
    title: 'app.returns',
    count: statistic?.returnsCount ?? 0,
    Icon: KeyboardReturnIcon,
    isCurrency: false,
  },
  {
    title: 'app.shippingCost',
    count: statistic?.totalWarehouseShipmentsCost
      ? Number(statistic?.totalWarehouseShipmentsCost?.toFixed(2))
      : 0,
    iconUrl: PaymentsIcon,
    isCurrency: true,
  },
];

export const getSecondRowOption = (statistic?: DashboardStatistic) => [
  {
    title: 'app.inStock',
    count: statistic?.totalProductQuantity ?? 0,
    Icon: ShoppingBasketIcon,
    isCurrency: false,
  },
  {
    title: 'app.skusInStock',
    count: statistic?.totalUniqueSKUCount ?? 0,
    Icon: CategoryIcon,
    isCurrency: false,
  },
  {
    title: 'app.storageCosts',
    count: statistic?.totalStorageCost
      ? Number(statistic?.totalStorageCost?.toFixed(2))
      : 0,
    Icon: MonetizationOnIcon,
    isCurrency: true,
  },
];

export const getThirdRowOption = (statistic?: DashboardStatistic) => [
  {
    title: 'app.shippedProducts',
    count: statistic?.totalShippedProductQuantity ?? 0,
    iconUrl: FastShippingIcon,
    isCurrency: false,
  },
  {
    title: 'app.receivedProducts',
    count: statistic?.totalInboundAcceptedProductQuantity ?? 0,
    iconUrl: WarehouseIcon,
    isCurrency: false,
  },
  {
    title: 'app.expectedProducts',
    count: statistic?.totalInboundInTransitProductQuantity ?? 0,
    Icon: LocalShippingIcon,
    isCurrency: false,
  },
];
