import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../../../../utils/constants';

export const styles = makeStyles((theme) => ({
  graphicWrapper: {
    width: 'calc(66.6% - 25.3px)',
    height: '420px',
    backgroundColor: COLORS.WHITE,
    borderRadius: '34px',
    padding: '32px',
    flexGrow: 1,
    '& canvas': {
      height: '288px !important',
    },
  },
  graphicTitle: {
    fontFamily: '"Source Sans 3", sans-serif',
    fontOpticalSizing: 'auto',
    fontWeight: 400,
    fontStyle: 'normal',
    fontSize: 24,
    marginBottom: '24px',
  },
}));

export default styles;
