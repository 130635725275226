import React from 'react';
import useStyles from './styles';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { INotificationItemComponent } from './types';
import moment from 'moment';
import { DATE_TIME_FORMAT } from '../../../utils/constants';

const NotificationItemComponent = ({
  item,
  readNotification,
  loading,
}: INotificationItemComponent) => {
  const classes = useStyles();

  const formattedSentAt = moment(new Date(String(item?.sendAt))).format(
    DATE_TIME_FORMAT,
  );

  return (
    <Box className={classes.notificationWrapper}>
      <Box>
        <Typography className={classes.titleDate}>{formattedSentAt}</Typography>
        <Typography className={classes.notificatioTitle}>
          {item.topic}
        </Typography>
        <Typography className={classes.notificationMessage}>
          {item.text}
        </Typography>
      </Box>
      {loading ? (
        <CircularProgress />
      ) : (
        <CloseIcon
          className={classes.closeIcon}
          onClick={readNotification(item.id)}
        />
      )}
    </Box>
  );
};

export default NotificationItemComponent;
