import React from 'react';
import {
  Box,
  Button,
  Checkbox,
  TableCell,
  TableRow,
  Tooltip,
  MenuItem,
  Select,
} from '@material-ui/core';
import {
  TRoutes,
  getCountryISOByName,
  getCountryNameByISO,
  getMarketplaceImageSrc,
  handleSelectCheckboxItem,
} from '../../utils/helpers';
import { BoxCentered } from '../../components/BoxCentered/BoxCentered';
import { RenderCountryFlagImage } from '../../utils/helperComponents';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import {
  IMarketplaceOrder,
  TSetStateArrayString,
  TSetStateBoolean,
} from '../../interfaces';
import { COLORS } from '../../utils/constants';
import { GetDeliveryCountriesQuery } from '../../generated/graphql';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';
import { useHistory } from 'react-router';

const MarketplacesOrdersTableRow: React.FC<{
  order: IMarketplaceOrder;
  index: number;
  setIsOpen: TSetStateBoolean;
  selected: string[];
  setSelected: TSetStateArrayString;
  handleCalculateCost: (values: any) => void;
  countries: GetDeliveryCountriesQuery | null;
  setOrderForModal: React.Dispatch<
    React.SetStateAction<IMarketplaceOrder | undefined>
  >;
  listOfDeliveryOptionsArr: any;
  getSendMarketplaceOrderLink: (
    order: IMarketplaceOrder | null,
    variant: TRoutes,
  ) => string;
}> = ({
  order,
  index,
  setIsOpen,
  selected,
  setSelected,
  handleCalculateCost,
  countries,
  setOrderForModal,
  listOfDeliveryOptionsArr,
  getSendMarketplaceOrderLink,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <TableRow>
      <TableCell align='center' style={{ paddingLeft: 10, paddingRight: 10 }}>
        <Checkbox
          onChange={(e) =>
            handleSelectCheckboxItem(
              e,
              order?.id?.toString(),
              selected,
              setSelected,
            )
          }
          checked={selected.indexOf(order.id?.toString()) !== -1}
        />
      </TableCell>

      {/* Маркетплейс */}
      <TableCell align='center' style={{ paddingRight: 0 }}>
        {order.marketplace && (
          <img
            style={{ maxWidth: 25 }}
            src={getMarketplaceImageSrc(order.marketplace)}
            alt={order.marketplace}
          />
        )}
      </TableCell>

      {/* № Заказа */}
      <TableCell>
        <div>{order.orderId}</div>
        <BoxCentered justifyContent='flex-start'>
          <Box mr={1}>{new Date(order.createdAt).toLocaleDateString()}</Box>
          <RenderIsOrderPaid isPaid={order.isPaid} />
        </BoxCentered>
      </TableCell>

      {/* Заказчик */}
      <TableCell>
        <strong>{order.receiverName}</strong>
        <BoxCentered justifyContent='flex-start'>
          <RenderCountryFlagImage
            countryName={getCountryNameByISO(order?.country) || order?.country}
            countryISO={getCountryISOByName(order?.country) || order?.country}
          />
          {order?.city}
          &nbsp;
          {order?.zip}
        </BoxCentered>
        {getCountryNameByISO(order?.country) || order?.country}
      </TableCell>

      {/* Товар */}
      <TableCell>
        {order?.orderProducts?.map((product, index) => (
          <div key={index}>
            {product?.quantity} x {product?.title}
            <br />
            {product?.priceAmount} {product?.priceCurrency}
          </div>
        ))}
        {order?.shippingMethod}
      </TableCell>

      {/* Упаковка */}
      <TableCell align='center'>
        {order?.package?.height &&
          order?.package?.width &&
          order?.package?.weight &&
          order?.package?.length && (
            <>
              {order?.package?.weight} {t('app.kg')} {order?.package?.width}x
              {order?.package?.length}x{order?.package?.height}
            </>
          )}

        {!order?.package?.height &&
          !order?.package?.width &&
          !order?.package?.weight &&
          !order?.package?.length && (
            <Button
              variant='text'
              startIcon={<AddCircleIcon color='primary' />}
              onClick={() => {
                setOrderForModal(order);
                setIsOpen(true);
              }}
            >
              {t('app.add')}
            </Button>
          )}
      </TableCell>

      {/* Доставка */}
      <TableCell align='center'>
        {listOfDeliveryOptionsArr[index] ? (
          <Select displayEmpty disableUnderline className={classes.Select}>
            {listOfDeliveryOptionsArr[
              index
            ]?.getListOfDeliveryOptions?.data.map(
              (item: any, index: number) => (
                <>
                  <MenuItem value={item} key={index}>
                    <div className={classes.selectItem}>
                      <p className={classes.selectItemText}>{item?.name}</p>
                      <p className={classes.selectItemText}>
                        {item?.days_to_delivery} {t('app.days2')}{' '}
                        {item?.summary_amount} Р
                      </p>
                    </div>
                  </MenuItem>
                  <hr className={classes.horizontalLine} />
                </>
              ),
            )}
          </Select>
        ) : (
          <Button
            size='small'
            variant='contained'
            color='primary'
            onClick={() => handleCalculateCost(order)}
          >
            {t('app.calculate')}
          </Button>
        )}
      </TableCell>

      {/* Статус */}
      <TableCell align='center'>
        <RenderOrderStatus status={order?.isShipped} />
      </TableCell>

      {/* Действия */}
      <TableCell align='center'>
        {!order.isShipped && (
          <Tooltip title={t('app.clickToStartProcessing')}>
            <Button
              style={{ color: COLORS.BLUE }}
              onClick={() => {
                history.push(
                  getSendMarketplaceOrderLink(
                    order,
                    TRoutes.SHIPMENT_FROM_WAREHOUSE_CREATE_WITH_LABEL,
                  ),
                );
              }}
            >
              {t('app.send')}
            </Button>
          </Tooltip>
        )}
      </TableCell>
    </TableRow>
  );
};

const RenderIsOrderPaid: React.FC<{ isPaid: boolean }> = ({ isPaid }) => {
  const classes = useStyles();
  return (
    <Box
      color={COLORS.WHITE}
      bgcolor={isPaid ? COLORS.GREEN : COLORS.RED}
      className={classes.isPaid}
    >
      {isPaid ? 'Paid' : 'Not Paid'}
    </Box>
  );
};

const RenderOrderStatus: React.FC<{
  status: boolean;
}> = ({ status }) => {
  let text = '';

  switch (status) {
    case false:
      text = t('app.notShipped');
      break;
    case true:
      text = t('app.shipped');
      break;
  }

  return (
    <Box
      padding='6px 15px'
      whiteSpace='nowrap'
      bgcolor={status ? COLORS.GREEN : COLORS.RED}
      color={COLORS.WHITE}
    >
      {text}
    </Box>
  );
};

export default MarketplacesOrdersTableRow;
