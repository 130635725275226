import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  footer: {
    fontSize: 16,
    marginTop: 20,
    marginBottom: 20,
    display: 'flex',
    flexWrap: 'wrap',
    whiteSpace: 'nowrap',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down(1050)]: {
      flexDirection: 'column',
    },
  },
  divider: {
    height: 22,
    marginRight: 5,
    marginLeft: 5,

    [theme.breakpoints.down(1050)]: {
      display: 'none',
    },
  },
  item: {
    display: 'flex',
    alignItems: 'center',
  },
  socialIcon: {
    maxWidth: 25,
  },
}));
