import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { FastField, Field, FieldArray, FieldProps } from 'formik';
import AddIcon from '@material-ui/icons/Add';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import {
  AvailableWarehouseProductsItem,
  DeliveryCountry,
  GetDeliveryCountriesQuery,
  GetDeliveryCountriesQueryVariables,
} from '../../../generated/graphql';
import {
  TFormikSetFieldValue,
  TFormikSetFieldValueDebounce,
  TSetStateNumber,
} from '../../../interfaces';
import useStyles from './styles';
import {
  COLORS,
  CURRENCIES,
  LOCAL_STORAGE_KEYS,
} from '../../../utils/constants';
import FieldCountry from '../../../components/Units/FieldCountry/FieldCountry';
import FieldWeightNet from '../../../components/Units/FieldWeightNet/FieldWeightNet';
import FieldCodeTNVED from '../../../components/Units/FieldCodeTNVED/FieldCodeTNVED';
import { useApolloClient } from '@apollo/client';
import { QUERY_DELIVERY_COUNTRIES } from '../../../GraphQL/queries/getDeliveryCountries';
import { sumByField, sumQuantityByField } from '../../../utils/helpers';
import { useTranslation } from 'react-i18next';

const ShipmentFromWarehouseFormStep2: React.FC<{
  values: any;
  currency: string;
  setFieldValue: TFormikSetFieldValue;
  setUpdateView: TSetStateNumber;
  setFieldValueDebounce: TFormikSetFieldValueDebounce;
  products: AvailableWarehouseProductsItem[];
}> = ({
  values,
  currency,
  setFieldValue,
  setUpdateView,
  setFieldValueDebounce,
  products,
}) => {
  const classes = useStyles();
  const client = useApolloClient();
  const [elementToRemove, setElementToRemove] = useState<number | null>();
  const [countries, setCountries] = useState<DeliveryCountry[]>([]);
  const { t } = useTranslation();

  const CURRENT_APP_CURRENCY = localStorage.getItem(
    LOCAL_STORAGE_KEYS.CURRENT_APP_CURRENCY,
  );

  const dataCountries = client.readQuery<
    GetDeliveryCountriesQuery,
    GetDeliveryCountriesQueryVariables
  >({
    query: QUERY_DELIVERY_COUNTRIES,
  });

  useEffect(() => {
    if (dataCountries?.deliveryCountries?.length) {
      // Need Russia here
      setCountries(
        [...(dataCountries?.deliveryCountries as DeliveryCountry[])].sort(
          (a, b) =>
            a.nameEng < b.nameEng ? -1 : a.nameEng > b.nameEng ? 1 : 0,
        ),
      );
    }
    // eslint-disable-next-line
  }, [dataCountries]);

  const showPreRemoveStyle = (element: any) => {
    return elementToRemove === element
      ? { background: '#cacaca', filter: 'blur(1px)' }
      : {};
  };

  const handlerChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const name = e.target.name;
    const value = e.target.value;

    setFieldValue(name, value);
  };

  const filteredSelectedProducts = () => {
    const filteredProducts = products?.filter(
      (product: AvailableWarehouseProductsItem) =>
        !values?.productsSku?.includes(product?.product?.sku),
    );
    return filteredProducts;
  };

  return (
    <>
      <FieldArray
        name='products'
        render={(helpers) => (
          <div>
            {values?.products?.map((productItem: any, productIndex: number) => {
              const fieldNamePrefix = `selected[${productIndex}].product`;
              return (
                <div
                  key={productIndex}
                  className={classes.productItem}
                  style={showPreRemoveStyle(productIndex)}
                >
                  <div className={classes.root}>
                    <Grid container spacing={5}>
                      <Grid item xs={12}>
                        <Box
                          display={'flex'}
                          alignItems={'center'}
                          justifyContent={'center'}
                          height={'100%'}
                          position={'relative'}
                        >
                          <Typography variant={'h6'}>
                            {t('app.product')} №{productIndex + 1}
                          </Typography>
                          {productIndex !== 0 && (
                            <Tooltip
                              title={`${t('app.removeProduct')} №${
                                productIndex + 1
                              } ?`}
                            >
                              <DeleteForeverIcon
                                className={classes.removeProduct}
                                style={{ color: COLORS.RED }}
                                onMouseEnter={() =>
                                  setElementToRemove(productIndex)
                                }
                                onMouseLeave={() => setElementToRemove(null)}
                                onClick={(e) => {
                                  e.preventDefault();
                                  helpers.remove(productIndex);
                                }}
                              />
                            </Tooltip>
                          )}
                        </Box>
                      </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                      <Grid item xs={12} style={{ marginBottom: '20px' }}>
                        <Autocomplete
                          // style={{ marginRight: '20px' }}
                          fullWidth
                          loadingText={`${t('app.upload')}...`}
                          closeText={t('app.close')}
                          openText={t('app.open')}
                          clearText={t('app.clear')}
                          defaultValue={products?.find(
                            (item: AvailableWarehouseProductsItem) => {
                              return (
                                //@ts-ignore
                                Number(item?.product?.id) ===
                                values?.products[productIndex]?.id
                              );
                            },
                          )}
                          noOptionsText={t('app.noItemsFound')}
                          options={
                            filteredSelectedProducts() as AvailableWarehouseProductsItem[]
                          }
                          renderInput={(params) => (
                            <div ref={params.InputProps.ref}>
                              <TextField
                                label={t('app.selectProduct2')}
                                {...params}
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: 'my_products',
                                }}
                              />
                            </div>
                          )}
                          renderOption={(option) => {
                            return (
                              <>
                                {'SKU:'}{' '}
                                {option?.product?.sku
                                  ? `[${option?.product?.sku}]`
                                  : ''}{' '}
                                {t('app.availableReserved', {
                                  value: option?.availableQuantity,
                                  reserved: option?.reservedQuantity,
                                })}{' '}
                                {option?.product?.name}
                              </>
                            );
                          }}
                          getOptionLabel={(option) => {
                            return `SKU: ${
                              option?.product?.sku
                                ? '[' + option?.product?.sku + ']'
                                : ''
                            } ${t('app.availableReserved', {
                              value: option?.availableQuantity,
                              reserved: option?.reservedQuantity,
                            })} - ${option?.product?.name || ''}`;
                          }}
                          onChange={(e, newValue) => {
                            const id =
                              newValue?.product?.id && +newValue?.product?.id
                                ? +newValue?.product?.id
                                : null;
                            //@ts-ignore
                            if (id) {
                              setFieldValue(`products[${productIndex}].id`, id);
                              setFieldValue(
                                `productsSku[${productIndex}]`,
                                newValue?.product?.sku,
                              );

                              setFieldValue(
                                `products[${productIndex}].price`,
                                newValue?.product?.declaredValue,
                              );
                              setFieldValue(
                                `products[${productIndex}].nameEN`,
                                newValue?.product?.detailsDescriptionEN,
                              );
                              setFieldValue(
                                `products[${productIndex}].code`,
                                newValue?.product?.code,
                              );
                              setFieldValue(
                                `products[${productIndex}].countryId`,
                                newValue?.product?.country?.id,
                              );
                              setFieldValue(
                                `products[${productIndex}].weight`,
                                newValue?.product?.actualDimensions?.weight ||
                                  newValue?.product?.declaredDimensions?.weight,
                              );
                              setFieldValue(
                                `selected[${productIndex}].product`,
                                newValue?.product,
                              );
                              setFieldValue(
                                `selected[${productIndex}].product.netWeight`,
                                newValue?.product?.actualDimensions?.weight ||
                                  newValue?.product?.declaredDimensions?.weight,
                              );
                              setFieldValue(
                                `selected[${productIndex}].maxQuantity`,
                                newValue?.availableQuantity,
                              );
                            }
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} lg={2}>
                        <FastField name={`products[${productIndex}].quantity`}>
                          {({
                            field: { value, ...field },
                            meta,
                          }: FieldProps) => {
                            return (
                              <FormControl
                                error={
                                  !!(meta.touched && meta.error) ||
                                  !!(
                                    +value >
                                    values?.selected[productIndex]?.maxQuantity
                                  )
                                }
                              >
                                <InputLabel
                                  shrink={false}
                                  htmlFor={`input-quantity-product-${productIndex}`}
                                  style={{
                                    fontSize: '13px',
                                    whiteSpace: 'nowrap',
                                  }}
                                >
                                  {t('app.quantity')}
                                </InputLabel>
                                <Input
                                  disableUnderline
                                  {...field}
                                  inputProps={{
                                    type: 'number',
                                    min: 1,
                                    step: 1,
                                    max: values?.selected[productIndex]
                                      ?.maxQuantity,
                                  }}
                                  startAdornment={
                                    <InputAdornment position='end'>
                                      {t('app.pcs')}.
                                    </InputAdornment>
                                  }
                                  defaultValue={value}
                                  onChange={(e) => {
                                    setFieldValueDebounce(
                                      `products[${productIndex}].quantity`,
                                      +e.target.value,
                                    );
                                  }}
                                  id={`input-quantity-product-${productIndex}`}
                                />
                                {(meta.touched && meta.error) ||
                                  (!!(
                                    +value >
                                    values?.selected[productIndex]?.maxQuantity
                                  ) && (
                                    <FormHelperText
                                      style={{
                                        display: 'inline',
                                      }}
                                    >
                                      {meta.error ||
                                        t('app.quantityNotExceedAvailable')}
                                    </FormHelperText>
                                  ))}
                              </FormControl>
                            );
                          }}
                        </FastField>
                      </Grid>
                      <Grid item xs={12} sm={6} lg={2}>
                        <FastField name={`products[${productIndex}].price`}>
                          {({
                            field: { value, ...field },
                            meta,
                          }: FieldProps) => (
                            <FormControl
                              error={
                                !!(meta.touched && meta.error && value !== 0)
                              }
                            >
                              <InputLabel
                                htmlFor={`input-price-product-${productIndex}`}
                                style={{ whiteSpace: 'nowrap' }}
                              >
                                {t('app.itemCost')}
                              </InputLabel>
                              <Input
                                disableUnderline
                                id={`input-price-product-${productIndex}`}
                                {...field}
                                startAdornment={
                                  <InputAdornment
                                    position='start'
                                    style={{ paddingLeft: 10 }}
                                  >
                                    {(
                                      currency || String(CURRENT_APP_CURRENCY)
                                    ).toUpperCase()}
                                  </InputAdornment>
                                }
                                inputProps={{
                                  type: 'number',
                                  min: 0,
                                  step: 0.1,
                                }}
                                value={value}
                                defaultValue={value}
                                onChange={(e) => {
                                  setFieldValue(
                                    `products[${productIndex}].price`,
                                    +e.target.value,
                                  );
                                }}
                              />
                              {meta.touched && meta.error && value !== 0 && (
                                <FormHelperText>{meta.error}</FormHelperText>
                              )}
                            </FormControl>
                          )}
                        </FastField>
                      </Grid>
                      <Grid item sm={6} lg={1}>
                        <Field name='currency'>
                          {({ field, meta }: FieldProps) => (
                            <FormControl error={!!(meta.touched && meta.error)}>
                              <InputLabel
                                shrink={false}
                                htmlFor='input-price-unit'
                                style={{
                                  fontSize: '13px',
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                {t('app.currency')}
                              </InputLabel>
                              <Select
                                id='input-price-unit'
                                disableUnderline
                                {...field}
                                onChange={(e) => {
                                  setFieldValue('currency', e.target.value);
                                  setUpdateView &&
                                    setUpdateView(
                                      (prevUpdateView) => prevUpdateView + 1,
                                    );
                                }}
                              >
                                <MenuItem value={CURRENCIES.RUB.toLowerCase()}>
                                  {CURRENCIES.RUB}
                                </MenuItem>
                                <MenuItem value={CURRENCIES.EUR.toLowerCase()}>
                                  {CURRENCIES.EUR}
                                </MenuItem>
                                <MenuItem value={CURRENCIES.USD.toLowerCase()}>
                                  {CURRENCIES.USD}
                                </MenuItem>
                                <MenuItem value={CURRENCIES.GBP.toLowerCase()}>
                                  {CURRENCIES.GBP}
                                </MenuItem>
                              </Select>
                              {meta.touched && meta.error && (
                                <FormHelperText>{meta.error}</FormHelperText>
                              )}
                            </FormControl>
                          )}
                        </Field>
                      </Grid>
                      <Grid item xs={12} sm={6} lg={3}>
                        <FieldCountry
                          countries={countries}
                          setFieldValue={setFieldValueDebounce}
                          fieldName={fieldNamePrefix}
                          unitIndex={productIndex}
                          isDisabled={true}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} lg={2}>
                        <FieldWeightNet
                          handlerChange={handlerChange}
                          fieldName={fieldNamePrefix}
                          unitIndex={productIndex}
                          isDisabled={true}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} lg={2}>
                        <FieldCodeTNVED
                          handlerChange={handlerChange}
                          fieldName={fieldNamePrefix}
                          unitIndex={productIndex}
                          isDisabled={true}
                        />
                      </Grid>
                    </Grid>
                  </div>
                </div>
              );
            })}
            <Box display={'flex'} justifyContent={'center'} marginTop={2}>
              <Button
                className={classes.showButton}
                startIcon={<AddIcon />}
                variant='text'
                onClick={() => {
                  helpers.push({ id: 0, quantity: 1, price: 0 });
                }}
              >
                {t('app.addProduct')}
              </Button>
            </Box>
            <div className={classes.parcelInfo}>
              <span>
                {t('app.totalItems')}:{' '}
                <b>
                  {sumByField(values.products, 'quantity', (val) => `${val}`)}
                </b>{' '}
                {t('app.pcs')}
              </span>
              <Divider
                className={classes.parcelInfoDivider}
                orientation='vertical'
              />
              <Tooltip title={t('app.totalWeight')}>
                <span>
                  {t('app.totalWeight')}:{' '}
                  <b>
                    {sumQuantityByField(
                      values.products,
                      'weight',
                      (val) => `${val}`,
                    )}
                  </b>{' '}
                  {t('app.kg')}
                </span>
              </Tooltip>
              <Divider
                className={classes.parcelInfoDivider}
                orientation='vertical'
              />

              <span>
                {t('app.totalCost')}
                <b>
                  {sumQuantityByField(
                    values.products,
                    'price',
                    (val) => `${val}`,
                  )}
                </b>{' '}
                {values?.currency?.toUpperCase()}
              </span>
            </div>
          </div>
        )}
      />
    </>
  );
};

export default ShipmentFromWarehouseFormStep2;
