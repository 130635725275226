import { makeStyles } from '@material-ui/core/styles';

export const styles = makeStyles((theme) => ({
  boxesWrapper: {
    display: 'flex',
    flexDirection: 'row',
    gap: '38px',
  },
  graphWrapper: {
    display: 'flex',
    gap: '38px',
  },
  pageWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '30px',
  },
}));

export default styles;
