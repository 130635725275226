import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import useStyles from './style';
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import HomeIcon from '@material-ui/icons/Home';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import { RenderCountryFlagImage } from '../../utils/helperComponents';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import EditIcon from '@material-ui/icons/Edit';
import { useHistory } from 'react-router';
import { TRoutes } from '../../utils/helpers';
import { useGetCurrentUserQuery } from '../../generated/graphql';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const PersonalData = () => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  const { data: userData } = useGetCurrentUserQuery({
    fetchPolicy: 'cache-first',
  });

  const user = userData?.currentUser;

  const verify =
    user?.passportData?.isDataVerified || user?.entityLegalData?.isDataVerified;

  return (
    <Box>
      <Typography variant='h2' className={classes.mainTitle} align='center'>
        {t('app.personalData2')}
      </Typography>
      <Grid container spacing={3} className={classes.wrapperOuter}>
        <Grid item md={6} style={{ display: 'flex' }}>
          <Box className={classes.wrapperInner}>
            <Box className={classes.line}>
              <AccessibilityIcon className={classes.icons} /> {user?.name}
            </Box>
            <Box className={classes.line}>
              <HomeIcon className={classes.icons} /> {user?.address}
            </Box>
            <Box className={classes.line}>
              <LocationCityIcon className={classes.icons} /> {user?.city}{' '}
              {user?.state}
            </Box>
            <Box className={classes.line}>
              <RenderCountryFlagImage
                countryISO={user?.country?.iso as string}
                countryName={user?.country?.name as string}
                className={classes.icons}
              />
              {user?.country?.name}
            </Box>
            <Box className={classes.line}>
              <PhoneIcon className={classes.icons} /> {user?.phone}
            </Box>
            <Box className={classes.line}>
              <EmailIcon className={classes.icons} /> {user?.email}
            </Box>
          </Box>
          <Box>
            <EditIcon
              onClick={() => {
                history.push(TRoutes.PERSONAL);
              }}
              style={{ cursor: 'pointer', marginLeft: '30px' }}
            />
          </Box>
        </Grid>
      </Grid>
      {!verify ? (
        <>
          <Box className={classes.alertWrapper}>
            <Box className={classes.alertIconWrapper}>
              <ReportProblemIcon />
            </Box>
            <Box className={classes.alertTextWrapper}>
              <Box>
                {t('app.accountVerificationRequired')}{' '}
                <Link to={TRoutes.PERSONAl_DATA_VERIFY}>
                  {t('app.goToVerification')}
                </Link>
              </Box>
            </Box>
          </Box>
        </>
      ) : null}
    </Box>
  );
};
