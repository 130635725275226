import React from 'react';
import {
  Box,
  Button,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Delete as DeleteIcon, Edit as EditIcon } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import { TRoutes } from '../../utils/helpers';
import useStyles from './styles';
import {
  RenderCountryFlagImage,
  ShowLoadingText,
} from '../../utils/helperComponents';
import {
  useGetContactsQuery,
  useRemoveContactMutation,
} from '../../generated/graphql';
import { useHistory } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import { COLORS, TEXT } from '../../utils/constants';
import InfoIcon from '@material-ui/icons/Info';
import { BoxCentered } from '../../components/BoxCentered/BoxCentered';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import { useTranslation } from 'react-i18next';

export default function Contacts() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const [removeContactMutation] = useRemoveContactMutation();
  const {
    loading,
    error,
    data,
    refetch: reRetchContacts,
  } = useGetContactsQuery();

  if (loading)
    return (
      <div>
        <ShowLoadingText name={t('app.contacts')} />
      </div>
    );
  if (error) enqueueSnackbar(error.message, { variant: 'error' });
  if (!data || !data.contacts) return <div>{t('app.noData2')}</div>;

  const handlerRemove =
    (id: string) => (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();

      removeContactMutation({
        variables: { id },
      })
        .then(() => {
          enqueueSnackbar(t('app.contactDeletedSuccessfully'), {
            variant: 'success',
          });
          reRetchContacts();
        })
        .catch((error) => enqueueSnackbar(error.message, { variant: 'error' }));
    };
  const handlerEdit =
    (id: string) => (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      history.push(`${TRoutes.CONTACTS}/${id}`);
    };

  return (
    <>
      <Box position='relative' padding='7px' textAlign='center'>
        <Typography className={classes.headerTitle} variant='h2'>
          {t('app.addressBook').toUpperCase()}
        </Typography>
        <Button
          className={classes.button}
          variant='contained'
          onClick={() => history.push(TRoutes.CONTACTS_CREATE)}
          startIcon={<AddIcon />}
        >
          {t('app.createContact2')}
        </Button>
      </Box>
      <TableContainer>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.cellHead}>
                {t('app.addressName')}
              </TableCell>
              <TableCell className={classes.cellHead}>
                {t('app.fullName')}
              </TableCell>
              <TableCell className={classes.cellHead}>
                {t('app.company')}
              </TableCell>
              <TableCell className={classes.cellHead}>
                {t('app.country')}
              </TableCell>
              <TableCell className={classes.cellHead}>
                {t('app.stateProvince')}
              </TableCell>
              <TableCell className={classes.cellHead}>
                {t('app.city')}
              </TableCell>
              <TableCell className={classes.cellHead}>
                {t('app.address')}
              </TableCell>
              <TableCell className={classes.cellHead}>
                {t('app.phone')}
              </TableCell>
              <TableCell className={classes.cellHead}>
                <Tooltip title='E-Mail'>
                  <AlternateEmailIcon />
                </Tooltip>
              </TableCell>
              <TableCell className={classes.cellHead}>
                <Tooltip title={t('app.noteAboutContact')}>
                  <InfoIcon />
                </Tooltip>
              </TableCell>
              <TableCell className={classes.cellHead} />
            </TableRow>
          </TableHead>
          <TableBody>
            {data.contacts.map(
              (row) =>
                row && (
                  <TableRow key={row.id}>
                    <TableCell className={classes.cell} scope='row'>
                      {row.contactName}
                    </TableCell>
                    <TableCell className={classes.cell} scope='row'>
                      {row.name}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {row.company && (
                        <Tooltip title={t('app.findInGoogle')}>
                          <a
                            href={`https://www.google.com/search?q=${row.company}`}
                            target='_blank'
                            rel='noopener noreferrer'
                          >
                            {row.company}
                          </a>
                        </Tooltip>
                      )}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      <BoxCentered>
                        {row.country && (
                          <RenderCountryFlagImage
                            countryName={row.country.name}
                          />
                        )}
                        {i18n.resolvedLanguage === 'en'
                          ? row?.country?.nameEng
                          : row?.country?.name}
                      </BoxCentered>
                    </TableCell>
                    <TableCell className={classes.cell}>{row.state}</TableCell>
                    <TableCell className={classes.cell}>{row.city}</TableCell>
                    <TableCell className={classes.cell}>
                      {row.address}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      <Tooltip title={t(TEXT.CLICK_TO_MAKE_A_CALL)}>
                        <a
                          href={`tel:${row.phone}`}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          {row.phone}
                        </a>
                      </Tooltip>
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {row.email && (
                        <a
                          href={`mailto:${row.email}`}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          {row.email}
                        </a>
                      )}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {row.remark && (
                        <Tooltip title={row.remark}>
                          <InfoIcon />
                        </Tooltip>
                      )}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      <Grid container alignItems='center' wrap='nowrap'>
                        <IconButton
                          onClick={handlerEdit(row.id)}
                          className={classes.cellAction}
                          aria-label='edit'
                          style={{ color: COLORS.ORANGE }}
                        >
                          <EditIcon color='inherit' />
                        </IconButton>
                        <IconButton
                          onClick={handlerRemove(row.id)}
                          className={classes.cellAction}
                          aria-label='remove'
                          style={{ color: COLORS.RED }}
                        >
                          <DeleteIcon color='inherit' />
                        </IconButton>
                      </Grid>
                    </TableCell>
                  </TableRow>
                ),
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
