import { gql } from '@apollo/client';

export const MUTATION_SWITCH_STORE_STATUS = gql`
  mutation switchStoreStatus($storeId: ID!) {
    switchStoreStatus(storeId: $storeId) {
      name
      url
      userId
      storeId
      isActive
      marketplace
    }
  }
`;
