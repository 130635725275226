import React from 'react';
import { Box, Card, CardContent, Grid, Typography } from '@material-ui/core';
import { COLORS } from '../../utils/constants';
import WarningIcon from '@material-ui/icons/Warning';

export const CardWarning: React.FC<{
  title: string;
  children?: React.ReactNode;
}> = ({ title, children }) => {
  return (
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={1}>
            <Box
              display='flex'
              alignItems='center'
              justifyContent='center'
              height='100%'
              border={`1px solid ${COLORS.ORANGE}`}
            >
              <WarningIcon fontSize='large' />
            </Box>
          </Grid>
          <Grid item xs={11}>
            <Box padding={2} border={`1px solid ${COLORS.ORANGE}`}>
              <Typography style={{ marginBottom: 10 }} variant='h6'>
                {title}
              </Typography>
              {children}
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
