import { Contact } from '../../../../generated/graphql';

export const transformRecieverBeforeCreate = (contact: Contact) => {
  const receiver = {
    id: contact.id,
    name: contact.name,
    company: contact.company,
    country: contact.country,
    address: contact.address,
    address2: contact.address2,
    address3: contact.address3,
    zipCode: contact.zipCode,
    city: contact.city,
    state: contact.state,
    phone: contact.phone,
    email: contact.email,
    remark: contact.remark,
    eoriCode: contact.eoriCode,
    innKppCode: contact.innKppCode,
    countryId: contact?.country?.id,
  };
  return receiver;
};
