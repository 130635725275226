import React, { useState } from 'react';
import {
  Typography,
  FormControl,
  InputLabel,
  Button,
  Input,
  InputAdornment,
  IconButton,
  Divider,
  FormHelperText,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { Formik, Field, Form, FormikProps, FormikHelpers } from 'formik';
import { useLocation, useHistory } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { TRoutes } from '../../utils/helpers';
import { password as passwordSchema } from '../../utils/validationSchemes';
import useStyles from './styles';
import { useSaveNewPasswordMutation } from '../../generated/graphql';
import { TRecoveryPasswordForm } from '../../interfaces';
import { useTranslation } from 'react-i18next';

export default function RecoveryPassword() {
  const [isShowPassword, toggleShowPassword] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();
  const [saveNewPasswordMutation] = useSaveNewPasswordMutation();

  const { t } = useTranslation();

  const token = new URLSearchParams(location.search).get('t');

  if (!token) {
    history.push(TRoutes.MAIN);
    return null;
  }

  const handlerSubmit = (
    values: TRecoveryPasswordForm,
    { setSubmitting }: FormikHelpers<TRecoveryPasswordForm>,
  ) => {
    saveNewPasswordMutation({
      variables: {
        token,
        password: values.password,
      },
    })
      .then(({ data }) => {
        enqueueSnackbar(t('app.newPasswordSaved'), {
          variant: 'success',
        });
        history.push(TRoutes.MAIN);
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <div>
      <Typography variant='h2'>{t('app.passwordRecovery')}</Typography>
      <Divider className={classes.divider} />

      <Formik
        initialValues={
          { password: '', confirmPassword: '' } as TRecoveryPasswordForm
        }
        validationSchema={passwordSchema}
        onSubmit={(e, v) => handlerSubmit(e, v)}
      >
        {(props: FormikProps<TRecoveryPasswordForm>) => {
          const { isSubmitting, getFieldProps, getFieldMeta } = props;

          return (
            <Form className={classes.formSignUp} noValidate autoComplete='off'>
              <Field name='password'>
                {() => (
                  <FormControl
                    error={
                      !!(
                        getFieldMeta('password').touched &&
                        getFieldMeta('password').error
                      )
                    }
                    className={classes.formControl}
                  >
                    <InputLabel shrink={false} htmlFor='input-password'>
                      {t('app.newPassword')}
                    </InputLabel>
                    <Input
                      disableUnderline
                      fullWidth
                      id='input-password'
                      type={isShowPassword ? 'text' : 'password'}
                      {...getFieldProps('password')}
                      endAdornment={
                        <InputAdornment
                          position='end'
                          onClick={() => toggleShowPassword(!isShowPassword)}
                        >
                          <IconButton aria-label='toggle password visibility'>
                            {isShowPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {getFieldMeta('password').touched &&
                      getFieldMeta('password').error && (
                        <FormHelperText>
                          {getFieldMeta('password').error}
                        </FormHelperText>
                      )}
                  </FormControl>
                )}
              </Field>
              <Field name='confirmPassword'>
                {() => (
                  <FormControl
                    error={
                      !!(
                        getFieldMeta('confirmPassword').touched &&
                        getFieldMeta('confirmPassword').error
                      )
                    }
                    className={classes.formControl}
                  >
                    <InputLabel shrink={false} htmlFor='input-confirm-password'>
                      {t('app.confirmNewPassword')}
                    </InputLabel>
                    <Input
                      disableUnderline
                      fullWidth
                      id='input-confirm-password'
                      type={isShowPassword ? 'text' : 'password'}
                      {...getFieldProps('confirmPassword')}
                      endAdornment={
                        <InputAdornment
                          position='end'
                          onClick={() => toggleShowPassword(!isShowPassword)}
                        >
                          <IconButton aria-label='toggle password visibility'>
                            {isShowPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {getFieldMeta('confirmPassword').touched &&
                      getFieldMeta('confirmPassword').error && (
                        <FormHelperText>
                          {getFieldMeta('confirmPassword').error}
                        </FormHelperText>
                      )}
                  </FormControl>
                )}
              </Field>

              <Button
                className={classes.signButton}
                disabled={isSubmitting}
                type='submit'
                variant='contained'
              >
                {t('app.save')}
              </Button>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
