import React from 'react';
import { Button, Tooltip } from '@material-ui/core';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import { useTranslation } from 'react-i18next';

const ButtonSendSelected: React.FC<{
  disabled: boolean;
  onClick: () => void;
  tooltipTitle: string;
}> = ({ disabled, tooltipTitle, onClick }) => {
  const { t } = useTranslation();
  return (
    <Tooltip title={tooltipTitle}>
      <Button
        onClick={onClick}
        className='buttonForSelected'
        size='small'
        disabled={disabled}
        variant='outlined'
        startIcon={<LocalShippingIcon />}
      >
        {t('app.send')}
      </Button>
    </Tooltip>
  );
};

export default ButtonSendSelected;
