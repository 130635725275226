import React from 'react';
import { MenuItem, Select, TableCell, TableRow } from '@material-ui/core';
import { Link } from 'react-router-dom';
import TrackTrackNumberComponent from '../../TrackTrackNumberComponent/TrackTrackNumberComponent';
import {
  ActionsItem,
  ConvertWarehouseStatus,
} from '../../../utils/helperComponents';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import { TRoutes } from '../../../utils/helpers';
import { DATE_FORMAT } from '../../../utils/constants';
import moment from 'moment';
import { getActionOptions } from './options';
import { IUndeclaredArrivalTableRowComponent } from './types';

const UndeclaredArrivalTableRowComponent = ({
  item,
}: IUndeclaredArrivalTableRowComponent) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const formattedDate = moment(Number(item?.createdAt)).format(DATE_FORMAT);

  return (
    <TableRow>
      <TableCell align='center'>
        <Link
          to={`${TRoutes.UNDECLARED_ARRIVAL_DETAILS_WITHOUT_ID}${item?.id}`}
        >
          {`UND-${item?.id}`}
        </Link>
      </TableCell>
      <TableCell align='center'>{formattedDate}</TableCell>
      <TableCell align='center'>{item?.warehouse}</TableCell>
      <TableCell align='center'>
        {item?.trackNumber && (
          <TrackTrackNumberComponent
            carrierCode={item?.carrierCode}
            trackNumber={item.trackNumber}
          />
        )}
      </TableCell>
      <TableCell align='center'>
        <ConvertWarehouseStatus status={item?.warehouseStatus as string} />
      </TableCell>
      <TableCell align='center'>{item?.quantityItems}</TableCell>

      <TableCell align='center'>
        <Select value={t('app.action')}>
          <MenuItem
            value={t('app.action')}
            disabled
            style={{ display: 'none' }}
          >
            {t('app.action')}
          </MenuItem>
          {getActionOptions(item?.id as number).map((option) => (
            <ActionsItem
              {...option}
              key={option.title}
              itemLinkName={classes.actionMenuItem}
              linkClassName={classes.actionLink}
              iconClassName={`${classes.actionIcon} ${classes.actionIconWatch}`}
            />
          ))}
        </Select>
      </TableCell>
    </TableRow>
  );
};

export default UndeclaredArrivalTableRowComponent;
