import React, { useState } from 'react';
import {
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  Typography,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import ResetInfo from './ResetInfo';
import { email as emailSchema } from '../../utils/validationSchemes';
import recoveryPasswordQuery from '../../GraphQL/queries/recoveryPassword';
import useStyles from './styles';
import {
  RecoveryPasswordQuery,
  RecoveryPasswordQueryVariables,
} from '../../generated/graphql';
import { useApolloClient } from '@apollo/client';
import { TResetPasswordForm } from '../../interfaces';
import { useTranslation } from 'react-i18next';

export default function Reset() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const client = useApolloClient();
  const [email, setEmail] = useState('');

  const { t } = useTranslation();

  const handlerSubmit = (
    values: TResetPasswordForm,
    { setSubmitting }: FormikHelpers<TResetPasswordForm>,
  ) => {
    client
      .query<RecoveryPasswordQuery, RecoveryPasswordQueryVariables>({
        variables: {
          email: values.email,
        },
        query: recoveryPasswordQuery,
        fetchPolicy: 'network-only',
      })
      .then(() => {
        setEmail(values.email);
        enqueueSnackbar(t('app.emailSent'), {
          variant: 'success',
        });
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      })
      .finally(() => setSubmitting(false));
  };

  if (email) return <ResetInfo email={email} />;

  return (
    <div>
      <Typography className={classes.resetAuthTitle} variant='h2'>
        {t('app.passwordRecovery')}
      </Typography>
      <Divider className={classes.divider} />
      <Formik
        initialValues={{ email: '' } as TResetPasswordForm}
        onSubmit={handlerSubmit}
        validationSchema={emailSchema}
      >
        {({ isSubmitting, getFieldMeta, getFieldProps }) => (
          <Form noValidate autoComplete='off'>
            <Field name='email'>
              {() => (
                <FormControl
                  error={
                    !!(
                      getFieldMeta('email').touched &&
                      getFieldMeta('email').error
                    )
                  }
                  className={classes.formControl}
                >
                  <InputLabel shrink={false} htmlFor='input-email'>
                    {t('app.email')}
                  </InputLabel>
                  <Input
                    disableUnderline
                    fullWidth
                    required
                    id='input-email'
                    {...getFieldProps('email')}
                  />
                  {getFieldMeta('email').touched &&
                    getFieldMeta('email').error && (
                      <FormHelperText>
                        {getFieldMeta('email').error}
                      </FormHelperText>
                    )}
                </FormControl>
              )}
            </Field>
            <Button
              className={classes.resetButton}
              disabled={isSubmitting}
              type='submit'
              variant='contained'
            >
              {t('app.recover')}
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
}
