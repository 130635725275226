import { gql } from '@apollo/client';

export const QUERY_GENERATE_URL_AUTHORIZATION_EBAY = gql`
  query generateUrlForAuthorizationInEbay(
    $name: String!
    $url: String
    $storeId: Int
  ) {
    generateUrlForAuthorizationInEbay(name: $name, url: $url, storeId: $storeId)
  }
`;
