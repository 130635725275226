import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  buttonsForSelected: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    marginBottom: '24px',

    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  pagination: {
    marginTop: '32px',
    fontWeight: 'bold',
    '& .MuiPagination-ul': {
      [theme.breakpoints.down('xs')]: {
        flexWrap: 'nowrap',
        justifyContent: 'center',
      },
    },
    '& .MuiButtonBase-root': {
      width: 44,
      height: 44,
      borderRadius: '50%',
      padding: 0,
      margin: 0,
      marginRight: 8,

      [theme.breakpoints.down('xs')]: {
        margin: 0,
        width: 35,
        height: 35,
      },
    },
    '& .MuiPaginationItem-ellipsis': {
      width: 12,
      height: 44,
      padding: 0,
      margin: 0,
      display: 'flex',
      alignItems: 'center',
      minWidth: 'auto',
      marginRight: 8,
    },
    '& .MuiPaginationItem-icon': {
      fontSize: '25px',
    },
  },
  inputLabel: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  input: { width: '240px' },
  libraryIcon: { paddingRight: '5px' },
  downloadIcon: { cursor: 'pointer', width: '22px', height: '22px' },
  boxModalButtons: {
    margin: '30px 0 0',
    display: 'flex',
    justifyContent: 'space-around',
  },
  actionLink: { color: '#000' },
  typeSelect: { width: '240px', marginBottom: '24px' },
  datePicker: {
    padding: '4px',
    width: '100px',
    borderRedius: '3px',
    '& .MuiInputBase-input': { padding: '4px 8px' },
  },
}));
