import { gql } from '@apollo/client';

export const QUERY_PACKAGING_TEMPLATE_LIST = gql`
  query getPackagingTemplatesList($limit: Int, $offset: Int, $name: String) {
    getPackagingTemplatesList(limit: $limit, offset: $offset, name: $name) {
      count
      rows {
        id
        name
        weight
        length
        width
        height
        userId
      }
    }
  }
`;
