import React, { useState } from 'react';
import {
  Button,
  Divider,
  Link as MuiLink,
  Typography,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { Link, useHistory } from 'react-router-dom';
import { logout, TRoutes } from '../../utils/helpers';
import repeatConfirmLetterQuery from '../../GraphQL/queries/repeatConfirmLetter';
import useStyles from './styles';
import {
  RepeatConfirmLetterQuery,
  RepeatConfirmLetterQueryVariables,
  useGetCurrentUserQuery,
} from '../../generated/graphql';
import { useApolloClient } from '@apollo/client';
import { useTranslation } from 'react-i18next';

export default function Confirm() {
  const classes = useStyles();
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { error, data } = useGetCurrentUserQuery();
  const client = useApolloClient();

  const { t } = useTranslation();

  if (error) {
    enqueueSnackbar(error.message, { variant: 'error' });
  }

  const handleLogout = (e: React.MouseEvent) => {
    e.preventDefault();
    logout(history);
  };

  const handleRepeatLetter = async (e: React.MouseEvent) => {
    e.preventDefault();
    setLoading(true);

    client
      .query<RepeatConfirmLetterQuery, RepeatConfirmLetterQueryVariables>({
        query: repeatConfirmLetterQuery,
        fetchPolicy: 'network-only',
      })
      .then(() =>
        enqueueSnackbar(t('app.emailSentAgain'), {
          variant: 'success',
        }),
      )
      .catch((error) => enqueueSnackbar(error.message, { variant: 'error' }))
      .finally(() => setLoading(false));
  };

  return (
    <div>
      <Typography className={classes.authTitleConfirm} variant='h2'>
        {t('app.registrationCompletion')}
      </Typography>
      <Divider className={classes.divider} />
      <div className={classes.authInfo}>
        <p style={{ margin: 0 }}>
          {t('app.registrationThankYouLong')} {data?.currentUser?.email}.
        </p>
        <Button
          onClick={handleRepeatLetter}
          disabled={isLoading}
          className={classes.sendLetterButton}
          variant='outlined'
        >
          {t('app.resendConfirmationEmail')}
        </Button>
      </div>
      <MuiLink
        className={classes.returnToSignIn}
        component={Link}
        variant='body2'
        underline='always'
        onClick={handleLogout}
        to={TRoutes.AUTH_SIGN_IN}
      >
        {t('app.backToLogin')}
      </MuiLink>
    </div>
  );
}
