import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  CircularProgress,
} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import ExposureIcon from '@material-ui/icons/Exposure';
import { useFormikContext } from 'formik';
import {
  CreateWarehouseShipmentOrderInput,
  DeliveryOptionsResponse,
  DeliveryOptionsResponseObject,
  useGetWarehouseShipmentOrderDeliveryOptionsListLazyQuery,
} from '../../../generated/graphql';
import { TSetStateBoolean } from '../../../interfaces';
import useStyles from './styles';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { transformDeliveryOptionsBeforyQuery } from './shipmentFromWarehouseUtils/transformDeliveryOptionsBeforyQuery';

const ShipmentFromWarehouseFormStep5: React.FC<{
  setLoading: TSetStateBoolean;
  isLoading: boolean;
}> = ({ setLoading, isLoading }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { values, setFieldValue } =
    useFormikContext<CreateWarehouseShipmentOrderInput>();
  const { enqueueSnackbar } = useSnackbar();
  const [
    warehouseShipmentDeliveryOptions,
    setWarehouseShipmentDeliveryOptions,
  ] = useState<DeliveryOptionsResponseObject | null>(null);

  const [getWarehouseShipmentOrderDeliveryOptionsListLazyQuery, { loading }] =
    useGetWarehouseShipmentOrderDeliveryOptionsListLazyQuery({
      onError: (error) => {
        if (error) {
          enqueueSnackbar(error.message, { variant: 'error' });
        }
      },
      onCompleted: (data) => {
        if (data) {
          setWarehouseShipmentDeliveryOptions(
            data?.getWarehouseShipmentOrderDeliveryOptionsList as DeliveryOptionsResponseObject,
          );
        }
      },
      fetchPolicy: 'network-only',
    });

  useEffect(() => {
    handleGetWarehouseShipmentOrderDeliveryOptionsList();
    //eslint-disable-next-line
  }, [
    values.signature,
    values.additionalInsurance,
    values.insuranceAmount,
    values.wrap,
    values.message,
    values.giftWrap,
  ]);

  useEffect(() => {
    if (loading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [loading, setLoading]);

  const input = transformDeliveryOptionsBeforyQuery(values);

  const handleGetWarehouseShipmentOrderDeliveryOptionsList = () => {
    setFieldValue('typeDeliveryId', null);
    setFieldValue('carrierService', null);

    getWarehouseShipmentOrderDeliveryOptionsListLazyQuery({
      variables: {
        data: input,
      },
    });
  };

  const onSelectDeliveryVariant =
    (variant: DeliveryOptionsResponse) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const fieldsToUpdate = {
        typeDeliveryId: variant?.typeDelivery,
        carrierService: variant?.name,
        lastMileCost: variant?.lastMileCost,
        shipmentId: variant?.shipmentId,
        rateId: variant?.rateId,
        orderCostCurrency: variant?.priceCurrency,
        preliminaryCost: variant?.summary_amount,
        handlingFee: variant?.info?.warehouse_proccesing_cost,
      };
      Object.entries(fieldsToUpdate).forEach(([key, value]) => {
        setFieldValue(key, value);
      });
    };

  return (
    <>
      <div className={classes.wrapper}>
        {warehouseShipmentDeliveryOptions && (
          <TableContainer>
            <Table>
              <TableHead>
                <TableCell className={classes.tableCell}>
                  {t('app.shippingOption')}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {t('app.deliveryTime')}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {t('app.cost')}
                </TableCell>
              </TableHead>
              <TableBody>
                {warehouseShipmentDeliveryOptions?.variants?.map(
                  (variant, index) =>
                    variant && (
                      <TableRow>
                        <TableCell className={classes.tableCell}>
                          <label className={classes.labelRow}>
                            {!variant?.days_to_delivery &&
                            !variant?.summary_amount ? (
                              <Tooltip
                                title={t('app.deliveryInformationMissing')}
                              >
                                <ErrorIcon className={classes.errorIcon} />
                              </Tooltip>
                            ) : (
                              <Radio
                                className={classes.radio}
                                value={variant?.typeDelivery}
                                disabled={isLoading}
                                checked={
                                  //@ts-ignore
                                  values?.carrierService === variant?.name
                                }
                                color='primary'
                                onChange={onSelectDeliveryVariant(variant)}
                              />
                            )}
                            <span className={classes.labelRowText}>
                              {variant?.name}
                            </span>
                          </label>
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {variant?.days_to_delivery
                            ? `${variant?.days_to_delivery} ${t('app.days2')}`
                            : t('app.unknown')}
                        </TableCell>
                        <TableCell
                          className={clsx(
                            classes.tableCell,
                            classes.itemResult,
                          )}
                        >
                          {variant?.summary_amount
                            ? `${variant?.summary_amount?.toFixed(2)} ${
                                variant?.priceCurrency
                              }`
                            : t('app.unknown')}
                        </TableCell>
                      </TableRow>
                    ),
                )}
                {loading ? (
                  <div className={classes.circularProgress}>
                    <CircularProgress size={40} />
                  </div>
                ) : null}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <Box mt={6} mb={6}>
          <Divider />
        </Box>

        <Box
          display={'flex'}
          justifyContent={'flex-start'}
          alignItems={'center'}
        >
          <Button
            className={classes.calculateButton}
            variant='contained'
            disabled={loading}
            onClick={handleGetWarehouseShipmentOrderDeliveryOptionsList}
            startIcon={
              loading ? <CircularProgress size={22} /> : <ExposureIcon />
            }
          >
            {t('app.recalculateCost')}
          </Button>
        </Box>
      </div>
    </>
  );
};

export default ShipmentFromWarehouseFormStep5;
