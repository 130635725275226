import React from 'react';
import { INotificationsIcon } from './types';
import { Box, IconButton } from '@material-ui/core';
import useStyles from './styles';
import NotificationsIcon from '@material-ui/icons/Notifications';

const NotificationsIconComponent = ({ onClick, count }: INotificationsIcon) => {
  const classes = useStyles();
  return (
    <Box className={classes.iconWrapper} onClick={onClick}>
      <IconButton className={classes.personIcon}>
        <NotificationsIcon />
      </IconButton>
      {count > 0 ? <Box className={classes.dot}>{count}</Box> : null}
    </Box>
  );
};

export default NotificationsIconComponent;
