import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../../../utils/constants';

export default makeStyles((theme) => ({
  wrapper: {
    maxWidth: '659px',
    minWidth: '400px',
    position: 'absolute',
    top: '46px',
    right: 0,
    padding: '20px',
    border: `1px solid ${COLORS.BLACK}`,
    borderRadius: '3px',
    color: COLORS.DARK_GREY,
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '100%',
    backgroundColor: COLORS.WHITE,
  },
  title: {
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: '100%',
  },
  titleDate: {
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '100%',
  },
  titleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
  },
  button: {
    border: 'none',
    padding: 0,
    minWidth: 'auto',
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '100%',
  },
  notificationWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    gap: '20px',
    marginBottom: '20px',
  },
  notificatioTitle: {
    color: COLORS.BLUE,
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '100%',
  },
  notificationMessage: {
    color: COLORS.BLUE,
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '100%',
    whiteSpace: 'pre-wrap',
  },
  closeIcon: {
    cursor: 'pointer',
    width: '20px',
  },
  link: {
    marginTop: '20px',
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '100%',
  },
}));
