import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  button: {
    position: 'absolute',
    top: 0,
    right: 0,

    [theme.breakpoints.down('xs')]: {
      position: 'relative',
      margin: '0 0 10px',
    },
  },
  headerTitle: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: 15,
    },
  },
  table: {
    minWidth: 1048,

    '& tr:first-child td': {
      paddingTop: 22,
    },
  },
  cellHead: {
    textAlign: 'center',
    borderBottom: '1px solid #e0e0e0',
  },
  cellCompany: {
    width: 154,
    paddingLeft: 9,
  },
  cell: {
    textAlign: 'center',
    paddingBottom: 16,
  },
  cellAction: {
    height: 16,
    padding: 0,
    marginRight: 10,

    '& .MuiSvgIcon-root': {
      height: 16,
      fontSize: '16px',
      lineHeight: 1,
    },
  },
}));
