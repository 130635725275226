import { gql } from '@apollo/client';

export const DELIVERY_COUNTRY = gql`
  fragment FragmentDeliveryCountry on DeliveryCountry {
    id
    order
    name
    daysToDelivery
    zoneId
    nameEng
    iso
    phoneCode
    phoneMin
    phoneMax
    customsInfo
  }
`;
