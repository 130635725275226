import { Box, FormControl, MenuItem, Select } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';
import { filterSelectDepartureDateOptions } from './options';
import { BoxCentered } from '../../../BoxCentered/BoxCentered';
import { IFilters } from '../../../../utils/types';
import EventIcon from '@material-ui/icons/Event';

interface IFilterSelectDashboardDepartureDate {
  value?: string | number | null;
  setFilter: React.Dispatch<React.SetStateAction<IFilters>>;
}

const FilterSelectDashboardDepartureDate = ({
  value,
  setFilter,
}: IFilterSelectDashboardDepartureDate) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const onChangeHandler = (
    e: React.ChangeEvent<{
      name?: string;
      value: unknown;
    }>,
  ) => {
    setFilter((state) => ({
      ...state,
      dateFilter: Number(e.target.value),
    }));
  };

  return (
    <Box className={classes.wrapper}>
      <EventIcon className={classes.icon} />
      <FormControl className={classes.filtersItemSelect}>
        <BoxCentered pl={2}>
          <Select
            displayEmpty
            disableUnderline
            value={value}
            onChange={onChangeHandler}
          >
            {filterSelectDepartureDateOptions.map((option) => (
              <MenuItem
                value={option.value}
                disabled={!option.value && option.value !== 0}
              >
                {option.nDays
                  ? t(option.title, { value: option.nDays })
                  : t(option.title)}
              </MenuItem>
            ))}
          </Select>
        </BoxCentered>
      </FormControl>
    </Box>
  );
};

export default FilterSelectDashboardDepartureDate;
