import { makeStyles, createStyles } from '@material-ui/core';

export const styles = () =>
  createStyles({
    mainTitle: {
      textAlign: 'center',
      margin: '15px 0px',
    },
    subTitleWrapper: {
      display: 'inline-block',
      borderBottom: '2px solid red',
    },
    balanceInfo: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '20px',
    },
    buttonWrapper: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    divider: {
      flexGrow: 2,
      borderBottom: '1px solid grey',
    },
    replenish: {
      backgroundColor: '#4ec7af',
    },
    payWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    payInput: {
      width: '50%',
    },
    pay: {
      backgroundColor: '#007dae',
    },
    imagePayment: {
      padding: '10px',
      cursor: 'pointer',
      '&:hover': {
        boxShadow: '3px 3px 2px rgba(60, 60, 60, .2)',
      },
    },
    changePaymentButton: {
      width: '50%',
      borderRadius: '3px',
      border: '1px solid #232323',
      backgroundColor: '#ebebeb',
      padding: '0px 10px',
    },
  });

export default makeStyles(styles);
