import React, { useState } from 'react';
import ModalBox from '../../components/ModalBox/ModalBox';
import { Button, Grid, MenuItem, Select, Typography } from '@material-ui/core';
import RouterLink from '../../components/RouterLink/RouterLink';
import { TRoutes } from '../../utils/helpers';
import {
  FastField,
  FieldProps,
  Form,
  Formik,
  FormikHelpers,
  FormikProps,
} from 'formik';
import { BoxCentered } from '../../components/BoxCentered/BoxCentered';
import FieldWeightGross from '../../components/Packages/FieldWeightGross/FieldWeightGross';
import FieldLengthCm from '../../components/Packages/FieldLengthCm/FieldLengthCm';
import FieldWidthCm from '../../components/Packages/FieldWidthCm/FieldWidthCm';
import FieldHeightCm from '../../components/Packages/FieldHeightCm/FieldHeightCm';
import { IMarketplaceOrder, TSetStateBoolean } from '../../interfaces';
import { PackagingTemplate } from '../../generated/graphql';
import { schemaAddPackageToMarketplaceOrder } from '../../utils/validationSchemes';
import { useTranslation } from 'react-i18next';

interface IModalBoxAddPackageToOrderFormik {
  packageId: number;
  weight: number;
  length: number;
  width: number;
  height: number;
}

const ModalBoxAddPackageToOrder: React.FC<{
  isOpen: boolean;
  setIsOpen: TSetStateBoolean;
  order: IMarketplaceOrder | undefined;
  setOrders: React.Dispatch<React.SetStateAction<IMarketplaceOrder[]>>;
  cachedPackages: PackagingTemplate[];
  dataCountries: any;
  setOrderAdditionalData: any;
}> = ({
  isOpen,
  setIsOpen,
  order,
  setOrders,
  cachedPackages,
  dataCountries,
  setOrderAdditionalData,
}) => {
  const [updateView, setUpdateView] = useState(0);

  const { t } = useTranslation();

  const handleSubmit = (
    values: IModalBoxAddPackageToOrderFormik,
    { setSubmitting }: FormikHelpers<IModalBoxAddPackageToOrderFormik>,
  ) => {
    const newData = {
      weight: values.weight,
      length: values.height,
      width: values.width,
      height: values.length,
    };

    setOrderAdditionalData(newData);

    setSubmitting(false);
    setIsOpen(false);
  };

  return (
    <ModalBox isOpen={isOpen} setOpen={setIsOpen}>
      <Typography align='center'>
        {t('app.makeChoice')} &nbsp;
        <RouterLink to={TRoutes.SETTINGS_MY_PACKAGES}>
          {t('app.makeChoice2')}
        </RouterLink>
        &nbsp; {t('app.makeChoice3')}
      </Typography>

      <Formik
        initialValues={{
          packageId: 0,
          weight: 0,
          length: 0,
          width: 0,
          height: 0,
        }}
        onSubmit={handleSubmit}
        validationSchema={schemaAddPackageToMarketplaceOrder}
      >
        {({ setFieldValue }: FormikProps<IModalBoxAddPackageToOrderFormik>) => {
          return (
            <Form>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <BoxCentered flexDirection='column' pt={2} pb={1}>
                    {t('app.myPackaging')}:
                    {!cachedPackages?.length ? (
                      <BoxCentered>
                        {t('app.noItems')} &nbsp;
                        <RouterLink to={TRoutes.SETTINGS_MY_PACKAGES}>
                          {t('app.myPackaging').toUpperCase()}
                        </RouterLink>
                        .
                      </BoxCentered>
                    ) : (
                      <FastField name='packageId'>
                        {({ field: { value } }: FieldProps) => {
                          return (
                            <Select
                              value={value}
                              disableUnderline
                              onChange={(event) => {
                                const packageId = event.target.value as number;
                                if (packageId) {
                                  setFieldValue('packageId', packageId);

                                  const selectedPackage = cachedPackages?.find(
                                    (cp) => {
                                      return cp?.id && +cp?.id === +packageId;
                                    },
                                  );
                                  setFieldValue(
                                    'weight',
                                    selectedPackage?.weight,
                                  );
                                  setFieldValue(
                                    'length',
                                    selectedPackage?.length,
                                  );
                                  setFieldValue(
                                    'height',
                                    selectedPackage?.height,
                                  );
                                  setFieldValue(
                                    'width',
                                    selectedPackage?.width,
                                  );
                                  setUpdateView(updateView + 1);
                                }
                              }}
                            >
                              {cachedPackages?.map((p) => {
                                return (
                                  p?.id && (
                                    <MenuItem key={p.id} value={p.id}>
                                      {p?.name} {p?.weight} {t('app.kg')}{' '}
                                      {p?.length}x{p?.width}x{p?.height}
                                    </MenuItem>
                                  )
                                );
                              })}
                            </Select>
                          );
                        }}
                      </FastField>
                    )}
                  </BoxCentered>
                </Grid>

                <Grid item xs={12} sm={3}>
                  <FieldWeightGross
                    key={updateView}
                    handlerChange={(e) =>
                      setFieldValue('weight', e.target.value)
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={3}>
                  <FieldLengthCm
                    key={updateView}
                    handlerChange={(e) =>
                      setFieldValue('length', e.target.value)
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={3}>
                  <FieldWidthCm
                    key={updateView}
                    handlerChange={(e) =>
                      setFieldValue('width', e.target.value)
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={3}>
                  <FieldHeightCm
                    key={updateView}
                    handlerChange={(e) =>
                      setFieldValue('height', e.target.value)
                    }
                  />
                </Grid>
              </Grid>

              <BoxCentered mt={2}>
                <Button
                  style={{ marginRight: 5 }}
                  variant='contained'
                  type='submit'
                >
                  {t('app.choose')}
                </Button>

                <Button variant='contained' onClick={() => setIsOpen(false)}>
                  {t('app.cancel')}
                </Button>
              </BoxCentered>
            </Form>
          );
        }}
      </Formik>
    </ModalBox>
  );
};

export default ModalBoxAddPackageToOrder;
