import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../../utils/constants';

export default makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '40px',
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '100%',
    cursor: 'pointer',
  },
  iconWrapper: {
    position: 'relative',
  },
  dot: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '3px',
    right: '-3px',
    top: '-2px',
    width: 'auto',
    height: '14px',
    border: `2px solid ${COLORS.WHITE}`,
    borderRadius: '14px',
    backgroundColor: COLORS.RED,
    fontSize: '10px',
    fontWeight: 500,
  },
  personIcon: {
    width: 24,
    height: 24,
    margin: '0 5px',
    padding: 0,
    color: COLORS.BLACK,
  },
}));
