import { FastField, FieldProps } from 'formik';
import {
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import React from 'react';
import { TFormikSetFieldValueDebounce } from '../../../interfaces';
import { useTranslation } from 'react-i18next';

const VatKey: React.FC<{
  fieldName?: string;
  unitIndex?: number;
  types: any;
  setFieldValue: TFormikSetFieldValueDebounce;
}> = ({ fieldName, unitIndex, types, setFieldValue }) => {
  const theme = useTheme();
  const hidden = useMediaQuery(theme.breakpoints.down('md'));

  const { t } = useTranslation();

  return (
    <FastField name={fieldName}>
      {({ field: { value, ...field }, meta }: FieldProps) => (
        <FormControl error={!!(meta.touched && meta.error && value !== 0)}>
          <Grid container justify='space-between'>
            <InputLabel htmlFor='input-vat-key'>
              {t('app.taxNumber')}
            </InputLabel>
            {hidden ? null : (
              <Tooltip
                style={{ padding: 0 }}
                title={t('app.specifyIOSSNumber')}
              >
                <IconButton>
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            )}
          </Grid>
          <Select
            id='input-vat-key'
            value={value}
            onChange={(e) =>
              setFieldValue(fieldName || 'vatKey', e.target.value)
            }
          >
            {Object.entries(types)?.map(([item]) => (
              <MenuItem key={item} value={item}>
                {item === 'EMPTY' ? t('app.no') : item}
              </MenuItem>
            ))}
          </Select>
          {meta.touched && meta.error && value !== 0 && (
            <FormHelperText>{meta.error}</FormHelperText>
          )}
        </FormControl>
      )}
    </FastField>
  );
};

export default VatKey;
