import { gql } from '@apollo/client';
import { DELIVERY_COUNTRY } from '../fragments/deliveryCountryFragment';

export default gql`
  ${DELIVERY_COUNTRY}
  query getContacts {
    contacts {
      id
      name
      company
      country {
        ...FragmentDeliveryCountry
      }
      address
      address2
      address3
      zipCode
      city
      city
      state
      phone
      email
      remark
      eoriCode
      type
      innKppCode
      contactName
    }
  }
`;
