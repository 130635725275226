import React from 'react';
import { FastField, FieldProps } from 'formik';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  Grid,
  Checkbox,
} from '@material-ui/core';

type FastFieldCustomType = {
  setFieldValue: (field: string, value: number | string | boolean) => void;
  name: string;
  label: string;
  maskType?: string;
};

export const FastFieldCustomCheckbox = ({
  setFieldValue,
  name,
  label,
}: FastFieldCustomType) => {
  return (
    <FastField name={name}>
      {({ field: { value, ...field }, meta }: FieldProps) => {
        return (
          <FormControl error={meta.touched && !!meta.error}>
            <Grid
              container
              item
              md={12}
              style={{
                display: 'flex',
                alignItems: 'center',
                marginLeft: '-10px',
              }}
            >
              <Checkbox
                onChange={(e) => {
                  setFieldValue(name, e?.target?.checked);
                }}
                defaultChecked={value}
              />
              <InputLabel style={{ marginBottom: '0px', maxWidth: '450px' }}>
                {label}
              </InputLabel>
            </Grid>

            {meta.touched && meta.error && (
              <FormHelperText>{meta.error}</FormHelperText>
            )}
          </FormControl>
        );
      }}
    </FastField>
  );
};
