import { t } from 'i18next';
import { Vatkey } from '../../../../generated/graphql';
import { LOCAL_STORAGE_KEYS } from '../../../../utils/constants';
import { TRoutes } from '../../../../utils/helpers';
import { createWarehouseShipmentOrderType } from './types';

export const createWarehouseShipmentOrderWithoutBuyLabel = ({
  createWarehouseShipmentOrderWithoutBuyLabelMutation,
  values,
  enqueueSnackbar,
  history,
  orderByIdData,
}: createWarehouseShipmentOrderType) => {
  let input = {
    warehouseId: values.warehouseId,
    products: values.products,
    carrierCode: values.carrierService,
    trackNumber: values.trackNumber,
    wrap: values.wrap,
    ...(values.message && { message: values.message }),
    ...(values.comment && { comment: values.comment }),
    ...(values.vatKey !== Vatkey.Empty && { vatKey: values.vatKey }),
    ...(values.vatValue && { vatValue: values.vatValue }),
    senderMark: values.senderMark,
    orderCostCurrency: values.orderCostCurrency,
    handlingFee: values.handlingFee,
    currency: values?.currency?.toUpperCase(),
    marketplaceInvoiceToken: values.marketplaceInvoiceToken,
    ...(orderByIdData && {
      marketPlaceOrderId: orderByIdData?.getOrderById?.id,
    }),
    giftWrap: values.giftWrap,
  };

  createWarehouseShipmentOrderWithoutBuyLabelMutation({
    variables: {
      data: input,
      fileLabel: values.fileLabel,
    },
    onCompleted: (data) => {
      if (data) {
        enqueueSnackbar(
          t('app.shipmentFromWarehouseCreated', {
            value:
              data?.createWarehouseShipmentOrderWithoutBuyLabel?.barcodeId?.substring(
                0,
                data?.createWarehouseShipmentOrderWithoutBuyLabel?.barcodeId
                  ?.length - 4,
              ),
          }),
          {
            variant: 'success',
          },
        );
        localStorage.setItem(
          LOCAL_STORAGE_KEYS.WAREHOUSE_ID_ORDER,
          data?.createWarehouseShipmentOrderWithoutBuyLabel?.sender?.id?.toString() as string,
        );
        localStorage.setItem(LOCAL_STORAGE_KEYS.TAB_VALUE, String(1));
        history.push(TRoutes.SHIPMENTS_FROM_WAREHOUSE);
      }
    },
    onError: (error) => {
      if (error) {
        enqueueSnackbar(error.message, {
          variant: 'error',
        });
      }
    },
  });
};
