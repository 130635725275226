import { Pagination } from '@material-ui/lab';
import React from 'react';
import useStyles from './styles';
import { Maybe } from 'graphql/jsutils/Maybe';
import { IFilters } from '../../utils/types';

interface IPaginationComponent {
  count: Maybe<number> | undefined;
  limit: number;
  currentPage: number;
  setFilter: React.Dispatch<React.SetStateAction<IFilters>>;
}

const PaginationComponent = ({
  count,
  limit,
  currentPage,
  setFilter,
}: IPaginationComponent) => {
  const classes = useStyles();

  const paginationCount = count ? Math.ceil(count / limit) : 1;

  const handleChangePage = (_: React.ChangeEvent<unknown>, page: number) => {
    setFilter((state) => ({
      ...state,
      page: page,
    }));
  };
  return (
    <Pagination
      className={classes.pagination}
      count={paginationCount}
      page={currentPage}
      onChange={handleChangePage}
      color='primary'
    />
  );
};
export default PaginationComponent;
