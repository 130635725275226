import { useTranslation } from 'react-i18next';

export const useChangeLanguage = () => {
  const { i18n, t } = useTranslation();
  const currentLanguage = i18n.language;

  const changeLang = (
    e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
  ) => {
    const lang = e.target.value as string;
    return i18n.changeLanguage(lang);
  };
  return { changeLang, currentLanguage, t };
};
