export const productsHeadCells = [
  { label: 'app.number', name: 'number' },
  { label: 'app.id', name: 'id' },
  { label: 'app.sku', name: 'productSku' },
  { label: 'app.nomination', name: 'productName' },
  { label: 'app.quantity', name: 'quantity' },
];

export const cargoPlacesHeadCells = [
  { label: 'app.number', name: 'number' },
  { label: 'app.type', name: 'type' },
  { label: 'app.numberOfSku', name: 'uniqSkuCount' },
  { label: 'app.step_value', name: 'productQty' },
  { label: 'app.dimensions', name: 'dimensions' },
  { label: 'app.weight', name: 'weight' },
  { label: 'app.barcode', name: 'barcode' },
  { label: 'app.сomposition', name: 'viewCargoPlace' },
];
