import React, { useState } from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';

import FormContact from '../../../components/FormContact/FormContact';
import {
  CreateWarehouseShipmentOrderInput,
  GetContactsQuery,
  Warehouse,
} from '../../../generated/graphql';
import { TSetStateNumber, TSetStateString } from '../../../interfaces';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import { transformRecieverBeforeCreate } from './shipmentFromWarehouseUtils/transformRecieverBeforeCreate';
import { useFormikContext } from 'formik';

const ShipmentFromWarehouseFormStep1: React.FC<{
  warehouseList: Warehouse[] | null;
  contactsData?: GetContactsQuery;
  updateView: number;
  setUpdateView: TSetStateNumber;
  setWarehouseId: TSetStateString;
  isWithLabel?: boolean;
}> = ({
  warehouseList,
  contactsData,
  updateView,
  setUpdateView,
  setWarehouseId,
  isWithLabel,
}) => {
  const classes = useStyles();
  const [warehouse, setWarehouse] = useState<Warehouse | null>(null);
  const { t } = useTranslation();
  const { values, setFieldValue } =
    useFormikContext<CreateWarehouseShipmentOrderInput>();

  const contactsDataRecipients = contactsData?.contacts?.filter(
    /* prettier-ignore */
    //@ts-ignore
    (contact) => contact?.type === "RECIPIENT",
  );

  const handleWarehouseChange = (
    e: React.ChangeEvent<{
      name?: string;
      value: unknown;
    }>,
  ) => {
    const warehouse = e.target.value as Warehouse;
    setWarehouse(e.target.value as Warehouse);
    setFieldValue('warehouseId', warehouse?.id);
    setWarehouseId(String(warehouse.id));
    if (isWithLabel) {
      setFieldValue('warehouseCountryIso', warehouse.country?.iso);
    }
  };

  return (
    <div className={classes.wrapper}>
      <Typography className={classes.contactFieldsTitle} variant='h3'>
        {t('app.from')}
      </Typography>
      <FormControl style={{ marginBottom: '24px' }} variant='standard'>
        <InputLabel shrink={false} htmlFor='warehouse'>
          {t('app.selectWarehouse')}
        </InputLabel>
        <Select
          className={classes.selectContacts}
          id='warehouse'
          value={
            warehouse
              ? warehouse
              : warehouseList?.find(
                  (warehouse) => warehouse.id === values.warehouseId,
                )
          }
          onChange={handleWarehouseChange}
        >
          <MenuItem
            value={t('app.chooseWarehouse')}
            disabled
            style={{ display: 'none' }}
          >
            {t('app.chooseWarehouse')}
          </MenuItem>
          {warehouseList?.map((item: any) => (
            <MenuItem key={item.code} value={item}>
              {item.code}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {isWithLabel ? (
        <>
          <Typography className={classes.contactFieldsTitle} variant='h3'>
            {t('app.to')}
          </Typography>
          <FormControl>
            <InputLabel shrink={false} htmlFor='eori-input'>
              {t('app.chooseFromAddressBook')}
            </InputLabel>
            <Select
              className={classes.selectContacts}
              disableUnderline
              value={values?.receiver?.id || ''}
              onChange={(e) => {
                const contact = contactsDataRecipients?.find(
                  (item) => item && item.id === e.target.value,
                );
                if (contact) {
                  const receiver = transformRecieverBeforeCreate(contact);
                  setFieldValue('receiver', receiver);
                  setUpdateView((updateView) => ++updateView);
                }
              }}
              displayEmpty
            >
              {contactsDataRecipients?.map((contact) =>
                contact ? (
                  <MenuItem key={contact.id} value={contact.id}>
                    {contact.contactName}
                  </MenuItem>
                ) : null,
              )}
            </Select>
          </FormControl>
          <div className={classes.contactFields}>
            <Typography className={classes.contactFieldsTitle} variant='h3'>
              {t('app.recipient')}
            </Typography>
            <FormContact
              nameShape='receiver'
              isRestrict
              key={updateView}
              outerSetFieldValue={setFieldValue}
              //@ts-ignore
              outerSelectedCountry={values?.receiver?.country}
              isPersonal={false}
            />
          </div>
        </>
      ) : null}
    </div>
  );
};

export default ShipmentFromWarehouseFormStep1;
