import React from 'react';
import { Box, Divider, Typography } from '@material-ui/core';

const LegalInformation: React.FC = () => {
  return (
    <div>
      <Box m={'15px 0'}>
        <Typography variant='h2' align='center'>
          ЮРИДИЧЕСКАЯ ИНФОРМАЦИЯ
        </Typography>
      </Box>
      <Divider />

      <Box m={'15px 0'}>
        <a href={'https://picknpack.pro/requisites/'}>Реквизиты компании</a>
        <br />
        <a href={'https://picknpack.pro/terms-of-use/'}>
          Пользовательское соглашение
        </a>
      </Box>
    </div>
  );
};

export default LegalInformation;
