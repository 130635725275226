import { Box, Typography } from '@material-ui/core';
import React from 'react';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { OrderStatusesStatictic } from '../../../../generated/graphql';
import { getDoghuntData } from '../../../../utils/helpers';
import { options } from './options';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

interface IDoghuntComponent {
  orderStatuses: OrderStatusesStatictic[];
}

const DoghuntComponent = ({ orderStatuses }: IDoghuntComponent) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const data = getDoghuntData(t, orderStatuses);

  return (
    <Box className={classes.doghuntWrapper}>
      <Typography className={classes.doghuntTitle}>
        {t('app.trackingStatus')}
      </Typography>
      <Doughnut data={data} options={options} />
    </Box>
  );
};

export default DoghuntComponent;
