import React from 'react';
import { Divider, Link as MuiLink, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import useStyles from './styles';
import { TRoutes } from '../../utils/helpers';
import { useTranslation } from 'react-i18next';

const ResetInfo: React.FC<{ email: string }> = ({ email }) => {
  const classes = useStyles();

  const { t } = useTranslation();

  return (
    <div>
      <Typography className={classes.authTitleConfirm} variant='h2'>
        {t('app.passwordRecovery')}
      </Typography>
      <Divider className={classes.divider} />
      <div className={classes.authInfo}>
        <p className={classes.authAlert}>{t('app.passwordRecoverySent')}</p>
        <Divider className={classes.divider} />
        <p style={{ margin: 0 }}>
          {t('app.passwordRecoveryDetails')}
          <MuiLink
            className={classes.email}
            variant='body2'
            href={`mailto:${email}`}
          >
            {email}
          </MuiLink>
          .
        </p>
      </div>
      <MuiLink
        className={classes.returnToSignIn}
        component={Link}
        variant='body2'
        underline='always'
        to={TRoutes.AUTH_SIGN_IN}
      >
        {t('app.backToLogin')}
      </MuiLink>
    </div>
  );
};

export default ResetInfo;
