import { Warehouse_Shipment_Order_Type } from '../../../generated/graphql';

export const tabsOptions = [
  {
    type: Warehouse_Shipment_Order_Type.WithBuyLabel,
    label: 'app.withBuyLabel',
  },
  {
    type: Warehouse_Shipment_Order_Type.WithoutBuyLabel,
    label: 'app.withoutBuyLabel',
  },
];
