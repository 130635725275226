import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  boxModalButtons: {
    margin: '30px 0 0',
    display: 'flex',
    justifyContent: 'space-around',
  },
  circularProgress: {
    marginRight: '14px',
  },
}));
