import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  wrapper: {
    width: "100%",
    border: "1px solid #e0e0e0",
    padding: "24px 48px",
    paddingBottom: "8px",

    [theme.breakpoints.down("md")]: {
      padding: "12px 24px",
    },
  },
}));
