import React from 'react';
import { TRoutes } from '../../../utils/helpers';
import SelectNextLink from '../../../components/SelectNextLink/SelectNextLink';
import ShipmentFromWarehouseIcon from '../../../assets/img/shipment/shipment_from_warehouse.png';
import B2bSendIcon from '../../../assets/img/shipment/shipment_b2b_create.png';
import { t } from 'i18next';

export default () => {
  const links = [
    {
      id: 1,
      route: TRoutes.SHIPMENT_FROM_WAREHOUSE_CREATE_WITH_LABEL,
      icon: ShipmentFromWarehouseIcon,
      text: t('app.createWarehouseShipmentWithLabel'),
      disabled: false,
      external: false,
    },
    {
      id: 2,
      route: TRoutes.SHIPMENT_FROM_WAREHOUSE_CREATE_WITHOUT_LABEL,
      icon: ShipmentFromWarehouseIcon,
      text: t('app.createWarehouseShipmentWithoutLabel'),
      disabled: false,
      external: false,
    },
    {
      id: 2,
      route: TRoutes.B2B_ORDER_SEND,
      icon: B2bSendIcon,
      text: t('app.b2bSend'),
      disabled: false,
      external: false,
    },
  ];

  return <SelectNextLink title={t('app.createNewShipment')} links={links} />;
};
