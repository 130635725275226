import { gql } from '@apollo/client';

export const QUERY_ORDERS = gql`
  query getOrders(
    $marketplace: String
    $period: Int
    $paymentStatus: Boolean
    $shippedStatus: Boolean
    $limit: Int
    $offset: Int
    $search: String
  ) {
    getOrders(
      marketplace: $marketplace
      period: $period
      paymentStatus: $paymentStatus
      shippedStatus: $shippedStatus
      limit: $limit
      offset: $offset
      search: $search
    ) {
      count
      rows {
        id
        marketplace
        orderId
        createdAt
        isPaid
        userId
        country
        addressFirstLine
        addressSecondLine
        zip
        city
        state
        email
        deliveryType
        deliveryPrice
        isShipped
        orderProducts {
          id
          title
          description
          quantity
          priceAmount
          priceCurrency
          shippingCostAmount
          shippingCostCurrency
          sku
          length
          height
          weight
          width
        }
        package {
          id
          name
          weight
          length
          width
          height
          userId
        }
        itemId
        receiverName
        storeId
        phone
        shippingMethod
      }
    }
  }
`;
