import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  actionMenuItem: {
    padding: 0,
  },
  actionIconWatch: {
    color: 'green',
  },
  actionLink: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    textDecoration: 'none',
    padding: '6px 16px',
  },
  actionIcon: {
    paddingRight: '5px',
    fontSize: '20px',
  },
  btnIcon: {
    float: 'right',
    marginLeft: 5,
    lineHeight: '30px',
  },
  actionIconCancel: {
    color: 'red',
  },

  productTitle: {
    fontSize: '14px',
    lineHeight: '20px',
  },
}));
