import { FormControl, MenuItem, Select } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useStyles from '../styles';
import { BoxCentered } from '../../BoxCentered/BoxCentered';
import { filterSelectDepartureDateOptions } from './options';
import { IFilters } from '../../../utils/types';
import { DEPARTURE_DATE_FILTER } from '../../../utils/helpers';

interface IFilterSelectDepartureDate {
  value?: string | number | null;
  setFilter: React.Dispatch<React.SetStateAction<IFilters>>;
}

const FilterSelectDepartureDate = ({
  value,
  setFilter,
}: IFilterSelectDepartureDate) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <FormControl className={classes.filtersItemSelect}>
      <BoxCentered pl={2}>
        <Select
          displayEmpty
          disableUnderline
          value={value}
          onChange={(e) => {
            const dateValue =
              e.target.value === DEPARTURE_DATE_FILTER.ALL_TIME
                ? DEPARTURE_DATE_FILTER.ALL_TIME
                : Number(e.target.value);
            setFilter((state) => ({
              ...state,
              dateFilter: dateValue,
            }));
          }}
        >
          {filterSelectDepartureDateOptions.map((option) => (
            <MenuItem value={option.value} disabled={!option.value}>
              {option.nDays
                ? t(option.title, { value: option.nDays })
                : t(option.title)}
            </MenuItem>
          ))}
        </Select>
      </BoxCentered>
    </FormControl>
  );
};

export default FilterSelectDepartureDate;
