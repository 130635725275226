/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import { COUNTRIES_CIS } from '../utils/constants';
import { LOCAL_STORAGE_KEYS } from '../utils/constants';
import {
  useGetDeliveryCountriesQuery,
  DeliveryCountry,
} from '../generated/graphql';

type Props = {
  children: React.ReactNode;
};
export const AppFormProvider = ({ children }: Props) => {
  const { allCountries, sngCountry } = useCounters();

  const { formValue, handleSetFormContext } = useForm();

  return (
    <AppFormContext.Provider
      value={{
        allCountries,
        formValue,
        handleSetFormContext,
        sngCountry: sngCountry,
      }}
    >
      {children}
    </AppFormContext.Provider>
  );
};

type ContextProps = {
  allCountries: Array<DeliveryCountry>;
  formValue?: any;
  handleSetFormContext: any;
  sngCountry: any;
};

export const AppFormContext = React.createContext<ContextProps>({
  allCountries: [],
  formValue: {},
  handleSetFormContext: () => {},
  sngCountry: [],
});

export const useAppFormContext = () => {
  const context = React.useContext(AppFormContext);
  if (!context) {
    throw new Error('useAppFormContext must be used within a AppFormProvider');
  }
  return context;
};

const useForm = () => {
  const [formValue, setFormValue] = useState<any>({
    fuelSurcharge: undefined,
    dollarRate: null,
  });

  const handleSetFormContext = (key: string, value: any) => {
    setFormValue((prevfilter: any) => ({
      ...prevfilter,
      [key]: value,
    }));
  };
  return { formValue, handleSetFormContext };
};

const useCounters = () => {
  const [allCountries, setAllCountries] = React.useState<any[any]>([]);

  const [sngCountry, setSngCountry] = useState(COUNTRIES_CIS);
  const { data: dataDeliveryCountries } = useGetDeliveryCountriesQuery({
    variables: {},
  });
  useEffect(() => {
    const allCountries = dataDeliveryCountries?.deliveryCountries?.length
      ? [...dataDeliveryCountries?.deliveryCountries].sort((a, b) => {
          return a && b && a.name < b.name
            ? -1
            : a && b && a.name > b.name
            ? 1
            : 0;
        })
      : () => {
          return (
            JSON.parse(
              localStorage.getItem(
                LOCAL_STORAGE_KEYS.DELIVERY_COUNTRIES,
              ) as string,
            ) || []
          );
        };

    setAllCountries(allCountries);
  }, [dataDeliveryCountries]);

  useEffect(() => {
    if (!allCountries) return;
    let arr: any = [];
    allCountries?.map((e: any) => {
      COUNTRIES_CIS.find((item): void => {
        if (item.iso === e.iso) {
          arr.push(e);
        }
      });
    });

    setSngCountry(arr);
  }, [allCountries]);

  return {
    allCountries,
    sngCountry,
  };
};
