import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const styles = (theme: Theme) =>
  createStyles({
    root: {
      fontSize: '40px',
      display: 'flex',
      flexDirection: 'column',
      textTransform: 'uppercase',
      textAlign: 'center',
    },
  });

export default makeStyles(styles);
