import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  actionMenuItem: {
    padding: 0,
  },
  actionLink: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    textDecoration: 'none',
    padding: '6px 16px',
  },
  actionIcon: {
    paddingRight: '5px',
    fontSize: '20px',
  },
  actionIconWatch: {
    color: 'green',
  },
  actionIconCancel: {
    color: 'red',
  },
  btnIcon: {
    float: 'right',
    marginLeft: 5,
    lineHeight: '30px',
  },
  linkIcon: {
    fill: '#58a6ff',
    verticalAlign: 'middle',
  },
  dateTitle: {
    fontSize: '14px',
    lineHeight: '20px',
  },
}));
