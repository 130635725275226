import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
  split,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { LOCAL_STORAGE_KEYS } from '../../utils/constants';
import { createUploadLink } from 'apollo-upload-client';
import { WebSocketLink } from './ws-link';
import { getMainDefinition } from '@apollo/client/utilities';

const apolloCache = new InMemoryCache();

const link = createUploadLink({
  uri: process.env.REACT_APP_GRAPHQL_URL,
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN);

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const wsLink =
  typeof window !== 'undefined'
    ? new WebSocketLink({
        url: `${process.env.REACT_APP_API_SOCKET_URL}`,
        connectionParams: () => {
          const token = localStorage.getItem('authToken');
          return {
            Authorization: `Bearer ${token}`,
          };
        },
      })
    : null;

const splitLink =
  typeof window !== 'undefined' && wsLink !== null
    ? split(
        ({ query }) => {
          const definition = getMainDefinition(query);
          return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
          );
        },
        wsLink,
        authLink.concat(link),
      )
    : authLink.concat(link);

const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  link: splitLink,
  cache: apolloCache,
  typeDefs: '',
  resolvers: {},
});

export default client;
