import React, { useEffect, useState } from 'react';
import FormContact from '../../components/FormContact/FormContact';
import {
  Box,
  Divider,
  Typography,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { getInitialUser, TRoutes } from '../../utils/helpers';
import useStyles from './styles';
import { ShowLoadingText } from '../../utils/helperComponents';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
  Contact as TContact,
  ContactInput,
  GetContactsQuery,
  GetContactsQueryVariables,
  useCreateContactMutation,
  useGetContactLazyQuery,
  useUpdateContactMutation,
} from '../../generated/graphql';
import { FormikHelpers } from 'formik';
import { useApolloClient } from '@apollo/client';
import getContacts from '../../GraphQL/queries/getContacts';
import { useTranslation } from 'react-i18next';

const FormAddressBookContact: React.FC = () => {
  const classes = useStyles();
  const client = useApolloClient();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const useMatch = useRouteMatch<{ id: string }>();
  const isCreateMode = useMatch.path === TRoutes.CONTACTS_CREATE;
  const [updateView, setUpdateView] = useState(0);

  const { t } = useTranslation();

  const [createContactMutation] = useCreateContactMutation();
  const [updateContactMutation] = useUpdateContactMutation();

  const [
    getContactLazyQuery,
    {
      loading: loadingGetContactLazyQuery,
      error: errorGetContactLazyQuery,
      data: dataGetContactLazyQuery,
    },
  ] = useGetContactLazyQuery();

  const contactTypes = [
    {
      value: 'recipient',
      title: t('app.saveAsRecipient'),
    },
    {
      value: 'sender',
      title: t('app.saveAsSender'),
    },
  ];

  const [contactType, setContactType] = useState(contactTypes[0].value);

  useEffect(() => {
    if (!dataGetContactLazyQuery?.contact) return;
    if (dataGetContactLazyQuery?.contact?.type === 'SENDER') {
      setContactType(contactTypes[1].value);
    }
    if (dataGetContactLazyQuery?.contact?.type === 'RECIPIENT') {
      setContactType(contactTypes[0].value);
    }
  }, [dataGetContactLazyQuery, contactTypes]);

  useEffect(() => {
    if (isCreateMode) return;

    if (+useMatch.params.id) {
      getContactLazyQuery({ variables: { id: useMatch.params.id } });
    } else history.push(TRoutes.NOT_FOUND);
    // eslint-disable-next-line
  }, [useMatch.params.id]);

  useEffect(() => setUpdateView((value) => ++value), [dataGetContactLazyQuery]);

  useEffect(() => {
    if (errorGetContactLazyQuery) {
      history.push(TRoutes.CONTACTS);
      enqueueSnackbar(errorGetContactLazyQuery.message, { variant: 'error' });
    }
    // eslint-disable-next-line
  }, [errorGetContactLazyQuery]);

  if (loadingGetContactLazyQuery) return <ShowLoadingText name='контактов' />;

  const handlerSubmit = (
    contactInput: ContactInput,
    { setSubmitting }: FormikHelpers<ContactInput>,
  ) => {
    const input: ContactInput = {
      contactName: contactInput.contactName,
      //@ts-ignore
      countryId: contactInput.country.id || contactInput.country,
      company: contactInput.company,
      address: contactInput.address,
      address2: contactInput.address2,
      address3: contactInput.address3,
      city: contactInput.city,
      email: contactInput.email,
      innKppCode: contactInput.innKppCode,
      name: contactInput.name,
      phone: contactInput.phone,
      remark: contactInput.remark,
      state: contactInput.state,
      zipCode: contactInput.zipCode,
      //@ts-ignore
      type: contactType?.toUpperCase(),
    };

    if (isCreateMode) {
      createContactMutation({
        variables: { input: input },
      })
        .then(({ data }) => {
          enqueueSnackbar(t('app.contactSuccessfullySaved'), {
            variant: 'success',
          });

          client
            .query<GetContactsQuery, GetContactsQueryVariables>({
              query: getContacts,
              fetchPolicy: 'network-only',
            })
            .catch((e) => console.error(e));

          history.push(TRoutes.CONTACTS);
        })
        .catch((error) => {
          enqueueSnackbar(error.message, { variant: 'error' });
        })
        .finally(() => setSubmitting(false));
    } else {
      updateContactMutation({
        variables: {
          id: useMatch.params.id,
          input: input,
        },
      })
        .then(({ data }) => {
          enqueueSnackbar(t('app.contactSuccessfullySaved'), {
            variant: 'success',
          });
          history.push(TRoutes.CONTACTS);
        })
        .catch((error) => {
          enqueueSnackbar(error.message, { variant: 'error' });
        })
        .finally(() => setSubmitting(false));
    }
  };

  const changeSelectRadioBtn = (event: any) => {
    setContactType(event.target.value);
  };

  return (
    <div className={classes.root}>
      <Typography className={classes.title} variant='h2'>
        {isCreateMode ? t('app.createContact') : t('app.editContact')}
      </Typography>
      <Divider className={classes.divider} />
      <Box className={classes.formWrapper}>
        <FormControl component='fieldset'>
          <RadioGroup
            name='contactType'
            value={contactType}
            onChange={changeSelectRadioBtn}
          >
            {contactTypes.map((item) => (
              <FormControlLabel
                value={item.value}
                label={item.title}
                disabled={!isCreateMode}
                control={<Radio />}
              />
            ))}
          </RadioGroup>
        </FormControl>
        <FormContact
          isAddress
          onSubmit={handlerSubmit}
          isCreate={isCreateMode}
          key={updateView}
          contactType={contactType as 'sender' | 'recipient'}
          initialValues={
            isCreateMode
              ? (getInitialUser() as TContact)
              : (dataGetContactLazyQuery && dataGetContactLazyQuery.contact) ||
                (getInitialUser() as TContact)
          }
          isPersonal={false}
        />
      </Box>
    </div>
  );
};

export default FormAddressBookContact;
