import React from 'react';
import ModalBox from '../../../components/ModalBox/ModalBox';
import { Button, Box, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

type ModalDeleeteType = {
  isOpen: boolean;
  setIsOpen: (value: any) => void;
  deleteId?: number | null;
  setDeleteId: (value: any) => void;
  handleRemove: (value: number) => void;
};

export const ModalDeleete = ({
  isOpen,
  setIsOpen,
  deleteId,
  setDeleteId,
  handleRemove,
}: ModalDeleeteType) => {
  const { t } = useTranslation();

  return (
    <ModalBox isOpen={isOpen} setOpen={setIsOpen}>
      <Typography align='center'>{t('app.deleteStoreFromList')}</Typography>
      <Box style={{ marginTop: '10px' }}>
        <Button
          variant='contained'
          onClick={() => {
            setIsOpen(false);
          }}
          style={{ marginRight: '32px' }}
        >
          {t('app.cancel')}
        </Button>
        <Button
          variant='contained'
          onClick={() => {
            handleRemove(deleteId as any);
            setIsOpen(false);
          }}
        >
          {t('app.delete')}
        </Button>
      </Box>
    </ModalBox>
  );
};
