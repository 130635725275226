import React from 'react';
import { Box, Divider, Typography } from '@material-ui/core';

const Eula = () => {
  return (
    <div>
      <Box m={'15px 0'}>
        <Typography variant='h2' align='center'>
          Пользовательское соглашение для веб-сайта
        </Typography>
      </Box>

      <Divider />

      <Box m={'15px 0'}>
        <h3>1. ОПРЕДЕЛЕНИЕ ПОНЯТИЙ</h3>
        <p>
          В тексте настоящего Соглашения нижеизложенным терминам дано следующее
          значение:
        </p>
        <p>
          «Сайт» означает следующий сайт: <strong>picknpack.ru</strong>, который
          принадлежит Компании и находится по следующей ссылке:{' '}
          <a href='https://www.picknpack.ru'>https://www.picknpack.ru</a>
        </p>
        <p>
          <strong>«Пользователь»</strong>, <strong>«Вы»</strong>,{' '}
          <strong>«Ваш»</strong>, <strong>«Вас»</strong>,{' '}
          <strong>«Вами»</strong> или какие-либо другие схожие производные (в
          зависимости от контекста) означает лицо, которое (1) пользуется Сайтом
          и/или получило доступ к его Контенту; и (2) дало свое согласие на
          соблюдение правил пользования Сайтом, изложенных в тексте данного
          Соглашения, путем создания личного аккаунта и регистрации на Сайте.
        </p>
        <p>
          <strong>«Компания»</strong>, <strong>«Мы»</strong>,{' '}
          <strong>«Наш»</strong>, <strong>«Нас»</strong>,{' '}
          <strong>«Нами»</strong> или какие-либо другие схожие производные (в
          зависимости от контекста) означает следующее лицо: ООО "Скала Групп",
          а также какие-либо другие лица, созданные в результате реорганизации
          Компании), которое является владельцем Сайта или им управляет.
        </p>
        <p>
          <strong>«Контент сайта»</strong> означает все объекты, размещенные
          Компанией и/или третьими лицами (с разрешения Компании) на Сайте, в
          том числе элементы дизайна, текст, графические изображения,
          иллюстрации, видео, программы, музыка, звуки, информация, уведомления
          и какие-либо другие объекты схожего назначения, их подборки или
          комбинации.
        </p>
        <p>
          <strong>«ПО сайта»</strong> означает программное обеспечение,
          разработанное Компанией (и/или третьими лицами по поручению Компании)
          для Сайта, включая, однако не ограничиваясь, весь софт, скрипты, коды
          (НТМL коды), программы и тп.
        </p>
        <p>
          <strong>«Услуги»</strong> означает совокупно Контент сайта и ПО сайта.
        </p>
        <h3>2. ПРИСОЕДИНЕНИЕ К СОГЛАШЕНИЮ</h3>
        <ul>
          <li>
            Настоящее Пользовательское соглашение (далее{' '}
            <strong>«Соглашение»</strong>) определяет правила и порядок
            использования Сайта и Услуг, права и обязанности Пользователей, а
            также регулирует поведение Пользователей при получении доступа к
            Сайту и Услугам.
          </li>
          <li>
            Заключая это соглашение, мы исходим из того, что вы заинтересованы в
            услугах по приему, доставке (вручении) отправлений (письменной
            корреспонденции, посылки, а также иные вложения документарного и не
            документарного характера) третьим лицам (Получателям), либо выдачу
            Отправлений Получателям со склада.
          </li>
          <li>
            Компания, в свою очередь, выполняет услуги по доставке, хранению и
            складской обработке товаров, которые предоставляются за плату.
          </li>
          <li>
            С целью предоставления вам услуг, предусмотренных настоящим
            соглашением, Компания может заключать договоры с другими лицами в
            любой форме, которая разрешена законодательством и позволяет
            обеспечить получение вами надлежащих услуг (в частности, в форме
            договоров о предоставлении услуг, агентских или субагентских
            договоров, договоров комиссии и т. п.).
          </li>
          <li>
            Пользователь принимает условия данного Соглашения путем создания
            личного аккаунта и регистрации на Сайте.
          </li>
          <li>
            Данное Соглашение является обязательным для его сторон (т.е. для
            Компании и Пользователя). Уступка Пользователем своих прав по
            данному Соглашению возможна только после получения предварительного
            письменного согласия от Компании.
          </li>
        </ul>

        <h3>3. ПОЛЬЗОВАТЕЛИ САЙТА</h3>
        <h4>Общие критерии и возраст</h4>
        <p>
          Для использования Сайта Пользователи должны соответствовать следующим
          критериям (совокупно):
        </p>
        <ul>
          <li>быть не младше 18-лет; и</li>
          <li>
            не быть ограниченным в праве доступа к Сайту и Услугам на основании
            решения суда, вступившего в законную силу, или в случаях,
            предусмотренных действующим законодательством или условиями данного
            Соглашения; и
          </li>
          <li>соблюдать следующие условия/выполнять критерии:</li>
        </ul>
        <p>
          Физические или юридические лица, которые могут заключать юридически
          обязывающие контракты в соответствии с действующим законодательством
        </p>

        <h4>Регистрация на сайте и создание личного аккаунта</h4>
        <ul>
          <li>
            Для использования Сайта и/или получения доступа к Услугам
            Пользователям требуется пройти обязательную бесплатную регистрацию
            при входе на Сайт. Пользование услугами Сайта без регистрации
            невозможно. По результатам завершения регистрации Пользователь
            получает уникальный логин и пароль для входа в его личный аккаунт
            (кабинет).
          </li>
          <li>
            Для регистрации Пользователю необходимо предоставить следующую
            информацию о себе:
            <ul>
              <li>
                Регистрация для частных лиц: ФИО, контактный телефон,
                электронная почта.
              </li>
              <li>
                Регистрация для бизнеса: Полное наименование компании,
                юридический адрес, ИНН, ОГРН, банковские реквизиты, контактный
                телефон, электронная почта.
              </li>
            </ul>
          </li>
        </ul>

        <h3>4. ИНТЕЛЛЕКТУАЛЬНАЯ СОБСТВЕННОСТЬ</h3>
        <p>
          4.1. Компании принадлежат все без исключения права собственности,
          включая имущественные права интеллектуальной собственности, на весь
          Контент сайта, а также ПО сайта. ПО сайта и Контент сайта защищены
          авторским правом в порядке, предусмотренном действующим гражданским
          законодательством Российской Федерации, а также международными
          договорами и конвенциями в области защиты объектов интеллектуальной
          собственности.
        </p>
        <p>
          4.2. Пользователям запрещено копировать, воспроизводить,
          модифицировать, компилировать, распространять, отображать в какой-либо
          форме, публиковать, скачивать, передавать, продавать или иным способом
          распространять или использовать контент сайта и ПО сайта, кроме
          случаев, когда такие действия ПРЯМО разрешены условиями данного
          Соглашения или действующим законодательством Российской Федерации.
        </p>
        <p>
          4.3. Ничто в тексте данного Соглашения не может быть истолковано как
          передача Пользователю каких-либо исключительных прав на контент сайта
          (полностью или в отдельной части) и/или ПО сайта.
        </p>
        <p>
          4.4. Компания владеет всеми правами в отношении торговых марок,
          коммерческих (бизнес) наименований, брендов, логотипов,
          зарегистрированных на ее имя (далее "Торговые марки"). Такие Торговые
          марки защищаются действующим законодательством и НИЧТО в тексте
          данного Соглашения не может быть истолковано как передача какой-либо
          лицензии пользователю на использование таких Торговых марок.
        </p>

        <h3>5. ПОРЯДОК РАБОТЫ С САЙТОМ</h3>
        <h4>Правила поведения на сайте</h4>
        <p>
          5.1. Во время использования Сайта пользователь обязуется
          придерживаться следующих правил:
        </p>
        <ul>
          <li>
            (1) соблюдать все обязательства, взятые на себя Пользователем в
            связи с присоединением к данному Соглашению; и
          </li>
          <li>
            (2) предоставлять достоверные о себе данные во время регистрации на
            Сайте и для создания личного аккаунта (кабинета); и
          </li>
          <li>
            (3) не выдавать себя за какое-либо другое лицо, включая, однако не
            ограничиваясь, не предоставлять какие-либо данные третьих лиц (без
            получения от них прямого, предварительного и осознанного согласия)
            для регистрации на Сайте и/или для создания личного аккаунта
            (кабинета); и
          </li>
          <li>
            (4) информировать Компанию о хищении логинов, паролей или каких-либо
            других ключей доступа Пользователя к личному аккаунту (кабинету); и
          </li>
          <li>
            (5) не предоставлять третьим лицам доступ к своему аккаунту
            (кабинету) и/или логины, пароли или другие ключи доступа; и
          </li>
          <li>
            не совершать какие-либо действия (с использованием средств
            автоматизации или без таких средств), направленные на сбор
            каких-либо персональных данных других Пользователей; и
          </li>
          <li>
            не предпринимать какие-либо действия и не помогать третьим лицам в
            совершении действий, направленных на подрыв работы Сайта, включая,
            однако не ограничиваясь, (а) загружать вирусы или вредоносный код;
            (б) совершать действия, которые могут привести к отключению Сайта, к
            нарушению нормальной работы Сайта или ПО сайта, или к ухудшению
            внешнего вида Сайта и/или Контента Сайта.
          </li>
          <li>
            не предпринимать какие-либо иные действия, которые являются
            незаконными, мошенническими, дискриминационными или вводящими в
            заблуждение.
          </li>
        </ul>

        <h4>Отзывы о работе сайта</h4>
        <ul>
          <li>
            Каждый Пользователь время от времени имеет право (но не обязанность)
            оставлять или направлять свои идеи, отзывы, предложения или проекты,
            направленные на улучшение работы Сайта или качества предоставляемых
            Услуг. Такие отзывы могут быть направлены Пользователем следующим
            способом;
            <p>
              Отправить письмо по следующему адресу:{' '}
              <a href='mailto:info@picknpack.ru'>info@picknpack.ru</a>
            </p>
          </li>
          <li>
            В случае направления такой идеи, отзыва, предложения или проекта
            Пользователь автоматически предоставляет нам неисключительную, не
            требующую лицензионных отчислений, действующую по всему миру
            лицензию с правом передачи и выдачи сублицензий на хранение,
            использование, распространение, изменение, запуск, копирование,
            публичное исполнение или показ, перевод Ваших идей, отзывов,
            предложений или проектов, а также создание производных работ на их
            основе.
          </li>
        </ul>

        <h3>6. РАЗМЕЩЕНИЕ РЕКЛАМЫ НА САЙТЕ</h3>
        <h4>Размещение рекламы компанией</h4>
        <p>
          Компания имеет право время от времени размещать на Сайте какие-либо
          рекламные или маркетинговые материалы.
        </p>
        <h4>Размещение рекламы третьими лицами</h4>
        <ul>
          <li>
            Контент сайта может содержать ссылки на веб-сайты третьих лиц и/или
            рекламные или маркетинговые материалы о товарах/услугах,
            предоставленные такими третьими лицами (далее{' '}
            <strong>«Реклама третьих лиц»</strong>). КОМПАНИЯ НЕ БЕРЕТ НА СЕБЯ
            КАКУЮ-ЛИБО ОТВЕТСТВЕННОСТЬ (1) ЗА СОДЕРЖАНИЕ РЕКЛАМЫ ТРЕТЬИХ ЛИЦ, А
            ТАКЖЕ ЗА НАЛИЧИЕ, КАЧЕСТВО И БЕЗОПАСНОСТЬ ПРОДВИГАЕМЫХ ТОВАРОВ/УСЛУГ
            В ТАКОЙ РЕКЛАМЕ; и (2) ЗА КАКИЕ-ЛИБО УБЫТКИ, ПОТЕРИ ИЛИ УЩЕРБ,
            ПОНЕСЕННЫЕ ИЛИ ПРИЧИНЕННЫЕ ПОЛЬЗОВАТЕЛЮ В РЕЗУЛЬТАТЕ ПРОЧТЕНИЯ ИМ
            ТАКОЙ РЕКЛАМЫ, ИСПОЛЬЗОВАНИЯ ИМ ПРОДВИГАЕМЫХ В РЕКЛАМЕ ТРЕТЬИХ ЛИЦ
            ТОВАРОВ/ УСЛУГ.
          </li>
          <li>
            В случае перехода на другой сайт через размещенную на Сайте Рекламу
            третьих лиц, Компания не может гарантировать, что такой веб-сайт
            является безопасным для Пользователя и/ или его компьютера. Ни что в
            тексте данного Соглашения не должно быть истолковано как заверение,
            поощрение, рекомендация или побуждение Пользователя воспользоваться
            Рекламой третьих лиц, посетить какие-либо сайты третьих лиц, а также
            попробовать, приобрести, воспользоваться какими-либо
            товарами/услугами третьих лиц.
          </li>
        </ul>

        <h3>7. ОПЛАТА УСЛУГ НА САЙТЕ</h3>
        <h4>Общие положения</h4>
        <ul>
          <li>
            Пользователи имеют возможность приобретать определенные услуги на
            Сайте. Продавцом таких услуг выступает непосредственно Компания.
          </li>
          <li>
            Компания не хранит какие-либо данные о Вашей дебетовой или кредитной
            карте, используемой для оплаты.
          </li>
          <li>
            Компания предоставляет заказанную услугу только после получения
            оплаты в полном объеме.
          </li>
          <li>
            Если Компания не может предоставить Вам заказанную и оплаченную
            услугу, Компания обязуется незамедлительно провести возврат Вам всей
            уплаченной ранее суммы в счет погашения стоимости заказанной услуги
            (за исключением банковских комиссий и издержек, которые могли быть
            начислены Вашим обслуживающим банком при оплате товаров/ услуг через
            Сайт).
          </li>
          <li>
            Компания не гарантирует выполнение той или иной услуги на Сайте.
          </li>
          <li>
            Компания не несет ответственности за ущерб или убытки, вызванные
            задержкой в доставке товара.
          </li>
          <li>
            Компания не берет на себя какую-либо ответственность за ошибку в
            доставке, которая произошла по вине Пользователя, который, к
            примеру, не указал верные данные получателя.
          </li>
        </ul>
        <h4>Порядок оплаты</h4>
        <ul>
          <li>
            Стоимость услуги на Сайте указывается в российских рублях. Оплата за
            услугу также должна быть проведена в российских рублях.
          </li>
          <li>
            Если у Вас нету счета в указанной валюте, в таком случае Вы можете
            воспользоваться, Вашей дебетовой или кредитной картой, а
            обслуживающий банк (держатель дебетовой или кредитной карты)
            проведет соответствующею конвертацию по обменному курсу согласно его
            внутрибанковским правилам. Компания не отвечает за обменный курс,
            используемый Вашим обслуживающим банком при такой конвертации.
          </li>
          <li>
            Пользователь может провести оплату одним из следующих способов:
            <ul>
              <li>Банковской картой</li>
              <li>Google Pay</li>
              <li>Apple Pay</li>
            </ul>
          </li>
          <li>
            Обращаем внимание, что Компания может в любой момент отказать в
            приеме того или иного способа платежа без какого-либо объяснения или
            уведомления Пользователей.
          </li>
          <li>
            Услуга считается оплаченной Пользователем в полном объеме с момента
            подтверждения исполнения такого платежа банковским учреждением,
            обслуживающим дебетовую или кредитную карту Пользователя, которая
            была им использована для оплаты на Сайте.
          </li>
          <li>
            Обращаем внимание, что Ваш обслуживающий банк может проводить по
            своему собственному усмотрению дополнительные проверки совершенной
            транзакции через наш Сайт, что в последствие может привести к
            задержке оплаты заказанной Вами ранее услуги.
          </li>
          <li>
            Компания не берет на себя какую-либо ответственность за убытки,
            ущерб, упущенную выгоду, потерю деловой репутации, возникшие у
            Пользователя из-за задержки в оплате, что в свою очередь могло
            привести к задержке с нашей стороны в предоставлении Вам
            определенной услуги.
          </li>
        </ul>

        <h4>Порядок отмены и возврата</h4>
        <ul>
          <li>
            Пользователь имеет право на отмену ранее заказанной услуги на Сайте.
          </li>
          <li>
            Правила и порядок отмены ранее заказанной услуги изложены по
            следующей ссылке: picknpack.ru/refund-policy.
          </li>
        </ul>

        <h3>8. ОФОРМЛЕНИЕ ПОДПИСКИ НА САЙТЕ</h3>
        <p>
          Доступ к Сайту и к его Услугам не предполагает от Пользователей
          оформления какой-либо подписки.
        </p>

        <h3>9. ПРЕКРАЩЕНИЕ ДОСТУПА К САЙТУ</h3>
        <ul>
          <li>
            Пользователь имеет право в любой момент прекратить использование
            Сайта посредством удаления своего аккаунта. Для этого необходимо
            отправить сообщение администрации сайта с просьбой удаления
            соответствующего аккаунта.
          </li>
          <li>
            В случае (1) нарушения Пользователем условий настоящего Соглашения;
            и/или (2) нарушения прав интеллектуальной собственности Компании,
            других Пользователей или третьих лиц; и/или (3) совершения действий,
            которые являются противозаконными, нарушают права и интересы
            Компании, других Пользователей или третьих лиц или подрывают работу
            Сайта или возможность использования Сайта другими Пользователями;
            и/или (4) Услуги или Сайт используется Пользователем таким образом,
            что это может повлечь юридическую ответственность Компании в
            будущем; и/или (5) если этого требует действующее законодательство
            или компетентный государственный орган, Компания имеет право без
            предварительного уведомления в любой момент прекратить (остановить)
            доступ Пользователя к Сайту и его Услугам.
          </li>
          <li>
            Такое прекращение доступа кроме прочего также подразумевает удаление
            личного аккаунта Пользователя.
          </li>
          <li>
            Пользователь должным образом осведомлен, что Компания не берет на
            себя ответственность за какой-либо ущерб, убытки, упущенную выгоду,
            потерю деловой или личной репутации, причиненные Пользователю
            удалением или блокировкой аккаунта и/или невозможностью доступа к
            Сайту и его Услугам.
          </li>
        </ul>

        <h3>10. ЗАДАТЬ ВОПРОС</h3>
        <ul>
          <li>
            Если у Вас есть вопросы касательно условий настоящего Соглашения или
            порядка/способа их исполнения, Вы можете адресовать нам свой вопрос
            следующим способом:
            <p>
              Отправить электронное письмо по следующему адресу:{' '}
              <a href='mailto:info@picknpack.ru'>info@picknpack.ru</a>
            </p>
          </li>
          <li>
            Сотрудники и представители Компании обязуются предпринять все
            возможные усилия для дачи ответа на Ваш запрос в течение разумного
            периода времени.
          </li>
        </ul>

        <h3>11. КОНФИДЕНЦИАЛЬНОСТЬ И БЕЗОПАСНОСТЬ</h3>
        <p>
          11.1 Мы не продаем и не предоставляем права на использование Вашей
          личной информации третьим лицам для маркетинговых целей без вашего
          согласия, и мы используем Вашу информацию, как описано в Политике
          конфиденциальности.
        </p>
        <p>
          11.2 Мы рассматриваем защиту конфиденциальности пользователей как
          очень важный принцип. Мы храним и обрабатываем вашу информацию на
          компьютерах, которые защищены как физическими, так и технологическими
          устройствами безопасности. Вы должны войти в свою учетную запись
          picknpack.ru на страницу с адресом https://www.lk.picknpack.ru. Все
          наши страницы начинаются с " http: / или https: / и поэтому вы не
          должны использовать любой другой сайт, который не начинается как
          такой.
        </p>

        <h3>12. ПРОЧИЕ УСЛОВИЯ</h3>
        <p>
          12.1 Все возможные споры, вытекающие из настоящего Соглашения или
          связанные с ним, подлежат разрешению в соответствии с действующим
          законодательством Российской Федерации.
        </p>
        <p>
          12.2 Признание судом какого-либо положения Соглашения недействительным
          или не подлежащим принудительному исполнению не влечет
          недействительности иных положений Соглашения.
        </p>
        <p>
          12.3 Бездействие со стороны Администрации Сайта в случае нарушения
          кем-либо из Пользователей положений Соглашения не лишает Администрацию
          Сайта права предпринять позднее соответствующие действия в защиту
          своих интересов и защиту авторских прав на охраняемые в соответствии с
          законодательством материалы Сайта.
        </p>

        <h3>13. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</h3>
        <p>
          Настоящее Соглашение вступает в силу с момента его опубликования на
          Сайте (по следующей ссылке: https://www.picknpack.ru/terms-of-use) и
          действуют в течение неопределенного периода времени.
        </p>
        <p>
          Мы можем время от времени пересматривать, дополнять или изменять
          условия данного Соглашения. Такие изменения, как правило, не являются
          ретроспективными. КОМПАНИЯ НЕ БЕРЕТ НА СЕБЯ КАКУЮ-ЛИБО ОБЯЗАННОСТЬ ПО
          УВЕДОМЛЕНИЮ ПОЛЬЗОВАТЕЛЕЙ О ПРЕДСТОЯЩИХ ИЛИ СОСТОЯВШИХСЯ ИЗМЕНЕНИЯХ
          ТЕКСТА СОГЛАШЕНИЯ. Посредством присоединения к условиям данного
          Соглашения Пользователь также берет на себя обязательство периодически
          просматривать условия данного Соглашения на предмет их изменения или
          дополнения.
        </p>
        <ul>
          <li>
            Если после проведенных изменений или дополнений в тексте Соглашения
            Пользователь продолжает пользоваться Сайтом, это значит, что он
            ознакомлен с изменениями или дополнениями и их принял в полном
            объеме без каких-либо возражений.
          </li>
          <li>
            Если иное прямо не указано в положениях настоящего Соглашения или
            прямо не вытекает из норм действующего законодательства, к условиям
            настоящего Договора применяется материальное право Российской
            Федерации.
          </li>
          <li>
            Если одно или несколько условий данного Соглашения утратило свою
            юридическую силу или признано недействительным согласно действующему
            законодательству, остальные условия Соглашения не теряют своей силы
            и продолжают действовать так, словно признанного недействительным
            или утратившим юридическую силу условия не существовало вовсе.
          </li>
        </ul>
        <p>
          Доступ к Сайту и его Услугам предоставляется Пользователю “как есть”,
          Мы не обещаем, не гарантируем, не подразумеваем, что Услуги и Сайт
          могут подходить или не подходить Вашим потребностям, целям, ожиданиям,
          и в связи с чем не гарантируем какого-либо специфического результата
          или последствия в результате использования Вами Сайта и его Услуг.
        </p>

        <div className='alert alert-warning' role='alert'>
          Если во время работы с новым личным кабинетом у вас возникли вопросы,
          наши операторы постараются на них ответить{' '}
          <a className='alert-link' href='tel:88002012159'>
            по телефону нашей горячей линии
          </a>
        </div>
      </Box>
    </div>
  );
};

export default Eula;
