import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  formControl: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  tooltipIconButton: {
    padding: 0,
  },
}));
