import { gql } from '@apollo/client';

export const GENERATE_URL_FOR_AUTHORIZATION_IN_AMAZON = gql`
  query generateUrlForAuthorizationInAmazon(
    $name: String!
    $merchantId: String!
    $region: String!
  ) {
    generateUrlForAuthorizationInAmazon(
      name: $name
      merchantId: $merchantId
      region: $region
    )
  }
`;
