import { Field, FieldProps } from 'formik';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import React from 'react';
import { CURRENCIES } from '../../../utils/constants';
import { TFormikSetFieldValueDebounce } from '../../../interfaces';
import styles from './styles';

const FieldCurrency: React.FC<{
  fieldName: string;
  setFieldValue: TFormikSetFieldValueDebounce;
  label?: string;
}> = ({ fieldName, setFieldValue, label }) => {
  const classes = styles();

  const handlerChange = (
    e: React.ChangeEvent<{
      name?: string;
      value: unknown;
    }>,
  ) => {
    setFieldValue(String(e.target.name), e.target.value);
  };

  return (
    <Field name={fieldName}>
      {({ field, meta }: FieldProps) => (
        <FormControl
          error={!!(meta.touched && meta.error)}
          className={classes.formComtrol}
        >
          <InputLabel
            style={{
              fontSize: '13px',
              whiteSpace: 'nowrap',
            }}
            shrink={false}
            htmlFor='input-price-unit'
          >
            {label || ''}
          </InputLabel>
          <Select
            id='input-price-unit'
            disableUnderline
            {...field}
            onChange={handlerChange}
          >
            {Object.values(CURRENCIES).map((value) => (
              <MenuItem value={value}>{value}</MenuItem>
            ))}
          </Select>
          {meta.touched && meta.error && (
            <FormHelperText>{meta.error}</FormHelperText>
          )}
        </FormControl>
      )}
    </Field>
  );
};

export default FieldCurrency;
