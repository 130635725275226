import React from 'react';
import { TableCell, TableRow, Typography } from '@material-ui/core';
import moment from 'moment';
import { DATE_TIME_FORMAT } from '../../../utils/constants';
import useStyles from './styles';

import { INotificationsTableRowComponent } from './types';
import { useTranslation } from 'react-i18next';

const NotificationsTableRowComponent = ({
  item,
}: INotificationsTableRowComponent) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const formattedCreatedAt = moment(new Date(String(item?.sendAt))).format(
    DATE_TIME_FORMAT,
  );

  const getMessageText = () => {
    if (!!item.text) {
      return item.text;
    }

    if (item.topic === 'Ожидает загрузки ШК') {
      return (
        <Typography className={classes.messageTitle}>
          {`${t('app.order')} `}
          <a href={`https://${item.data.link}`}>{item.data.link_title}</a>
          {` ${t('app.pickedAndWaitingDownloadBarcodes')}`}
        </Typography>
      );
    }
    return (
      <>
        <Typography className={classes.messageTitle}>
          {`${t('app.successfullyAccept')} `}
          <a href={`https://${item.data.link}`}>{item.data.link_title}</a>
        </Typography>
        {!!item.data.expected_quantity && (
          <Typography className={classes.messageTitle}>{`${t(
            'app.expectedProductQuantity',
          )} - ${item.data.expected_quantity}`}</Typography>
        )}
        {!!item.data.accepted_quantity && (
          <Typography className={classes.messageTitle}>{`${t(
            'app.receivedProductQuantity',
          )} - ${item.data.accepted_quantity}`}</Typography>
        )}
      </>
    );
  };

  return (
    <TableRow>
      <TableCell align='center'>{formattedCreatedAt}</TableCell>
      <TableCell align='center'>{item.topic}</TableCell>
      <TableCell className={classes.notificationMessage}>
        {getMessageText()}
      </TableCell>
    </TableRow>
  );
};

export default NotificationsTableRowComponent;
