import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';

export const i18nInit = (() => {
  // eslint-disable-next-line import/no-named-as-default-member
  return i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .use(HttpApi)
    .init({
      supportedLngs: ['en', 'ru'],
      fallbackLng: 'en',
      defaultNS: 'en',
      detection: {
        order: ['localStorage', 'htmlTag'],
      },
      backend: {
        loadPath: '/locales/{{lng}}/translation.json',
      },
      react: { useSuspense: false },
    });
})();
