import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { FastField, FieldArray, FieldProps } from 'formik';
import React, { useState } from 'react';
import {
  CargoPlace,
  GetPackagingTemplatesListQuery,
  GetPackagingTemplatesListQueryVariables,
  PackagingTemplate,
} from '../../../generated/graphql';
import { QUERY_PACKAGING_TEMPLATE_LIST } from '../../../GraphQL/queries/getPackagingTemplatesList';
import { TFormikSetFieldValueDebounce } from '../../../interfaces';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import AddIcon from '@material-ui/icons/Add';
import ArchiveIcon from '@material-ui/icons/Archive';
import {
  COLORS,
  MAX_PARCEL_DIMENSION,
  MAX_PARCEL_WEIGHT,
  TEXT,
} from '../../../utils/constants';
import useStyles from './styles';
import { getInitialCargoPlace } from '../../../utils/helpers';
import { useApolloClient } from '@apollo/client';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import ModalBox from '../../../components/ModalBox/ModalBox';
import { Autocomplete } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';

const SendingToWarehouseFormStep2: React.FC<{
  values: any;
  setFieldValueDebounce: TFormikSetFieldValueDebounce;
  cargoPlaces: CargoPlace[];
  setValues: any;
  setUpdateView: any;
}> = ({
  values,
  setFieldValueDebounce,
  cargoPlaces,
  setValues,
  setUpdateView,
}) => {
  const client = useApolloClient();
  const classes = useStyles();
  const theme = useTheme();
  const hidden = useMediaQuery(theme.breakpoints.down('xs'));
  const [elementToRemove, setElementToRemove] = useState<number | null>();
  const [editingPackageIndex, setEditingPackageIndex] = useState(0);
  const [isOpenModalMyPackages, setIsOpenModalMyPackages] = useState(false);
  const { t } = useTranslation();

  const showPreRemoveStyle = (element: any) => {
    return elementToRemove === element
      ? { background: '#cacaca', filter: 'blur(1px)' }
      : {};
  };

  const cachedPackages = client.readQuery<
    GetPackagingTemplatesListQuery,
    GetPackagingTemplatesListQueryVariables
  >({
    query: QUERY_PACKAGING_TEMPLATE_LIST,
  });

  const handlerChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const name = e.target.name;
    const value = e.target.value;

    setFieldValueDebounce(name, +value);
  };

  return (
    <>
      <FieldArray
        name='cargoPlaces'
        render={(helpers) => (
          <div>
            {cargoPlaces?.map(
              (cargoPlaceItem, cargoPlaceIndex) =>
                cargoPlaceItem && (
                  <div
                    key={cargoPlaceIndex}
                    className={classes.packageItem}
                    style={showPreRemoveStyle(cargoPlaceIndex)}
                  >
                    <div className={classes.root}>
                      <Grid container spacing={5}>
                        <Grid item xs={12}>
                          <Box
                            display={'flex'}
                            alignItems={'center'}
                            justifyContent={'center'}
                            height={'100%'}
                            position={'relative'}
                          >
                            <Typography variant={'h6'}>
                              {t('app.packageNumber')}
                              {cargoPlaceIndex + 1}
                            </Typography>
                            {cargoPlaceIndex !== 0 && (
                              <Tooltip
                                title={`${t('app.deleteCargoNo')}${
                                  cargoPlaceIndex + 1
                                } ?`}
                              >
                                <DeleteForeverIcon
                                  className={classes.removePackage}
                                  style={{ color: COLORS.RED }}
                                  onMouseEnter={() =>
                                    setElementToRemove(cargoPlaceIndex)
                                  }
                                  onMouseLeave={() => setElementToRemove(null)}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    helpers.remove(cargoPlaceIndex);
                                  }}
                                />
                              </Tooltip>
                            )}
                            {!!cachedPackages?.getPackagingTemplatesList?.rows
                              ?.length && (
                              <Tooltip title={t('app.selectPackage')}>
                                <Box
                                  className={classes.openMyPackages}
                                  onClick={() => {
                                    setEditingPackageIndex(cargoPlaceIndex);
                                    setIsOpenModalMyPackages(true);
                                  }}
                                >
                                  <ViewModuleIcon />
                                  {hidden ? null : t('app.myPackage')}
                                </Box>
                              </Tooltip>
                            )}
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <FastField
                            name={
                              Number.isInteger(cargoPlaceIndex)
                                ? `cargoPlaces[${cargoPlaceIndex}].weight`
                                : 'weight'
                            }
                          >
                            {({
                              field: { value, ...field },
                              meta,
                            }: FieldProps) => (
                              <FormControl
                                error={
                                  !!(meta.touched && meta.error && value !== 0)
                                }
                              >
                                <InputLabel
                                  shrink={false}
                                  htmlFor={`input-weight-package-${cargoPlaceIndex}`}
                                >
                                  <Tooltip title={TEXT.GROSS}>
                                    <span>
                                      {Number.isInteger(cargoPlaceIndex)
                                        ? t('app.grossWeight')
                                        : t('app.weight')}
                                    </span>
                                  </Tooltip>
                                </InputLabel>
                                <Input
                                  disableUnderline
                                  id={`input-weight-package-${cargoPlaceIndex}`}
                                  {...field}
                                  inputProps={{
                                    type: 'number',
                                    step: 0.01,
                                    min: 0,
                                    max: MAX_PARCEL_WEIGHT,
                                  }}
                                  startAdornment={
                                    <InputAdornment position='end'>
                                      {t('app.kg')}
                                    </InputAdornment>
                                  }
                                  defaultValue={value}
                                  onChange={handlerChange}
                                  value={value}
                                />
                                {meta.touched && meta.error && value !== 0 && (
                                  <FormHelperText>{meta.error}</FormHelperText>
                                )}
                              </FormControl>
                            )}
                          </FastField>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <FastField
                            name={
                              Number.isInteger(cargoPlaceIndex)
                                ? `cargoPlaces[${cargoPlaceIndex}].length`
                                : 'length'
                            }
                          >
                            {({
                              field: { value, ...field },
                              meta,
                            }: FieldProps) => (
                              <FormControl
                                error={
                                  meta.touched && !!meta.error && value !== 0
                                }
                              >
                                <InputLabel
                                  shrink={false}
                                  htmlFor={`input-length-package-${cargoPlaceIndex}`}
                                >
                                  {t('app.length')}
                                </InputLabel>
                                <Input
                                  disableUnderline
                                  id={`input-length-package-${cargoPlaceIndex}`}
                                  {...field}
                                  inputProps={{
                                    type: 'number',
                                    min: 0,
                                    step: 0.1,
                                    max: MAX_PARCEL_DIMENSION,
                                  }}
                                  startAdornment={
                                    <InputAdornment position='end'>
                                      {t('app.cm')}
                                    </InputAdornment>
                                  }
                                  defaultValue={value}
                                  onChange={handlerChange}
                                  value={value}
                                />
                                {meta.touched &&
                                  !!meta.error &&
                                  value !== 0 && (
                                    <FormHelperText>
                                      {meta.error}
                                    </FormHelperText>
                                  )}
                              </FormControl>
                            )}
                          </FastField>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <FastField
                            name={
                              Number.isInteger(cargoPlaceIndex)
                                ? `cargoPlaces[${cargoPlaceIndex}].width`
                                : 'width'
                            }
                          >
                            {({
                              field: { value, ...field },
                              meta,
                            }: FieldProps) => (
                              <FormControl
                                error={
                                  meta.touched && !!meta.error && value !== 0
                                }
                              >
                                <InputLabel
                                  shrink={false}
                                  htmlFor={`input-width-package-${cargoPlaceIndex}`}
                                >
                                  {t('app.width')}
                                </InputLabel>
                                <Input
                                  disableUnderline
                                  id={`input-width-package-${cargoPlaceIndex}`}
                                  {...field}
                                  inputProps={{
                                    type: 'number',
                                    min: 0,
                                    step: 0.1,
                                    max: MAX_PARCEL_DIMENSION,
                                  }}
                                  startAdornment={
                                    <InputAdornment position='end'>
                                      {t('app.cm')}
                                    </InputAdornment>
                                  }
                                  defaultValue={value}
                                  onChange={handlerChange}
                                  value={value}
                                />
                                {meta.touched &&
                                  !!meta.error &&
                                  value !== 0 && (
                                    <FormHelperText>
                                      {meta.error}
                                    </FormHelperText>
                                  )}
                              </FormControl>
                            )}
                          </FastField>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <FastField
                            name={
                              Number.isInteger(cargoPlaceIndex)
                                ? `cargoPlaces[${cargoPlaceIndex}].height`
                                : 'height'
                            }
                          >
                            {({
                              field: { value, ...field },
                              meta,
                            }: FieldProps) => (
                              <FormControl
                                error={
                                  meta.touched && !!meta.error && value !== 0
                                }
                              >
                                <InputLabel
                                  shrink={false}
                                  htmlFor={`input-height-package-${cargoPlaceIndex}`}
                                >
                                  {t('app.height')}
                                </InputLabel>
                                <Input
                                  disableUnderline
                                  id={`input-height-package-${cargoPlaceIndex}`}
                                  {...field}
                                  inputProps={{
                                    type: 'number',
                                    min: 0,
                                    step: 0.1,
                                    max: MAX_PARCEL_DIMENSION,
                                  }}
                                  startAdornment={
                                    <InputAdornment position='end'>
                                      {t('app.cm')}
                                    </InputAdornment>
                                  }
                                  defaultValue={value}
                                  onChange={handlerChange}
                                  value={value}
                                />
                                {meta.touched &&
                                  !!meta.error &&
                                  value !== 0 && (
                                    <FormHelperText>
                                      {meta.error}
                                    </FormHelperText>
                                  )}
                              </FormControl>
                            )}
                          </FastField>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                ),
            )}

            <Box mt={1}>
              <Button
                startIcon={<AddIcon />}
                endIcon={<ArchiveIcon />}
                className={classes.addParcelAction}
                variant='contained'
                onClick={(e) => {
                  e.preventDefault();
                  helpers.push(getInitialCargoPlace());
                }}
              >
                {t('app.addPackageNumber')} {cargoPlaces?.length + 1}
              </Button>
            </Box>
          </div>
        )}
      />
      <ModalBox
        isOpen={
          isOpenModalMyPackages &&
          (!!editingPackageIndex || editingPackageIndex === 0)
        }
        setOpen={setIsOpenModalMyPackages}
      >
        {!!cachedPackages?.getPackagingTemplatesList?.rows?.length && (
          <Autocomplete
            options={
              cachedPackages?.getPackagingTemplatesList
                ?.rows as PackagingTemplate[]
            }
            getOptionLabel={(option) => {
              return `${option?.name || ''} [${option?.length}x${
                option?.width
              }x${option?.height}] ${
                option?.weight ? option?.weight + ` ${t('app.kg')}` : ''
              } `;
            }}
            renderInput={(params) => (
              <div ref={params.InputProps.ref}>
                <TextField
                  label={t('app.selectPackageItem')}
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'my_packages',
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='end'>
                        <ViewModuleIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            )}
            onChange={(e, newValue) => {
              const id = newValue?.id && +newValue?.id ? +newValue?.id : null;
              if (id) {
                const packageSelected =
                  cachedPackages?.getPackagingTemplatesList?.rows?.find(
                    (p) => p?.id && +p.id === +id,
                  );
                if (packageSelected && setValues) {
                  // @ts-ignore
                  setValues((prevState) => {
                    const newCargoPlaces = prevState?.cargoPlaces?.map(
                      (p: any, i: any) => {
                        if (i === editingPackageIndex) {
                          return {
                            ...p,
                            height: packageSelected.height || 0,
                            length: packageSelected.length || 0,
                            weight: packageSelected.weight || 0,
                            width: packageSelected.width || 0,
                          };
                        }

                        return p;
                      },
                    );

                    return {
                      ...prevState,
                      cargoPlaces: newCargoPlaces,
                    };
                  });

                  setUpdateView &&
                    setUpdateView((prevState: any) => prevState + 1);
                }
              }
            }}
          />
        )}
      </ModalBox>
    </>
  );
};

export default SendingToWarehouseFormStep2;
