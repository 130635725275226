import {
  CreateWarehouseShipmentOrderInput,
  Vatkey,
} from '../../../../generated/graphql';

export const transformDeliveryOptionsBeforyQuery = (
  values: CreateWarehouseShipmentOrderInput,
) => {
  const deliveryOptionsInput = {
    warehouseId: values.warehouseId,
    products: values.products?.map((product) => ({
      id: product?.id,
      quantity: product?.quantity,
      price: product?.price,
      nameEN: product?.nameEN,
      code: product?.code,
      countryId: product?.countryId,
      weight: product?.weight,
    })),

    receiver: {
      id: Number(values.receiver.id),
      address: values?.receiver?.address,
      address2: values?.receiver?.address2,
      address3: values?.receiver?.address3,
      city: values?.receiver?.city,
      company: values?.receiver?.company,
      countryId: values?.receiver?.countryId,
      email: values?.receiver?.email,
      eoriCode: values?.receiver?.eoriCode,
      innKppCode: values?.receiver?.innKppCode,
      name: values?.receiver?.name,
      phone: values?.receiver?.phone,
      remark: values?.receiver?.remark,
      state: values?.receiver?.state,
      zipCode: values?.receiver?.zipCode,
    },
    wrap: values.wrap as string,
    ...(values.message && { message: values.message }),
    additionalInsurance: values.additionalInsurance,
    ...(values.additionalInsurance && {
      insuranceAmount: Number(values.insuranceAmount),
    }),
    signature: values.signature,
    only_calculation: false,
    address_validation: false,
    ...(values.vatKey !== Vatkey.Empty && { vatKey: values.vatKey }),
    ...(values.vatValue && { vatValue: values.vatValue }),
    giftWrap: values.giftWrap,
  };

  return deliveryOptionsInput;
};
