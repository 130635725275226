import React, { useState, useEffect } from 'react';
import {
  Button,
  Box,
  Container,
  Divider,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Switch,
  TableHead,
} from '@material-ui/core';

import { useLazyQuery, useMutation } from '@apollo/client';
import { getMarketplaceImageSrc } from '../../../utils/helpers';
import { QUERY_GENERATE_URL_AUTHORIZATION_ETSY } from '../../../GraphQL/queries/generateUrlForAuthorizationInEtsy';
import { QUERY_GENERATE_URL_AUTHORIZATION_EBAY } from '../../../GraphQL/queries/generateUrlForAuthorizationInEbay';
import { GENERATE_URL_FOR_AUTHORIZATION_IN_AMAZON } from '../../../GraphQL/queries/generateUrlForAuthorizationInAmazon';
import { MUTATION_SWITCH_STORE_STATUS } from '../../../GraphQL/mutations/switchStoreStatus';
import { QUERY_MY_STORES } from '../../../GraphQL/queries/getMyStores';
import { GET_REGIONS_CODES } from '../../../GraphQL/queries/getRegionsCodes';
import { MarketplaceInput } from '../../../interfaces';
import { useSnackbar } from 'notistack';
import styles from './styles';
import { ModalSelectMarketplace } from './ModalSelectMarketplace';
import CreateIcon from '@material-ui/icons/Create';
import CloseIcon from '@material-ui/icons/Close';
import {
  useRemoveStoreMutation,
  useUpdateStoreMutation,
} from '../../../generated/graphql';
import { useInitValue } from './useInitValue';
import { ModalDeleete } from './ModalDelete';
import { useTranslation } from 'react-i18next';

const MyMarketplaces: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [selectedMarketplace, setSelectedMarketplace] = useState('');
  const [marketplaceData, setMarketplaceData] = useState<any>();
  const [isOpen, setIsOpen] = useState(false);
  const { isOpenDelete, setIsOpenDelete, deleteId, setDeleteId } = useDelete();

  const { t } = useTranslation();

  const [typeForm, setTypeForm] = useState('addForm');
  const classes = styles();

  const { initialValues, setInitialValues } = useInitValue(selectedMarketplace);

  const [getEbayUrl, { error: errorEbayUrl, data: dataEbayUrl }] = useLazyQuery(
    QUERY_GENERATE_URL_AUTHORIZATION_EBAY,
    {
      fetchPolicy: 'cache-and-network',
      variables: { ...marketplaceData },
    },
  );

  const [getEtsyUrl, { error: errorEtsyUrl, data: dataEtsyUrl }] = useLazyQuery(
    QUERY_GENERATE_URL_AUTHORIZATION_ETSY,
    {
      fetchPolicy: 'cache-and-network',
      variables: { ...marketplaceData },
    },
  );

  const [getAmazonUrl, { error: errorAmazonUrl, data: dataAmazonUrl }] =
    useLazyQuery(GENERATE_URL_FOR_AUTHORIZATION_IN_AMAZON, {
      fetchPolicy: 'cache-and-network',
      variables: { ...marketplaceData },
    });

  const [getMyStores, { error: errorMyStores, data: dataMyStores }] =
    useLazyQuery(QUERY_MY_STORES, {
      fetchPolicy: 'cache-and-network',
    });

  const [
    getRegionsCodes,
    { error: errorRegionsCodes, data: dataRegionsCodes },
  ] = useLazyQuery(GET_REGIONS_CODES, {
    fetchPolicy: 'cache-and-network',
  });

  const [removeStoreMutation] = useRemoveStoreMutation();
  const [updateStoreMutation] = useUpdateStoreMutation();

  const handleRemove = (id: any) => {
    removeStoreMutation({
      variables: {
        marketplaceId: id,
      },
    })
      .then(() => {
        getMyStores();
      })
      .catch((error) => {
        console.info(error);
      });
  };

  const [switchStoreStatus, { data: dataSwitchStoreStatus }] = useMutation(
    MUTATION_SWITCH_STORE_STATUS,
  );

  useEffect(() => {
    if (!dataMyStores) {
      getMyStores();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (errorMyStores) {
      enqueueSnackbar(t('app.failedToGetConnectedMarketplaces'), {
        variant: 'error',
      });
    }
    // eslint-disable-next-line
  }, [errorMyStores]);

  const handleSubmit = (value: MarketplaceInput) => {
    if (value) {
      const name = value.name ? { name: value.name } : {};
      const url = value.url ? { url: value.url } : {};
      const storeId = value.storeId ? { storeId: +value.storeId } : {};
      const region = value.region ? { region: value.region } : {};
      const merchantId = value.merchantId
        ? { merchantId: value.merchantId }
        : {};

      const data = {
        ...name,
        ...url,
        ...storeId,
        ...region,
        ...merchantId,
      };
      if (typeForm === 'addForm') {
        setMarketplaceData(data);
      } else {
        setIsOpen(false);
        updateStoreMutation({
          variables: {
            input: {
              name: value?.name,
              isActive: value?.isActive as boolean,
              url: value?.url,
              shopId: value?.shopId,
              marketplaceId: value?.marketplaceId as number,
            },
          },
        }).catch((error) => {
          console.info(error);
        });
      }
    }
  };

  useEffect(() => {
    if (marketplaceData && selectedMarketplace?.toLowerCase() === 'ebay') {
      getEbayUrl();
    }
    if (marketplaceData && selectedMarketplace?.toLowerCase() === 'etsy') {
      getEtsyUrl();
    }
    if (marketplaceData && selectedMarketplace?.toLowerCase() === 'amazon') {
      getAmazonUrl();
    }
    // eslint-disable-next-line
  }, [marketplaceData]);

  useEffect(() => {
    if (dataEbayUrl?.generateUrlForAuthorizationInEbay) {
      window.location.assign(dataEbayUrl.generateUrlForAuthorizationInEbay);
    }
  }, [dataEbayUrl]);

  useEffect(() => {
    if (dataEtsyUrl?.generateUrlForAuthorizationInEtsy) {
      window.location.assign(dataEtsyUrl.generateUrlForAuthorizationInEtsy);
    }
  }, [dataEtsyUrl]);

  useEffect(() => {
    if (dataAmazonUrl?.generateUrlForAuthorizationInAmazon) {
      window.location.assign(dataAmazonUrl.generateUrlForAuthorizationInAmazon);
    }
  }, [dataAmazonUrl]);

  const generateUrlErrorMsg = (marketplace: string) => {
    enqueueSnackbar(
      `${t('app.errorCreatingLinkToMarketplace')}: ${marketplace}`,
      { variant: 'error' },
    );
  };

  useEffect(() => {
    if (errorEbayUrl) {
      generateUrlErrorMsg(selectedMarketplace);
    }
    // eslint-disable-next-line
  }, [errorEbayUrl]);

  useEffect(() => {
    if (errorEtsyUrl) {
      generateUrlErrorMsg(selectedMarketplace);
    }
    // eslint-disable-next-line
  }, [errorEtsyUrl]);

  useEffect(() => {
    if (errorAmazonUrl) {
      generateUrlErrorMsg(selectedMarketplace);
    }
    // eslint-disable-next-line
  }, [errorAmazonUrl]);

  const handleDeleteMarketplace = (id: string | number | null) => {
    if (id) {
      switchStoreStatus({ variables: { storeId: id } });
    }
  };

  useEffect(() => {
    if (!dataRegionsCodes) {
      getRegionsCodes();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (errorRegionsCodes) {
      enqueueSnackbar(
        `${t('app.errorGettingListOfRegions')}: ${errorRegionsCodes}`,
        { variant: 'error' },
      );
    }
    // eslint-disable-next-line
  }, [errorRegionsCodes]);

  const showInputListForCreateLink = [
    {
      type: 'ebay',
      isShow: ['name'],
    },
    {
      type: 'etsy',
      isShow: ['name', 'url', 'storeId'],
    },
    {
      type: 'amazon',
      isShow: ['name', 'region', 'merchantId'],
    },
  ];

  const checkIsShowInput = (inputName: string) => {
    if (typeForm === 'addForm') {
      if (
        showInputListForCreateLink.some(
          (item) =>
            item.type.trim().toLowerCase() ===
              selectedMarketplace.trim().toLowerCase() &&
            item.isShow.some(
              (item) =>
                item.trim().toLowerCase() === inputName.trim().toLowerCase(),
            ),
        )
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return showInputListForCreateLink.some((item: any) => {
        if (
          selectedMarketplace.trim().toLowerCase().indexOf(item.type) !== -1
        ) {
          return item.isShow.some(
            (item: any) =>
              item.trim().toLowerCase() === inputName.trim().toLowerCase(),
          );
        } else {
          return false;
        }
      });
    }
  };

  useEffect(() => {
    getMyStores();
    // eslint-disable-next-line
  }, [dataSwitchStoreStatus]);

  return (
    <Container>
      <Typography variant='h2' align='center'>
        {t('app.stores').toUpperCase()}
      </Typography>
      <Divider orientation='horizontal' />
      <div>{t('app.connectYourStore')}</div>
      <Button
        variant='contained'
        onClick={() => {
          setInitialValues({
            name: '',
            url: '',
            storeId: null,
            region: '',
            merchantId: '',
          });
          setTypeForm('addForm');
          setIsOpen(true);
        }}
      >
        {t('app.connect')}
      </Button>

      <hr className={classes.horizontalLine} />

      {dataMyStores?.getMyStores.length ? (
        <Box>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t('app.marketplace')}</TableCell>
                  <TableCell align='center'>{t('app.name')}</TableCell>
                  <TableCell align='center'>{t('app.active')}</TableCell>
                  <TableCell align='right'>{t('app.actions')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataMyStores?.getMyStores.map((item: any, index: number) => (
                  <TableRow key={index}>
                    <TableCell className={classes.tableLeftPart}>
                      <img
                        className={classes.marketplaceSmallImg}
                        src={getMarketplaceImageSrc(
                          item.marketplace.toLowerCase(),
                        )}
                        alt={item.marketplace}
                      />
                    </TableCell>
                    <TableCell className={classes.tableCenterPart}>
                      <p>{item.name}</p>
                    </TableCell>
                    <TableCell className={classes.tableCenterPart}>
                      <Switches
                        value={item?.isActive}
                        handleDeleteMarketplace={handleDeleteMarketplace}
                        id={item?.id}
                      />
                    </TableCell>
                    <TableCell className={classes.tableRightPart}>
                      <CreateIcon
                        style={{ cursor: 'pointer', marginRight: '10px' }}
                        onClick={() => {
                          setSelectedMarketplace(item?.name);
                          setTypeForm('editForm');
                          setInitialValues({
                            name: item?.name,
                            url: item?.url,
                            storeId: item?.storeId,
                            region: item?.region,
                            merchantId: item?.merchantId,
                            isActive: item?.isActive,
                            marketplaceId: item?.id,
                          });
                          setIsOpen(true);
                        }}
                      />
                      <CloseIcon
                        style={{ cursor: 'pointer', marginRight: '10px' }}
                        onClick={() => {
                          setIsOpenDelete(true);
                          setDeleteId(item?.id);
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      ) : null}
      <ModalSelectMarketplace
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        selectedMarketplace={selectedMarketplace}
        initialValues={initialValues}
        handleSubmit={handleSubmit}
        checkIsShowInput={checkIsShowInput}
        dataRegionsCodes={dataRegionsCodes}
        setSelectedMarketplace={setSelectedMarketplace}
      />
      <ModalDeleete
        isOpen={isOpenDelete}
        setIsOpen={setIsOpenDelete}
        deleteId={deleteId}
        setDeleteId={setDeleteId}
        handleRemove={handleRemove}
      />
    </Container>
  );
};

export default MyMarketplaces;

type SwitchesTypes = {
  value: boolean;
  handleDeleteMarketplace: (value: string) => void;
  id: any;
};

const Switches = ({ value, handleDeleteMarketplace, id }: SwitchesTypes) => {
  const [checked, setChecked] = useState(value);

  const ToggleChecked = () => {
    setChecked((prev) => !prev);
    handleDeleteMarketplace(id);
  };

  return <Switch checked={checked} onChange={ToggleChecked} />;
};

const useDelete = () => {
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  useEffect(() => {
    if (!isOpenDelete) {
      setDeleteId(null);
    }
  }, [isOpenDelete]);

  return { isOpenDelete, setIsOpenDelete, deleteId, setDeleteId };
};
