import { TableCell, TableRow } from '@material-ui/core';
import React from 'react';
import { ShowLoadingText } from '../../utils/helperComponents';
import { useTranslation } from 'react-i18next';

interface ITableBodyRequestHandler {
  dataLength: number;
  colSpan: number;
  loading: boolean;
  children: JSX.Element[] | null;
  loadingText?: string;
}

const TableBodyRequestHandler = ({
  dataLength,
  colSpan,
  loading,
  children,
  loadingText = '',
}: ITableBodyRequestHandler): JSX.Element => {
  const { t } = useTranslation();

  if (loading) {
    return (
      <TableRow>
        <TableCell colSpan={colSpan} align='center'>
          <ShowLoadingText name={loadingText} />
        </TableCell>
      </TableRow>
    );
  }

  if (!children || !dataLength) {
    return (
      <TableRow>
        <TableCell colSpan={colSpan} align='center'>
          {t('app.noData2')}
        </TableCell>
      </TableRow>
    );
  }

  return <>{children}</>;
};

export default TableBodyRequestHandler;
