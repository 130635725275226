import { gql } from '@apollo/client';

export const ENTITY_LEGAL_DATA = gql`
  fragment FragmentEntityLegalData on EntityLegalData {
    id
    company
    inn
    kpp
    MSRN
    generalManager
    legalAddress
    nameOfTheBank
    BIK
    paymentAccount
    correspondentAccount
    fileName
    userId
    isDataVerified
  }
`;
