import React, { CSSProperties } from 'react';
import { Link } from 'react-router-dom';

const RouterLink: React.FC<{
  to: string;
  style?: CSSProperties;
  children?: React.ReactNode;
}> = ({ to, style, children }) => {
  return (
    <Link to={to} style={style}>
      {children}
    </Link>
  );
};

export default RouterLink;
