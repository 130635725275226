import React from 'react';
import { Typography } from '@material-ui/core';
import InsertEmoticonIcon from '@material-ui/icons/InsertEmoticon';
import { useTranslation } from 'react-i18next';

export default () => {
  const { t } = useTranslation();

  return (
    <>
      <InsertEmoticonIcon style={{ fontSize: 48, color: '#ffc749' }} />
      <Typography variant='h2'>{t('app.thankYou')}!</Typography>
      <p>{t('app.paymentSuccessMessage')}</p>
    </>
  );
};
