import { FormControl, MenuItem, Select } from '@material-ui/core';
import React from 'react';
import { BoxCentered } from '../../BoxCentered/BoxCentered';
import useStyles from '../styles';
import { useTranslation } from 'react-i18next';
import { IFilters } from '../../../utils/types';

const FilterSelectLimitItems: React.FC<{
  value: number;
  setFilter: React.Dispatch<React.SetStateAction<IFilters>>;
  localStorageItemsLimitKey: string;
}> = ({ value, setFilter, localStorageItemsLimitKey }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <FormControl className={classes.filtersItemSelect}>
      <BoxCentered pl={2}>
        <span className={classes.filtersSelectLimitWrapper}>
          {t('app.show')}
        </span>
        <Select
          displayEmpty
          disableUnderline
          value={value}
          onChange={(e) => {
            localStorage.setItem(
              localStorageItemsLimitKey,
              e.target.value as string,
            );
            setFilter((state) => ({
              ...state,
              itemsLimit: e.target.value as number,
              page: 1,
            }));
          }}
        >
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={20}>20</MenuItem>
          <MenuItem value={50}>50</MenuItem>
          <MenuItem value={100}>100</MenuItem>
          <MenuItem value={500}>500</MenuItem>
        </Select>
      </BoxCentered>
    </FormControl>
  );
};

export default FilterSelectLimitItems;
