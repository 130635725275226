import { makeStyles } from '@material-ui/core/styles';

export default (isSidebarHidden?: boolean) =>
  makeStyles(() => ({
    list: {
      marginTop: '6px',
      color: '#fff',
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 500,
      padding: 0,
    },
    listSub: {
      marginTop: '0px',
      lineHeight: '20px',
      fontWeight: 400,
    },
    listItem: {
      display: 'flex',
      marginTop: 15,
      padding: 0,
    },
    listItemLink: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      width: '100%',
      padding: '10px 32px',
      fontSize: isSidebarHidden ? 9 : 14,
      color: 'inherit',
      textDecoration: 'none',
      textTransform: 'uppercase',
      whiteSpace: 'nowrap',
    },
    listItemLinkIcon: {
      marginRight: isSidebarHidden ? 0 : '10px',
    },

    listItemLinkImage: {
      width: '24px',
      height: '24px',
      marginRight: isSidebarHidden ? 0 : '10px',
    },
    listItemLinkActive: {
      backgroundColor: '#4f4f4f',
    },
    listItemSub: {
      fontWeight: 400,
      fontSize: '14px',
      marginTop: 7,
      textTransform: 'capitalize',

      '& $listItemLink': {
        textTransform: 'none',
        padding: isSidebarHidden ? '5px 0' : '5px 32px',
        justifyContent: isSidebarHidden ? 'center' : 'flex-start',
        textAlign: 'center',
      },
    },
    listItemDisabled: {
      padding: '10px 32px',
      color: '#4f4f4f',
      textTransform: 'uppercase',
    },
    listItemSubList: {
      padding: '10px 32px',
      textTransform: 'uppercase',
      fontSize: isSidebarHidden ? 10 : 14,
    },
  }))();
