import React from 'react';

import { FastField } from 'formik';
import {
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
} from '@material-ui/core';
import InputMask from 'react-input-mask';
import MaterialInput from '@material-ui/core/Input';

type FastFieldCustomType = {
  setFieldValue: (field: string, value: number | string | boolean) => void;
  name: string;
  label: string;
  maskType?: string;
};

export const FastFieldCustom = ({
  setFieldValue,
  name,
  label,
  maskType,
}: FastFieldCustomType) => {
  return (
    <FastField name={name}>
      {(props: any) => {
        const {
          field: { value, ...field },
          meta,
        } = props;
        return (
          <FormControl error={meta.touched && !!meta.error}>
            <InputLabel>{label}</InputLabel>
            {maskType ? (
              <InputMask
                mask={maskType}
                value={props.value}
                onChange={(e) => {
                  setFieldValue(name, e.target.value.replaceAll('_', ''));
                }}
                defaultValue={value}
              >
                {/* @ts-ignore */}
                {(inputProps: any) => (
                  <MaterialInput {...inputProps} type='tel' disableUnderline />
                )}
              </InputMask>
            ) : (
              <Input
                disableUnderline
                defaultValue={value}
                {...field}
                onChange={(e) => setFieldValue(name, e.target.value)}
              />
            )}

            {meta.touched && meta.error && (
              <FormHelperText>{meta.error}</FormHelperText>
            )}
          </FormControl>
        );
      }}
    </FastField>
  );
};
