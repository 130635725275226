import React, { useEffect, useState } from 'react';
import {
  Button,
  Container,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { BoxCentered } from '../../../components/BoxCentered/BoxCentered';
import { PostAdd } from '@material-ui/icons';
import EditIcon from '@material-ui/icons/Edit';
import { COLORS } from '../../../utils/constants';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ModalBox from '../../../components/ModalBox/ModalBox';
import MyPackageForm from '../../../components/MyPackageForm/MyPackageForm';
import {
  GetPackagingTemplatesListQuery,
  GetPackagingTemplatesListQueryVariables,
  PackagingTemplate,
  useGetPackagingTemplatesListLazyQuery,
  useRemovePackagingTemplateMutation,
} from '../../../generated/graphql';
import { useSnackbar } from 'notistack';
import { QUERY_PACKAGING_TEMPLATE_LIST } from '../../../GraphQL/queries/getPackagingTemplatesList';
import { useApolloClient } from '@apollo/client';
import { useTranslation } from 'react-i18next';

const MyPackagesList: React.FC = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [editingPackage, setEditingPackage] =
    useState<null | PackagingTemplate>(null);
  const { enqueueSnackbar } = useSnackbar();
  const client = useApolloClient();
  const { t } = useTranslation();

  const [myPackages, setMyPackages] = useState<PackagingTemplate[]>([]);

  const [getMyPackagesLazy, { data: dataPackages, error }] =
    useGetPackagingTemplatesListLazyQuery({
      fetchPolicy: 'network-only',
    });

  const cachedPackages = client.readQuery<
    GetPackagingTemplatesListQuery,
    GetPackagingTemplatesListQueryVariables
  >({
    query: QUERY_PACKAGING_TEMPLATE_LIST,
  });

  useEffect(() => {
    if (cachedPackages?.getPackagingTemplatesList?.rows?.length) {
      setMyPackages(
        cachedPackages?.getPackagingTemplatesList?.rows as PackagingTemplate[],
      );
    }
    // eslint-disable-next-line
  }, [cachedPackages]);

  error?.message && enqueueSnackbar(error?.message, { variant: 'error' });

  const [removeMyPackage] = useRemovePackagingTemplateMutation();

  useEffect(() => {
    if (dataPackages?.getPackagingTemplatesList?.rows?.length) {
      setMyPackages(
        dataPackages?.getPackagingTemplatesList?.rows as PackagingTemplate[],
      );
    }
    // eslint-disable-next-line
  }, [dataPackages]);

  const handleRemove = (id: number) => {
    if (id) {
      removeMyPackage({
        variables: {
          id,
        },
      })
        .then((value) => {
          if (value?.data?.removePackagingTemplate) {
            getMyPackagesLazy();
            enqueueSnackbar(t('app.packageSuccessfullyDeleted'), {
              variant: 'success',
            });
          }
        })
        .catch((reason) => {
          console.error(reason);
          reason?.message &&
            enqueueSnackbar(reason?.message, { variant: 'error' });
        });
    }
  };

  return (
    <Container>
      <Typography variant='h2' align='center'>
        {t('app.myPackaging').toUpperCase()}
      </Typography>
      <Divider />
      <br />
      <Typography variant='body1'>{t('app.createList')}</Typography>

      <BoxCentered mt={2} mb={2} justifyContent='flex-end'>
        <Button
          variant='contained'
          startIcon={<PostAdd />}
          onClick={() => {
            setEditingPackage(null);
            setIsOpenModal(true);
          }}
        >
          {t('app.add')}
        </Button>
      </BoxCentered>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align='center'>{t('app.name')}</TableCell>
              <TableCell align='center'>{t('app.dimensions')}</TableCell>
              <TableCell align='center'>
                {t('app.weight')}, {t('app.kg')}
              </TableCell>
              <TableCell align='center'>{t('app.action')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {myPackages.length ? (
              myPackages.map((packageItem) => {
                return (
                  packageItem && (
                    <TableRow>
                      <TableCell align='center'>{packageItem.name}</TableCell>
                      <TableCell align='center'>
                        {packageItem.length}x{packageItem.width}x
                        {packageItem.height}
                      </TableCell>
                      <TableCell align='center'>{packageItem.weight}</TableCell>
                      <TableCell align='center'>
                        <BoxCentered>
                          <IconButton
                            edge='start'
                            onClick={() => {
                              setEditingPackage(packageItem);
                              setIsOpenModal(true);
                            }}
                          >
                            <Tooltip title='Редактировать'>
                              <EditIcon style={{ color: COLORS.ORANGE }} />
                            </Tooltip>
                          </IconButton>

                          <IconButton
                            edge='start'
                            onClick={() => {
                              packageItem.id && handleRemove(+packageItem.id);
                            }}
                          >
                            <Tooltip title='Удалить'>
                              <DeleteForeverIcon
                                style={{ color: COLORS.RED }}
                              />
                            </Tooltip>
                          </IconButton>
                        </BoxCentered>
                      </TableCell>
                    </TableRow>
                  )
                );
              })
            ) : (
              <TableRow>
                <TableCell align='center' colSpan={4}>
                  {t('app.noData2')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <ModalBox isOpen={isOpenModal} setOpen={setIsOpenModal}>
        <MyPackageForm
          editingPackage={editingPackage}
          setEditingPackage={setEditingPackage}
          getMyPackagesLazy={getMyPackagesLazy}
          setIsOpenModal={setIsOpenModal}
        />
      </ModalBox>
    </Container>
  );
};

export default MyPackagesList;
