import React from 'react';
import ModalBox from '../../../components/ModalBox/ModalBox';
import {
  Button,
  Box,
  Grid,
  Tooltip,
  Typography,
  FormControl,
  Input,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import styles from './styles';
import { Form, Formik, Field } from 'formik';
import { BoxCentered } from '../../../components/BoxCentered/BoxCentered';
import imgAmazon from '../../../assets/img/marketplaces/amazon.png';
import imgEbay from '../../../assets/img/marketplaces/ebay.png';
import imgEtsy from '../../../assets/img/marketplaces/etsy.png';

import { MarketplaceInput } from '../../../interfaces';
import { useTranslation } from 'react-i18next';

type ModalSelectMarketplaceType = {
  isOpen: boolean;
  setIsOpen: (value: any) => void;
  selectedMarketplace: string;
  initialValues: MarketplaceInput;
  handleSubmit: (value: MarketplaceInput) => void;
  checkIsShowInput: (value: string) => boolean;
  dataRegionsCodes: any;
  setSelectedMarketplace: (value: string) => void;
};

export const ModalSelectMarketplace = ({
  isOpen,
  setIsOpen,
  selectedMarketplace,
  initialValues,
  handleSubmit,
  checkIsShowInput,
  dataRegionsCodes,
  setSelectedMarketplace,
}: ModalSelectMarketplaceType) => {
  const classes = styles();
  const { t } = useTranslation();

  return (
    <ModalBox isOpen={isOpen} setOpen={setIsOpen}>
      {selectedMarketplace ? (
        <Box>
          <Typography
            variant='h3'
            align='center'
            className={classes.modalFormTile}
          >
            {t('app.connectStoreTo')} {selectedMarketplace}
          </Typography>
          {selectedMarketplace === 'Etsy' ? (
            <Typography variant='h3' className={classes.modalFormTileEtsy}>
              The term 'Etsy' is a trademark of Etsy, Inc.
              <br /> This application uses the Etsy API but is not endorsed or
              certified by Etsy, Inc.
            </Typography>
          ) : null}
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue }) => {
              return (
                <Form noValidate className={classes.modalFormWrap}>
                  {checkIsShowInput('name') ? (
                    <Field name='name'>
                      {() => (
                        <FormControl className={classes.formControl}>
                          <InputLabel shrink={false} htmlFor='name'>
                            {t('app.storeName')}
                          </InputLabel>
                          <Input
                            disableUnderline
                            fullWidth
                            required
                            defaultValue={values.name}
                            value={values.name}
                            onChange={(e) =>
                              setFieldValue('name', e.target.value)
                            }
                            id='name'
                          />
                        </FormControl>
                      )}
                    </Field>
                  ) : null}
                  {checkIsShowInput('url') ? (
                    <Field name='url'>
                      {() => (
                        <FormControl className={classes.formControl}>
                          <InputLabel shrink={false} htmlFor='url'>
                            {t('app.storeLink')}
                          </InputLabel>
                          <Input
                            disableUnderline
                            fullWidth
                            required
                            defaultValue={values.url}
                            onChange={(e) =>
                              setFieldValue('url', e.target.value)
                            }
                            id='url'
                          />
                        </FormControl>
                      )}
                    </Field>
                  ) : null}
                  {checkIsShowInput('storeId') ? (
                    <Field name='storeId'>
                      {() => (
                        <FormControl className={classes.formControl}>
                          <InputLabel shrink={false} htmlFor='storeId'>
                            {selectedMarketplace} shop ID
                          </InputLabel>
                          <Input
                            disableUnderline
                            fullWidth
                            required
                            defaultValue={values.storeId}
                            onChange={(e) =>
                              setFieldValue('storeId', e.target.value)
                            }
                            id='storeId'
                          />
                        </FormControl>
                      )}
                    </Field>
                  ) : null}

                  {checkIsShowInput('region') ? (
                    <Field name='region'>
                      {() => (
                        <FormControl className={classes.formControl}>
                          <InputLabel shrink={false} htmlFor='region'>
                            {t('app.region')}
                          </InputLabel>
                          <Select
                            displayEmpty
                            disableUnderline
                            value={values.region}
                            onChange={(e) =>
                              setFieldValue('region', e.target.value)
                            }
                            id='region'
                          >
                            <MenuItem value='' disabled>
                              {t('app.region')}
                            </MenuItem>
                            {dataRegionsCodes?.getRegionsCodes?.map(
                              (region: string) => (
                                <MenuItem value={region}>{region}</MenuItem>
                              ),
                            )}
                          </Select>
                        </FormControl>
                      )}
                    </Field>
                  ) : null}

                  {checkIsShowInput('merchantId') ? (
                    <Field name='merchantId'>
                      {() => (
                        <FormControl className={classes.formControl}>
                          <InputLabel shrink={false} htmlFor='merchantId'>
                            {t('app.sellerID')}
                          </InputLabel>
                          <Input
                            disableUnderline
                            fullWidth
                            required
                            defaultValue={values.merchantId}
                            onChange={(e) =>
                              setFieldValue('merchantId', e.target.value)
                            }
                            id='merchantId'
                          />
                        </FormControl>
                      )}
                    </Field>
                  ) : null}

                  <BoxCentered>
                    <Button
                      type='submit'
                      className={classes.modalBtn}
                      variant='contained'
                    >
                      {t('app.connect')}
                    </Button>
                    <Button
                      onClick={() => setSelectedMarketplace('')}
                      className={classes.modalBtn}
                      variant='contained'
                    >
                      {t('app.back')}
                    </Button>
                  </BoxCentered>
                </Form>
              );
            }}
          </Formik>
        </Box>
      ) : (
        <>
          <BoxCentered sx={{ fontSize: 24, fontWeight: 600 }}>
            {t('app.selectMarketplace')}
          </BoxCentered>
          <BoxCentered sx={{ fontSize: 22, marginBottom: '24px' }}>
            {t('app.chooseEcommercePlatform')}
          </BoxCentered>
          <Grid container spacing={5}>
            <Grid item xs={12} sm={4}>
              <BoxCentered
                height='100%'
                onClick={() => setSelectedMarketplace('Amazon')}
              >
                <Tooltip title={t('app.connectStore', { value: 'Amazon' })}>
                  <img
                    className={classes.marketplaceImg}
                    src={imgAmazon}
                    alt='Amazon'
                  />
                </Tooltip>
              </BoxCentered>
            </Grid>
            <Grid item xs={12} sm={4}>
              <BoxCentered
                height='100%'
                onClick={() => setSelectedMarketplace('Ebay')}
              >
                <Tooltip title={t('app.connectStore', { value: 'Ebay' })}>
                  <img
                    className={classes.marketplaceImg}
                    src={imgEbay}
                    alt='Ebay'
                  />
                </Tooltip>
              </BoxCentered>
            </Grid>
            <Grid item xs={12} sm={4}>
              <BoxCentered
                height='100%'
                onClick={() => setSelectedMarketplace('Etsy')}
              >
                <Tooltip title={t('app.connectStore', { value: 'Etsy' })}>
                  <img
                    className={classes.marketplaceImg}
                    src={imgEtsy}
                    alt='Etsy'
                  />
                </Tooltip>
              </BoxCentered>
            </Grid>
          </Grid>

          {/* temporarily commented out until shops are available */}
          {/* <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <BoxCentered height='100%'>
                <img
                  className={classes.disableMarketplaceImg}
                  src={imgShopify}
                  alt='Shopify'
                />
              </BoxCentered>
            </Grid>
            <Grid item xs={12} sm={4}>
              <BoxCentered height='100%'>
                <img
                  className={classes.disableMarketplaceImg}
                  src={imgWooCommerce}
                  alt='WooCommerce'
                />
              </BoxCentered>
            </Grid>
            <Grid item xs={12} sm={4}>
              <BoxCentered height='100%'>
                <img
                  className={classes.disableMarketplaceImg}
                  src={imgBigCommerce}
                  alt='BigCommerce'
                />
              </BoxCentered>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <BoxCentered height='100%'>
                <img
                  className={classes.disableMarketplaceImg}
                  src={imgPayPal}
                  alt='PayPal'
                />
              </BoxCentered>
            </Grid>
            <Grid item xs={12} sm={4}>
              <BoxCentered height='100%'>
                <img
                  className={classes.disableMarketplaceImg}
                  src={imgWildBerries}
                  alt='WildBerries'
                />
              </BoxCentered>
            </Grid>
            <Grid item xs={12} sm={4}>
              <BoxCentered height='100%'>
                <img
                  className={classes.disableMarketplaceImg}
                  src={imgOzon}
                  alt='Ozon'
                />
              </BoxCentered>
            </Grid> 
          </Grid>*/}
        </>
      )}
    </ModalBox>
  );
};
