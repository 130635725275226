import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  filters: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: 25,
    marginTop: 25,

    [theme.breakpoints.down('xs')]: {
      justifyContent: 'space-between',
    },
  },

  mainTitle: {
    marginBottom: 32,
    textTransform: 'uppercase',

    [theme.breakpoints.down('sm')]: {
      fontSize: 23,
      marginBottom: 15,
    },
  },
}));
