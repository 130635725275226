import React from 'react';

import DeliveryOptionsComponent from '../../../components/DeliveryOptionsComponent/DeliveryOptionsComponent';

const WarehouseShipmentFormStep5: React.FC<{
  isForwarding?: boolean;
  values: any;
  setFieldValue: any;
}> = ({ values, setFieldValue, isForwarding }) => {
  return (
    <DeliveryOptionsComponent
      isForwarding={isForwarding}
      carrierCode={values.carrierCode}
      trackNumber={values.trackNumber}
      expectedArrivalDate={values.expectedArrivalDate}
      comment={values.comment}
      setFieldValue={setFieldValue}
    />
  );
};

export default WarehouseShipmentFormStep5;
