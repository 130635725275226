import { Box, SvgIconTypeMap, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { Maybe } from '../../../generated/graphql';
import { LOCAL_STORAGE_KEYS } from '../../../utils/constants';

const BoxComponent = ({
  title,
  count,
  Icon,
  isCurrency,
  iconUrl,
}: {
  title: string;
  count?: Maybe<number>;
  Icon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
  iconUrl?: string;
  isCurrency: boolean;
}) => {
  const { t } = useTranslation();
  const CURRENT_APP_CURRENCY = localStorage.getItem(
    LOCAL_STORAGE_KEYS.CURRENT_APP_CURRENCY,
  );
  const classes = useStyles();
  const countTitle = isCurrency ? `${count} ${CURRENT_APP_CURRENCY}` : count;
  return (
    <Box className={classes.boxWrapper}>
      <Typography className={classes.boxTitle}>{t(title)}</Typography>
      <Box className={classes.boxCountWrapper}>
        <Typography className={classes.boxCount}>{countTitle}</Typography>
        {iconUrl ? (
          <img src={iconUrl} alt={t(title)} className={classes.icon} />
        ) : (
          Icon && <Icon className={classes.icon} style={{ height: '72px' }} />
        )}
      </Box>
    </Box>
  );
};

export default BoxComponent;
