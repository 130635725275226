import { gql } from '@apollo/client';

export const QUERY_GENERATE_URL_AUTHORIZATION_ETSY = gql`
  query generateUrlForAuthorizationInEtsy(
    $name: String!
    $url: String
    $storeId: Int!
  ) {
    generateUrlForAuthorizationInEtsy(name: $name, url: $url, storeId: $storeId)
  }
`;
