import {
  ShipmentWarehouseStatus,
  WarehouseShipmentOrder,
} from '../../../generated/graphql';
import { TRoutes } from '../../../utils/helpers';
import SearchIcon from '@material-ui/icons/Search';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import iconBarcode from '../../../assets/img/icon_barcode.svg';
import { colorsEnum, deliveryExplainEnums } from '../../../utils/constants';

export const getActionOptions = (
  order: WarehouseShipmentOrder,
  isWithLabel: boolean,
  openModalForCancelHandler: () => void,
) => {
  return [
    {
      link: `${
        TRoutes.SHIPMENT_FROM_WAREHOUSE_DETAILS_WITHOUT_ID
      }${order?.id?.toString()}`,
      Icon: SearchIcon,
      title: 'app.view',
      color: colorsEnum.GREEN,
      tooltipText: 'app.clickToOpenViewShipments',
    },
    {
      link: `/api/getBarcode/${order?.barcodeId}`,
      imgSrc: iconBarcode,
      title: 'app.barcode',
      target: '_blank',
      tooltipText: 'app.clickToOpenBarcode',
    },
    {
      link: `/api/getReceipt/${isWithLabel ? 'for_admin_' : ''}${
        order.pathNameFile
      }`,
      Icon: SaveIcon,
      title: 'app.label',
      target: '_blank',
      disabled: !order.pathNameFile,
      tooltipText: 'app.clickToDownloadPDFLabel',
    },
    {
      link: `#`,
      Icon: CancelIcon,
      title: 'app.delete',
      onClick: openModalForCancelHandler,
      disabled:
        order.deliveryStatus?.explain !== deliveryExplainEnums.CREATED ||
        order?.warehouseStatus !== ShipmentWarehouseStatus.Unshipped,
      color: colorsEnum.RED,
      tooltipText: 'app.clickToDeleteOrder',
    },
  ];
};
