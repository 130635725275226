export const messages = {
  PROMPT_MAIL: 'app.enterEmailAddress',
  PROMPT_PASSWORD: 'app.enterPassword',
  REQUIRE_MESSAGE: 'app.mandatoryField',
  INCORRECT_SYMBOL: 'app.prohibitedSymbol',
  RUSSIAN_SYMBOL: 'app.onlyCyrillic',
  MIN_10_SYMBOL: 'app.minLength10Chars',
  MIN_11_SYMBOL: 'app.minLength11Chars',
  MIN_12_SYMBOL: 'app.minLength12Chars',
  DROPZONE: 'app.chooseFile',
};
