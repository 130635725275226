import { Box, CircularProgress } from '@material-ui/core';
import React from 'react';
//@ts-ignore
const RequestHandler: React.FC<{
  loading: boolean;
  children: React.ReactNode;
}> = ({ loading, children }) => {
  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }

  return children;
};

export default RequestHandler;
