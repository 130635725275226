import React from 'react';
import { Link, List, ListItem, Typography } from '@material-ui/core';
import { BoxCentered } from '../../BoxCentered/BoxCentered';
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import { RenderCountryFlagImage } from '../../../utils/helperComponents';
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import useStyles from '../ShimpentDetailsContact/styles';
import { Warehouse } from '../../../generated/graphql';
import HomeIcon from '@material-ui/icons/Home';
import BusinessIcon from '@material-ui/icons/Business';
import { LocationCity } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

const ShipmentDetailsWarehouse: React.FC<{
  type: 'receiver' | 'sender';
  contact: Warehouse;
}> = ({ type, contact }) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  return (
    <List>
      <ListItem>
        <Typography variant='h3'>
          {type === 'receiver' ? t('app.recipient') : t('app.sender')}
        </Typography>
      </ListItem>
      <ListItem className={classes.itemInfo}>
        <BoxCentered mr={2}>
          <AccessibilityIcon />
        </BoxCentered>
        {contact?.managerName}
      </ListItem>
      {contact?.company && (
        <ListItem className={classes.itemInfo}>
          <BoxCentered mr={2}>
            <BusinessIcon />
          </BoxCentered>
          {contact?.company}
        </ListItem>
      )}
      <ListItem className={classes.itemInfo}>
        <BoxCentered mr={2}>
          <HomeIcon />
        </BoxCentered>
        {contact?.address} {contact?.address2}
      </ListItem>

      <ListItem className={classes.itemInfo}>
        <BoxCentered mr={2}>
          <LocationCity />
        </BoxCentered>
        <Link
          target='_blank'
          rel='noopener noreferrer'
          href={`https://google.com/maps/search/${contact?.country?.name} ${contact?.city} ${contact?.state} ${contact?.address} ${contact?.address2} ${contact?.zipCode}`}
        >
          {contact?.city}, {contact?.state} {contact?.zipCode}
        </Link>
      </ListItem>

      <ListItem className={classes.itemInfo}>
        {contact?.country && (
          <RenderCountryFlagImage countryName={contact?.country?.name} />
        )}
        {i18n.resolvedLanguage === 'en'
          ? contact?.country?.nameEng
          : contact?.country?.name}
      </ListItem>

      {contact?.phone && (
        <ListItem className={classes.itemInfo}>
          <BoxCentered mr={2}>
            <PhoneInTalkIcon />
          </BoxCentered>
          <Link href={`tel:${contact.phone}`}>{contact.phone}</Link>
        </ListItem>
      )}
      {contact?.email && (
        <ListItem className={classes.itemInfo}>
          <BoxCentered mr={2}>
            <MailOutlineIcon />
          </BoxCentered>
          <Link href={`mailto:${contact.email}`}>{contact.email}</Link>
        </ListItem>
      )}
    </List>
  );
};

export default ShipmentDetailsWarehouse;
