import { useState, useEffect } from 'react';
import { MarketplaceInput } from '../../../interfaces';

export const useInitValue = (selectedMarketplace: any) => {
  const [initialValues, setInitialValues] = useState<MarketplaceInput>({
    name: selectedMarketplace,
    url: '',
    storeId: null,
    region: '',
    merchantId: '',
  });

  useEffect(() => {
    handleSetInitialValues('name', selectedMarketplace);
  }, [selectedMarketplace]);

  const handleSetInitialValues = (key: string, value: any) => {
    setInitialValues((prevValue) => ({
      ...prevValue,
      [key]: value,
    }));
  };

  return { initialValues, handleSetInitialValues, setInitialValues };
};
