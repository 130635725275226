import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 7,
    maxWidth: '528px',

    '& .MuiFormControl-root': {
      marginBottom: 24,
      maxWidth: 528,
    },
  },
  dividerSmall: {
    marginTop: 9,
    marginBottom: 24,
  },
  textarea: {
    fontFamily: 'Roboto, Arial, sans-serif',
    fontSize: '14px',
    backgroundColor: 'transparent',
    lineHeight: '20px',
    maxWidth: '100%',
    minWidth: '100%',
  },
  phoneInput: {
    marginBottom: 24,

    '& .PhoneInputInput': {
      color: '#000',
      border: '1px solid #4f4f4f',
      backgroundColor: 'transparent',
      width: '100%',
      height: 'auto',
      padding: '13px 16px',
      fontSize: '14px',
      lineHeight: '20px',

      '& input:focus': {
        outline: 'none',
      },
    },
  },
  phoneInputWarning: {
    color: '#ff9800',
  },
  tooltipIconButton: {
    padding: 0,
  },
  inputLabel: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));
